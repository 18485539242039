import { Box, Container, Typography } from '@mui/material'

import FAQCard from '../../components/faq/faq'
import DefaultLayout from '../../global/DefaultLayout'
import { grey } from '@mui/material/colors'
import { Link } from 'react-router-dom'

const FAQ_LIST = [
  {
    category: '계정이용',
    list: [
      {
        question: '입점 사업자 신청 과정은 어떻게 되나요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 10 }}>바이스냅(BYSNAP) 파트너스 입점 절차는 아래와 같습니다.</Typography>
            <Typography style={{ color: '#9e9e9e', marginBottom: 20, }}>입점 절차: 바이스냅(BYSNAP) 파트너스 회원 가입 및 입점 신청 → 바이스냅(BYSNAP) 파트너스 전자계약서 날인 → 파트너스 계정 최종 승인 및 이용</Typography>
            <Typography style={{ marginBottom: 10, fontWeight: 'bold' }}>신청 시 유의사항</Typography>
            <Typography style={{ marginBottom: 10 }}>* 전자 계약서는 신청해주신 이메일 계정으로 전달 될 예정입니다.</Typography>
            <Typography style={{ marginBottom: 10 }}>* 입점 신청 후 최종 승인까지 평균 1주일정도 소요됩니다.</Typography>
            <Typography style={{ marginBottom: 10 }}>* 사업자 검토 시 추가 검증을 위해 자료를 요구할 수 있습니다.</Typography>

          </>
        ),
      },
      {
        question: '입점 사업자 신청 시 자격 조건이 있나요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 10 }}>개인 또는 법인 사업자에 한하여 파트너스 입점 신청이 가능하며, 해당 사업자로 통신 판매업 신고가 되어 있어야 합니다.</Typography>
            <Typography style={{ color: '#9e9e9e', marginBottom: 20, }}> *간이 사업자는 입점 신청이 불가합니다.</Typography>
          </>
        ),
      },
      {
        question: '파트너스의 입점 계약기간은 어떻게 되나요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 10 }}>BYSNAP 입점 계약기간은 계약 체결일로 부터 1년을 기본으로 하고 있으며, 서면 통지에 의하여 이의를 제기하지 않는 한 동일한 조건으로 1년씩 자동으로 연장 됩니다</Typography>
          </>
        ),
      },
      {
        question: '바이스냅(BYSNAP) 파트너스 계정으로 BYSNAP 앱서비스 이용이 가능한가요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 10 }}>바이스냅 파트너스 계정으로 앱 서비스 이용은 가능하지만,</Typography>
            <Typography style={{ marginBottom: 10 }}>파트너스 웹 페이지에서 제공하는 스냅 사진 업로드, 상품 등록 및 수정 등의 파트너스 권한의 행위는 앱 서비스에서 제한됩니다.</Typography>

            <Typography style={{ color: '#9e9e9e', marginBottom: 20, }}> *파트너스 계정은 앱 서비스를 통해 구매 또한 불가하니 참고 해 주세요!</Typography>
          </>
        ),
      },
      {
        question: '입점 사업자 퇴점 신청이 가능한가요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 10 }}>계약기간 종료 이전에도 판매중개계약 변경 또는 해지는 판매중개계약서 제 4장 제 20조에 따라 가능합니다.</Typography>
            <Typography style={{ marginBottom: 10 }}>파트너스 퇴점 신청을 원하실 경우 아래 고객 센터를 통해 연락주세요.</Typography>

            <Link to='#'
              onClick={(e) => {
                window.location.href = "mailto:contact@by-snap.com";
                e.preventDefault();

              }}> 고객센터 연락하기</Link>
          </>
        ),
      },
      {
        question: '퇴점 후 재가입이 가능한가요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 10 }}>바이스냅 파트너스 이용약관 또는 판매중개계약에 위배되어 퇴점 된 경우가 아니라면 입점 재신청이 가능합니다.</Typography>
            <Typography style={{ marginBottom: 10 }}>퇴점 처리 완료 2개월 이후에 재가입이 가능하며, 재가입시 이전과 동일한 이메일은 사용할 수 없습니다.</Typography>

          </>
        ),
      },
    ],
  },
  {
    category: '스냅사진 등록',
    list: [
      {
        question: '하루에 여러장의 스냅 사진을 등록해도 되나요?',
        answer: (
          <>
            <Typography>게시물 등록 수는 시간 또는 기한에 제한 없이 가능합니다.</Typography>
          </>
        ),
      },
      {
        question: '스냅사진을 등록하려는데 상품 정보가 보이지 않아요.',
        answer: (
          <>
            <Typography style={{ marginBottom: 10 }}>스냅 사진에 상품 태그를 하기 위해선 상품 정보를 먼저 등록 해 주셔야 합니다.</Typography>
            <Typography style={{ color: '#9e9e9e', marginBottom: 20, }}> *상품 태그는 승인 대기중인 상품도 태그가 가능하니 참고 해 주세요! (승인 거절 시 해당 태그는 삭제됩니다.)</Typography>
          </>
        ),
      },
    ],
  },
  {
    category: '상품등록',
    list: [
      {
        question: '판매 가능 상품에 대한 기준이 있나요?',
        answer: (
          <>
            <Typography>바이스냅 파트너스 시스템에서 제공하는 카테고리에 한하여 상품 판매가 가능합니다.</Typography>
            <Typography>또한 이용약관 제 3장 제 18조 매매 부적합상품에 포함되는 상품이 등록 될 경우 계정이 제재될 수 있으니 참고 해 주세요.</Typography>
          </>
        ),
      },
      {
        question: '상품 판매 가격에 제한이 있나요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 10 }}>바이스냅은 소비자 상품 판매가격에 대한 제한을 두고 있지 않습니다.
            </Typography>
            <Typography> 단, 바이스냅은 판매중개계약서 제2장 제6조에 따라 파트너스의 판매가격이 부당하다고 판단 할 경우 조정을 요구할 수 있습니다.
            </Typography>
          </>
        ),
      },
      {
        question: '해외 판매가는 뭔가요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 10 }}>바이스냅은 해외 이용자들의 구매를 위해 현지 판매가를 적용 하고 있습니다.
            </Typography>
            <Typography> 해외 판매가는 등록해주신 원화 기준 판매가에 기반한 해외 판매가이며, 환율/결제 수수료 등 해외 판매를 위한 제반 비용이 일부 포함되어 있습니다.
            </Typography>
          </>
        ),
      },
      {
        question: '[상품정보제공고시]는 모두 입력해야 하나요?',
        answer: (
          <>
            <Typography>바이스냅 파트너스 시스템에 상품정보제공고시를 필수로 기입해야합니다.</Typography>
            <Typography>상품정보제공고시를 입력하지 않은 상품은 최종 등록 및 승인이 불가하니 참고 해 주세요.</Typography>
          </>
        ),
      },
      {
        question: '라벨이나 상품 정보는 왜 영문이어야 하나요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 40 }}>바이스냅에서는 해외 이용자들이 구매하는 상품이기때문에 라벨 등 상품에 대한 정보는 필수적으로 영문으로 노출되어야 합니다.</Typography>
            <Typography style={{ marginBottom: 10, fontWeight: 'bold' }}>· 라벨(케어라벨, 원산지 표시 등)</Typography>
            <Typography style={{ marginBottom: 20, }}>영문으로 작성된 라벨이 필수적으로 부착되어 있어야 함</Typography>
            <Typography style={{ marginBottom: 10, fontWeight: 'bold' }}>· 파트너스 시스템에 등록되는 상품 정보(원산지, 소재, 혼용률 등)</Typography>
            <Typography style={{ marginBottom: 20 }}>국문으로 작성 시 바이스냅에서 영문 번역 후 최종 승인</Typography>

          </>
        ),
      },
      {
        question: '상품 페이지에 상세 이미지를 추가할 수 있나요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 40 }} > 바이스냅 파트너스 시스템 절차에 따라 다양한 상품 사진 추가가 가능합니다.</Typography>
            <Typography style={{ marginBottom: 10, fontWeight: 'bold' }}>· 상품 메인 이미지</Typography>
            <Typography style={{ marginBottom: 20, }}>상품 정보 최상단에 노출되는 사진 (최대 10장)</Typography>
            <Typography style={{ marginBottom: 10, fontWeight: 'bold' }}>· 상품 추가 이미지</Typography>
            <Typography style={{ marginBottom: 20 }}>상품 상세 이미지 내 'MORE PHOTOS'에 노출되는 상품 이미지(최대 20장)</Typography></>
        ),
      },
    ],
  },
  {
    category: '판매/배송',
    list: [
      {
        question: '하루에 여러 상품을 등록해도 되나요?',
        answer: (
          <>
            <Typography>상품 판매 등록 수는 시간 또는 기한에 제한 없이 가능합니다.</Typography>
          </>
        ),
      },
      {
        question: '배송 절차는 어떻게 되는건가요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 20 }}>파트너스 입점사는 국내에 위치한 BYSNAP 물류 센터로 배송만 해주시면, 이후 해외 배송은 바이스냅이 진행합니다.</Typography>
            <Typography style={{ color: '#9e9e9e', marginBottom: 20 }}>배송절차 : 판매 상품 확인 → BYSNAP 물류 센터로 배송 → BYSNAP 상품 수령 및 검수 → 해외 배송사 상품 인계 → 해외 배송 완료</Typography>

          </>
        ),
      },
      {
        question: '운송장 정보는 어떻게 등록하나요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 20 }}>[판매 관리] → [판매 내역 조회] 페이지에서 발송정보 입력이 가능합니다.</Typography>
            <Typography style={{ color: '#9e9e9e', marginBottom: 20 }}>* 현재는 택배 배송만 이용 가능하니 참고 해 주세요.(배송비는 선불로 착불로 발송 시 반송 될 수 있습니다.)</Typography>
          </>
        ),
      },
    ],
  },
  {
    category: '정산/반품',
    list: [
      {
        question: '정산은 어떻게 되나요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 20 }}>정산은 구매자 결제 시점으로부터 최대 30일 이내로 정산되며, BYSNAP 물류센터에서 상품 수령 및 검수 완료 이후 정산 해 드릴 수 있도록 내부 방침을 정하여 운영하고 있습니다. (보통 일주일 이내 정산)</Typography>
            <Typography>정산 주기가 빠를수록 사업 운영이 원활하게 운영 될 수 있다는 점에 공감하여, 저희 바이스냅은 파트너분들께 최대한 빨리 정산 드릴 수 있도록 항상 최선을 다하겠습니다.</Typography>
          </>
        ),
      },
      {
        question: '구매자의 반품 또는 환불이 가능한가요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 20 }}>해외 구매자는 국제 전자상거래 기준에 따라 반품 또는 환불이 가능하며, 구매자 반품 시 반품건에 대한 관리는 바이스냅 미국 현지에서 관리 합니다.</Typography>

            <Typography style={{ color: '#9e9e9e', marginBottom: 20 }}>* 허위, 불량 또는 부정거래에 의심이 가는 상황이 아니라면 위 내용을 원칙으로 진행하며, 계약상 [월 반품률] 또는 [월 반품 재고금액] 초과 시 회사의 판단에 따라 파트너이게 반품 및 환불을 요구할 수 있습니다.</Typography>
          </>
        ),
      },
      {
        question: '반품 과정에 대해 알려주세요.',
        answer: (
          <>
            <Typography style={{ marginBottom: 20 }}>해외 구매자는 국제 전자상거래 기준에 따라 반품 또는 환불이 가능하며, 구매자 반품 시 반품건에 대한 관리는 바이스냅 미국 현지에서 관리 합니다.</Typography>

            <Typography style={{ marginBottom: 10 }}>바이스냅은 파트너의 비용 효율성을 위해서 하자나 불량으로 인한 반품이 아니한 경우 미국 센터 보관을 지원하고 있으며, 동일 물품의 판매주문이 신규로 성사되는 경우 해당 국가의 바이스냅 물품보관창고에서 리패키징 및 상품화 작업을 통하여 재판매를 지원하고 있습니다.</Typography>
            <Typography style={{ color: '#9e9e9e', marginBottom: 20 }}>반품 프로세스 : 구매자 반품 신청 → 반품 회수 및 BYSNAP 리패키징 및 상품화 작업 진행 → 동일 물품 판매주문 성사 → 구매자에게 배송</Typography>
            <Typography style={{ color: '#9e9e9e', marginBottom: 10 }}>* 단, 상황에 따라 파트너는 비용을 부담하고 파트너가 지정하는 장소로 반송 요청을 할 수 있습니다. </Typography>

          </>
        ),
      },
      {
        question: '반품된 물품에 대한 보관 비용은 어떻게 되나요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 20 }}>바이스냅은 파트너와 공동의 이익을 증진과 파트너의 비용 효율성을 위하여 판매국가의 물품보관창고의 보관판매 및 보관비용을 무료로 지원하고 있습니다.</Typography>

            <Typography style={{ marginBottom: 10 }}>바이스냅은 파트너의 비용 효율성을 위해서 하자나 불량으로 인한 반품이 아니한 경우, 미국 센터 보관을 지원하고 있으며, 동일 물품의 판매주문이 신규로 성사되는 경우 해당 국가의 바이스냅 물품보관창고에서 리패키징 및 상품화 작업을 통하여 재판매를 지원하고 있습니다.</Typography>
            <Typography style={{ color: '#9e9e9e', marginBottom: 20 }}>반품 프로세스 : 구매자 반품 신청 → 반품 회수 및 BYSNAP 리패키징 및 상품화 작업 진행 → 동일 물품 판매주문 성사 → 구매자에게 배송</Typography>
            <Typography style={{ color: '#9e9e9e', marginBottom: 10 }}>* 단, 상황에 따라 파트너는 비용을 부담하고 파트너가 지정하는 장소로 반송 요청을 할 수 있습니다. </Typography>

          </>
        ),
      },
      {
        question: '세금계산서 발행 가능한가요?',
        answer: (
          <>
            <Typography style={{ marginBottom: 20 }}>저희 BYSNAP은 미국 법인으로서 세금계산서 발행이 불가합니다.
            </Typography>
            <Typography style={{ marginBottom: 10 }}>다만, 부가세 또는 종소세 신고 시 수출 실적에 대한 자료를 요구하실 경우 저희 서비스 내 판매 기록에 대해 제공드릴 예정이니 참고 부탁드리며, </Typography>
            <Typography style={{ marginBottom: 10 }}>국제 특송사(DHL)를 통해 배송을 진행하는 관계로, 수출 신고 필증 및 소포 수령증 또한 발급이 어려운 점 양해 부탁드립니다.</Typography>
          </>
        ),
      },
    ],
  },

  {
    category: '판매자 센터',
    list: [
      {
        question: '여러 계정으로 판매자 센터를 사용할 수 있나요?',
        answer: (
          <>
            <Typography>현재 파트너당 1개의 계정만 이용 가능합니다.</Typography>
          </>
        ),
      },
      {
        question: '모바일에서도 접속이 가능한가요?',
        answer: (
          <>
            <Typography>가능하지만, 파트너스 시스템은 데스크톱이나 노트북에 최적화 되어 있습니다.</Typography>
          </>
        ),
      },
    ],
  },
]

const FAQPage = () => {
  return (
    <DefaultLayout>
      <Container>
        <Box sx={{ height: '100px' }} />
        <Typography sx={{ fontWeight: 700, fontSize: '40px' }}>
          자주 묻는 질문 (FAQ)
        </Typography>
        <Box sx={{ height: '60px' }} />
        <Box sx={{ gap: '50px', display: 'flex', flexDirection: 'column' }}>
          {FAQ_LIST.map((faq) => (
            <FAQCard faq={faq} key={faq.category} />
          ))}
        </Box>
      </Container>
      <Box sx={{ height: '300px' }} />
    </DefaultLayout>
  )
}

export default FAQPage
