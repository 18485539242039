import { useEffect, useState } from 'react'
import { getNotices } from '../../api/notice'

const useNoticeList = () => {
  const [page, setPage] = useState(1)
  const [totalCtn, setTotalCtn] = useState(0)
  const [notices, setNotices] = useState([])

  useEffect(() => {
    ;(async () => {
      const { data, count } = await getNotices(page)
      setNotices(data)
      setTotalCtn(count)
    })()
  }, [page])

  return { page, setPage, notices, setNotices, totalCtn }
}

export default useNoticeList
