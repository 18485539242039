import { Container } from '@mui/material'
import DefaultLayout from '../global/DefaultLayout'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import { useState } from 'react'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useNavigate } from 'react-router-dom'
import { getImageUrl } from '../api/image'
import { useDispatch, useSelector } from 'react-redux'
import { getBankAction } from '../redux/reducers/sign'
import { InputTextField } from '../element/InputTextField'
import RegisterHeader from '../components/register/Header'
import { inputLabelPropsStyle } from '../utils'

const RegisterAccountInfo = () => {
  const navigation = useNavigate()

  const [previewImg, setPreview] = useState('')

  const [isUploading, setUploading] = useState(false)
  const accountImgHandler = async (e) => {
    e.preventDefault()

    const file = e.target.files?.[0]
    if (!file) return alert('잘못된 이미지 형식입니다.')

    setUploading(true)

    const formData = new FormData()
    formData.append('file', file)

    const { success, data } = await getImageUrl(formData)

    if (success) {
      setBank({ ...bank, accountImg: data })

      let reader = new FileReader()

      reader.onloadend = () => {
        setPreview(reader.result)
      }

      reader.readAsDataURL(file)
    }

    setUploading(false)
  }

  const bankOption = [
    '',
    'KB국민은행',
    'SC제일은행',
    '경남은행',
    '광주은행',
    '기업은행',
    '농협',
    '대구은행',
    '부산은행',
    '산업은행',
    '새마을금고',
    '수협',
    '신한은행',
    '신협',
    '외환은행',
    '우리은행',
    '우체국',
    '전북은행',
    '카카오뱅크',
    '케이뱅크',
    '하나은행',
    '한국씨티은행',
    '제주은행',
    '토스뱅크',
    '저축은행',
  ]

  const init = useSelector((state) => state.sign.bank)

  const [bank, setBank] = useState(init)

  const dispatch = useDispatch()

  const [shouldValidate, setValidate] = useState(false)
  const nextBtnHandler = () => {
    if (!Object.values(bank).every((val) => val !== '')) {
      setValidate(true)
      return
    }

    dispatch(getBankAction(bank))
    navigation('/register/user')
  }

  const isIncorrectForm = (val) => {
    if (shouldValidate && val === '') {
      return { message: '필수 입력값입니다.', value: true }
    }
    return { message: '', value: false }
  }

  return (
    <DefaultLayout>
      <Container align="center" sx={{ marginTop: '50px' }}>
        <RegisterHeader step={2} />

        <Box
          sx={{
            width: 460,
            maxWidth: '100%',
            textAlign: 'left',
            paddingTop: 4,
          }}
        >
          <p style={{ fontWeight: 'bolder', fontSize: 18 }}>정산 정보</p>

          <CustomDropDownField
            fullWidth
            disableClearable
            size="small"
            value={bank.bankName}
            onChange={(event, value) => {
              setBank({ ...bank, bankName: value })
            }}
            ListboxProps={{
              style: {
                fontSize: '14px',
              },
            }}
            id="bank_option"
            options={bankOption}
            filterOptions={(options, { inputValue }) => {
              return options.filter(
                (option) => option !== '' && option.includes(inputValue)
              )
            }}
            renderInput={(params) => (
              <TextField
                error={isIncorrectForm(bank.bankName).value}
                helperText={isIncorrectForm(bank.bankName).message}
                {...params}
                InputLabelProps={inputLabelPropsStyle}
                label="은행선택"
              />
            )}
          />
          <InputTextField
            marginBottom="20px"
            error={isIncorrectForm(bank.accountHolder).value}
            helperText={isIncorrectForm(bank.accountHolder).message}
            label="예금주명"
            id="account_holder"
            value={bank.accountHolder}
            onChange={(e) => {
              setBank({ ...bank, accountHolder: e.target.value })
            }}
          />
          <InputTextField
            marginBottom="20px"
            error={isIncorrectForm(bank.accountNumber).value}
            helperText={isIncorrectForm(bank.accountNumber).message}
            label="계좌번호('-' 없이 입력)"
            id="account_number"
            value={bank.accountNumber}
            onChange={(e) => {
              setBank({ ...bank, accountNumber: e.target.value })
            }}
          />
          <Button
            component="label"
            variant="contained"
            fullWidth
            sx={{ fontSize: 16, color: 'white', backgroundColor: '#707070' }}
            startIcon={<CloudUploadIcon />}
            disabled={isUploading}
          >
            통장 사본 파일 첨부
            <VisuallyHiddenInput
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={accountImgHandler}
            />
          </Button>
          <p style={{ fontSize: 14, paddingTop: 4 }}>
            최대 5MB 크기의 jpg,jpeg,png 형식 파일
          </p>

          {previewImg && <img alt="post" src={previewImg} width={'100%'} />}

          {isIncorrectForm(bank.accountImg).value && (
            <p style={{ fontSize: 12, paddingTop: 4, color: '#d32f2f' }}>
              {isIncorrectForm(bank.accountImg).message}
            </p>
          )}
        </Box>
        <br />
        <Box
          sx={{
            display: 'inline-block',
            width: '225px',
            paddingRight: '5px',
            paddingTop: '20px',
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            size="large"
            sx={{ color: 'black', borderColor: 'black' }}
            onClick={() => {
              navigation(-1)
            }}
          >
            이전
          </Button>
        </Box>
        <Box
          sx={{
            display: 'inline-block',
            width: '225px',
            paddingLeft: '5px',
          }}
        >
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{ backgroundColor: 'black' }}
            onClick={nextBtnHandler}
          >
            다음
          </Button>
        </Box>
        <Box sx={{ height: 100 }}></Box>
      </Container>
    </DefaultLayout>
  )
}

const CustomDropDownField = styled(Autocomplete)({
  marginTop: '10px',
  marginBottom: '20px',

  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    // 해당 input focus 되었을 때 placeholder text color
    // floatng label을 사용할 때 처리 필요하다
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
})

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 2,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})
export default RegisterAccountInfo
