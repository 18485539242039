import { Divider, Input } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import * as React from 'react'
import styled from 'styled-components'
import { setOrderInvoice } from '../../api/order'
import { Button } from '../../element/Button'
import PostSelectBtn from './select-btn'
import SendingTable from './sending-table'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 955,
  bgcolor: 'background.paper',
  outline: 'none',
  boxShadow: 24,
  padding: '16px 16px 30px 16px',
}

export default function SetInvoiceModal({
  open,
  handleClose,
  checked,
  orderItems,
  setOrderItems,
  setOnCheckBar,
  setSnackBarMsg,
  isModify,
  setModify,
}) {
  const [body, setBody] = React.useState({
    shippingCompany: '',
    invoiceNumber: '',
  })

  React.useEffect(() => {
    if (isModify) {
      setBody({
        ...body,
        shippingCompany: checked[0]?.innerShippingCompany,
        invoiceNumber: checked[0]?.innerInvoiceNumber,
      })
    }
  }, [isModify])

  const onClickBtn = async () => {
    if (Object.values(body).some((data) => !data)) {
      alert('택배사와 송장번호를 다시 확인해주세요.')
      return
    }

    const { success } = await setOrderInvoice({
      orderItemIds: checked.map((o) => o.id),
      ...body,
    })

    if (!success) {
      return
    }

    for (const check of checked) {
      check.innerShippingCompany = body.shippingCompany
      check.innerInvoiceNumber = body.invoiceNumber
      check.innerShippingStatus = 'SENT_COMPLETE'
    }

    setOrderItems([...orderItems])
    setBody({ ...body, shippingCompany: '', invoiceNumber: '' })
    handleClose()

    if (isModify) {
      setModify(false)
      setSnackBarMsg('발송정보 변경이 완료되었습니다.')
    }
    setOnCheckBar(true)
  }

  const onChangeInvoiceNumber = (e) => {
    setBody({ ...body, invoiceNumber: e.target.value })
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
          선택 발송정보 입력
        </p>
        <Divider sx={{ height: 16 }} />
        <Box sx={{ padding: '10px 0 10px 0' }}>
          <p style={{ color: '#707070' }}>
            선택된 발송가능한 주문 건 목록입니다.
          </p>
        </Box>
        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#FFF0F0',
            height: '230px',
            marginTop: '10px',
            marginBottom: '22px',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              borderRadius: '10px 0px 0px 10px',
              backgroundColor: '#F57979',
              width: '10px',
            }}
          />

          <p
            style={{
              fontSize: '14px',
              margin: '10px 0 0 30px',
            }}
          >
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              * 꼭 확인해주세요.
            </span>
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              · 택배 발송 시 배송비용은 선불로 발송해주세요.
            </span>
            <br />
            <span>
              &ensp; 착불로 발송해주실 경우 센터 입고가 지연 또는 미처리될 수
              있으며, 배송 비용은 정산 금액에서 차감 후 지급됩니다.
            </span>
            <br />
            <br />

            <span style={{ fontWeight: 'bold' }}>
              · 택배 발송은 결제일로부터 +3 영업일 이내 발송해주세요.
            </span>
            <br />

            <span>
              &ensp; +3 영업일 이내 발송되지 않을 경우, 배송 지연으로 처리되며
              이후에 패널티가 부가될 수 있습니다.
            </span>
            <br />
            <span>&ensp; * 주문제작 상품의 경우 +7일 또는 +14일</span>

            <br />
            <br />

            <span style={{ fontWeight: 'bold' }}>
              · 상품은 아래 바이스냅 국내 검수센터 주소로 발송 해 주세요.
            </span>
          </p>
        </Box>

        <Box
          sx={{
            borderRadius: 2,
            padding: '10px',
            marginBottom: '30px',
          }}
        >
          <P style={{ fontWeight: 'bold' }}>보내실 곳 : </P>
          <P>바이스냅(BYSNAP) 앞</P>
          <P>서울 중구 을지로 264 11층 1148호</P>
          <P>010 - 5355 - 9068</P>
        </Box>

        <Box paddingLeft="10px" display="flex" alignItems="center" gap="20px">
          <p style={{ color: '#707070', fontWeight: 'bold' }}>택배사</p>
          <PostSelectBtn
            body={body}
            setBody={setBody}
            isModify={isModify}
            checked={checked}
          />

          <Box width="30px" />

          <p style={{ color: '#707070', fontWeight: 'bold' }}>송장번호</p>
          <Input
            sx={{
              border: '1px solid #e4e4e4',
              borderRadius: '6px',
              width: '259px',
              height: '40px',
            }}
            disableUnderline
            defaultValue={isModify ? checked[0]?.innerInvoiceNumber : ''}
            placeholder="송장번호(- 없이 입력)"
            inputProps={{
              style: { fontSize: '12px', padding: '5px', color: 'black' },
            }}
            onChange={onChangeInvoiceNumber}
          />
        </Box>

        <Box height="20px" />

        <Box maxHeight="300px" overflow="scroll">
          <SendingTable checked={checked} />
        </Box>

        <Box
          paddingX={1}
          paddingY={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            color="#f57979"
            bg="#fff0f0"
            width="80px"
            height="50px"
            borderRadius="10px"
            bold
            onClick={handleClose}
          >
            취소
          </Button>
          <Box sx={{ width: 20 }} />
          <Button
            color="white"
            bg="black"
            width="126px"
            height="50px"
            borderRadius="10px"
            bold
            onClick={onClickBtn}
          >
            {isModify ? '발송정보 변경' : '발송정보 저장'}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

const P = styled.p`
  line-height: 30px;
`
