import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import styled from 'styled-components'
import { getNotices } from '../api/notice'

const NoticeSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    vertical: true,
    verticalSwiping: true,
  }

  const [notices, setNotices] = useState([])
  const navigation = useNavigate()

  useEffect(() => {
    ;(async () => {
      const { data } = await getNotices(1)

      setNotices(data.splice(0, 3).map((o) => ({ id: o.id, title: o.title })))
    })()
  }, [])

  if (notices.length === 0) {
    return <></>
  }

  return (
    <Box
      display="flex"
      gap={1}
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: '100%' }}
    >
      <Box display="flex" gap={1} flexDirection="row" alignItems="center">
        <Box>
          <HeadText>공지사항</HeadText>
        </Box>
        <StyledSlider {...settings}>
          {notices.map((notice) => (
            <div
              key={notice.id}
              onClick={() => navigation(`/notice/${notice.id}`)}
            >
              <p
                style={{
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
              >
                {notice.title}
              </p>
            </div>
          ))}
        </StyledSlider>
      </Box>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          navigation('/notice')
        }}
      >
        <p>더보기</p>
      </Box>
    </Box>
  )
}

export default NoticeSlider

const HeadText = styled.p`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`

const StyledSlider = styled(Slider)`
  .slick-list {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slick-slide > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1000px;
  }
`
