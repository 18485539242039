import * as React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export default function SelectDiscountTypeForModal({ discount, setDiscount }) {
  const handleChange = (event) => {
    setDiscount({
      ...discount,
      type: event.target.value,
      amount: 0,
    })
  }

  return (
    <Box sx={{ minWidth: 10, marginX: 1 }}>
      <FormControl fullWidth>
        <Select
          sx={{ height: '45px' }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={discount.type}
          onChange={handleChange}
        >
          <MenuItem value={'PERCENTAGE'}>%</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}
