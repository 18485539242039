import { Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Table, Td } from '../../element/Table'
import useNoticeList from '../../hooks/notice/useNoticeList'
import { formatDateToKorean } from '../../utils'
import Navigation from '../post/navigation'

const NoticeTable = () => {
  const navigate = useNavigate()
  const { page, setPage, notices, totalCtn } = useNoticeList()

  return (
    <>
      <Table>
        <tbody>
          {notices.map((notice) => {
            return (
              <tr
                key={`${notice.id}`}
                onClick={() => navigate(`/notice/${notice.id}`)}
                style={{ cursor: 'pointer' }}
              >
                <Td>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      borderBottom: '1px solid #e4e4e4',
                      height: 54,
                      paddingX: '20px',
                    }}
                  >
                    <Typography>{notice.title}</Typography>
                    <Typography
                      sx={{
                        color: '#707070',
                        fontSize: 14,
                      }}
                    >
                      {formatDateToKorean(new Date(notice.createdAt))}
                    </Typography>
                  </Stack>
                </Td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Navigation page={page} setPage={setPage} count={totalCtn} />
    </>
  )
}

export default NoticeTable
