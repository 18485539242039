import styled from 'styled-components'

import { getDate } from '../../utils'
import { priceWrapper } from '../../pages/product/addProduct'

const heads = [
  '판매일',
  '상품명',
  '판매금액($)',
  '정산일',
  '정산방법',
  '정산금액',
  '개수',
  '총 정산금액',
]

const METHOD_TABLE = {
  ACCOUNT_PAYMENT: '계좌지급',
}

const TransactionTable = ({ transactions }) => {
  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {transactions.map((item, index) => {
          const {
            orderItem: {
              product: { name },
              orderPrice,
              transactionPrice,
              orderCount,
              method,
              createdAt,
            },
          } = item

          return (
            <tr
              key={item.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
            >
              <Td>{getDate(item.createdAt)}</Td>
              <Td width="400px">
                <PBox>{name}</PBox>
              </Td>

              <Td>{orderPrice}</Td>
              <Td>{getDate(createdAt)}</Td>
              <Td>{METHOD_TABLE[method]}</Td>
              <Td>{priceWrapper(transactionPrice)}</Td>
              <Td>{orderCount}</Td>
              <Td>{priceWrapper(transactionPrice * orderCount)}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default TransactionTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})

const PBox = styled('div')({
  width: '400px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})
