import React from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getSellerFromLocalStorage } from '../../api/storage'
import Image from '../../element/Image'
import { IModal, PostModelInfo, PreviewProductLocs } from '../modal/modal'
import ProductLocs from './ProductLocs'

const usePreviewSnapFormModal = (form) => {
  const { imgs, productLocs, content } = form
  const canPreview = imgs.length !== 0 && productLocs.length !== 0 && content

  const [on, setOn] = React.useState(false)

  const open = () => {
    setOn(true)
  }
  const close = () => setOn(false)

  const handler = {
    close,
  }

  return {
    canPreview,
    open,
    modal: on
      ? () => (
          <IModal
            open={on}
            onClose={handler.close}
            content={<PreviewSnap post={form} />}
          />
        )
      : () => <React.Fragment />,
  }
}

export default usePreviewSnapFormModal

const IMAGE_SIZE = 430

const PreviewSnap = ({ post }) => {
  const [isViewLoc, setIsViewLoc] = React.useState(false)
  const [imageIndex, setImageIndex] = React.useState(0)
  const seller = getSellerFromLocalStorage()
  const { productLocs, imgs } = post

  const products = productLocs.map((p) => p.product)

  let uniqueId = []
  let uniqueProducts = []

  for (let product of products) {
    if (product.approveStatus !== 'APPROVED') continue
    if (!uniqueId.includes(product.id)) {
      uniqueId.push(product.id)
      uniqueProducts.push(product)
    }
  }

  const currentTag = productLocs?.filter((l) => {
    return l.url === imgs[imageIndex] && l.product.approveStatus === 'APPROVED'
  })

  const handler = {
    swipe: (s) => setImageIndex(s.snapIndex),
    viewLoc: () => setIsViewLoc((prev) => !prev),
  }

  return (
    <Box>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <img
          alt="seller profile"
          src={seller?.profileImg ?? ''}
          style={{
            borderRadius: '36px',
            width: '36px',
            height: '36px',
            objectFit: 'cover',
          }}
        />
        <Box sx={{ width: '20px' }} />
        <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
          {seller.name}
        </Typography>
      </Box>
      <Box sx={{ height: 10 }} />
      <Swiper onSlideChange={handler.swipe}>
        {post?.imgs?.map((img, index) => {
          return (
            <SwiperSlide key={index}>
              <Box
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
                position="relative"
                onClick={handler.viewLoc}
              >
                <Image
                  src={img}
                  alt="snap"
                  width={IMAGE_SIZE}
                  height={IMAGE_SIZE}
                  position="absolute"
                />
                {currentTag?.map((loc, idx) => {
                  return (
                    <ProductLocs
                      key={`just_loc_${index}_${idx}`}
                      loc={loc}
                      size={IMAGE_SIZE}
                      real
                    />
                  )
                })}
                {isViewLoc &&
                  currentTag?.map((loc, idx) => {
                    return (
                      <PreviewProductLocs
                        loc={loc}
                        key={`product_loc_${index}_${idx}`}
                      />
                    )
                  })}
              </Box>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <Box
        sx={{
          margin: '15px',
          borderTop: '1px solid #e4e4e4',
          borderBottom: '1px solid #e4e4e4',
          boxSizing: 'border-box',
        }}
      >
        <Stack direction="column" padding="10px">
          <Typography fontSize={14} fontWeight={500}>
            Product Info.
          </Typography>
          {uniqueProducts?.map((product, idx) => (
            <Box
              key={idx}
              sx={{
                padding: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={product.imgs?.[0]}
                alt="product"
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '10px',
                  border: '1px solid #e4e4e4',
                }}
              />
              <Box sx={{ width: '10px' }} />
              <Box>
                <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
                  {product?.name}
                </Typography>
                <Typography sx={{ fontWeight: 700 }}>
                  ${product?.price}
                </Typography>
              </Box>
            </Box>
          ))}
          <PostModelInfo post={post} />
        </Stack>
      </Box>
      <Typography sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}>
        <span style={{ fontWeight: 700 }}>{seller?.name} </span>
        {post?.content}
      </Typography>
    </Box>
  )
}
