import { Box } from '@mui/material'
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'

import CloseIcon from '@mui/icons-material/Close'

export const format = (items) => {
  return items.map((item) => ({ id: item.id, image: item.imgs[0] }))
}

const ItemTypes = {
  IMAGE: 'image',
}

const Image = ({ src, id, onDrop, width, height }) => {
  const [, ref] = useDrag({
    type: ItemTypes.IMAGE,
    item: { id, src },
  })

  const [, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    drop: (item) => onDrop(item.id, id),
  })

  return (
    <div
      ref={(node) => ref(drop(node))}
      style={{ margin: '10px', cursor: 'pointer' }}
    >
      <img
        src={src}
        alt={`img-${id}`}
        width={width}
        height={height}
        style={{ objectFit: 'cover' }}
      />
    </div>
  )
}

const DragDropItems = ({ items, setItems, width, height, front }) => {
  const handleDrop = (draggedId, targetId) => {
    const draggedIndex = items.findIndex((item) => item.id === draggedId)
    const targetIndex = items.findIndex((item) => item.id === targetId)

    const newItems = [...items]
    const [draggedImage] = newItems.splice(draggedIndex, 1)
    newItems.splice(targetIndex, 0, draggedImage)

    setItems(newItems)
  }

  const handleCancel = (idx) => {
    items.splice(idx, 1)
    setItems(items)
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
      {front}
      {items.map((item, idx) => (
        <CancelPreviewImg
          key={idx}
          src={item.imgs[0]}
          id={item.id}
          onDrop={handleDrop}
          width={width}
          height={height}
          onCancelClick={() => {
            handleCancel(idx)
          }}
        />
      ))}
    </div>
  )
}

export default DragDropItems

const CancelPreviewImg = ({
  id,
  src,
  onCancelClick,
  onDrop,
  width,
  height,
}) => {
  return (
    <Box display="inline-block" position="relative">
      <CloseIcon
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: 0,
          right: 0,
          cursor: 'pointer',
        }}
        onClick={onCancelClick}
      />
      <Image src={src} id={id} onDrop={onDrop} width={width} height={height} />
    </Box>
  )
}
