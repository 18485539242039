import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { signUp } from '../api/auth'
import RegisterHeader from '../components/register/Header'
import { InputTextField } from '../element/InputTextField'
import DefaultLayout from '../global/DefaultLayout'
import { isValidEmail, isValidPhoneNum, isValidPwd } from '../utils'

const RegisterUserInfo = () => {
  const navigation = useNavigate()
  const [info, setInfo] = useState({
    email: '',
    password: '',
    confirmedPwd: '',
    sellerName: '',
    managerName: '',
    managerNumber: '',
    agree: {
      termsOfService: false,
      privacyPolicy: false,
    },
  })

  const checkBoxHandler = (e, keyName) => {
    const value = e.target.value === 'true'
    const copied = { ...info, agree: { ...info.agree } }
    copied.agree[keyName] = !value
    setInfo(copied)
  }

  const isAllAgreeTrue = () => Object.values(info.agree).every((val) => val)

  const setAllAgree = () => {
    const value = isAllAgreeTrue() ? false : true
    const copied = { ...info, agree: { ...info.agree } }

    for (const key of Object.keys(info.agree)) {
      copied.agree[key] = value
    }

    setInfo(copied)
  }

  const sign = useSelector((state) => state.sign)

  const [shouldValidate, setValidate] = useState(false)
  const registerBtnHandler = async () => {
    if (!sign.business.isSettled) {
      navigation('/register/biz')
      alert('사업자 정보를 다시 입력해주세요.')
      return
    }

    if (!sign.bank.isSettled) {
      navigation('/register/account')
      alert('계좌 정보를 다시 입력해주세요.')
      return
    }

    if (info.sellerName.length > 30) {
      alert('닉네임은 최대 띄어쓰기 포함 30글자 입니다.')
      return
    }

    const isFilled = Object.values(info).every((val) =>
      typeof val === 'string'
        ? val !== ''
        : Object.values(val).every((_val) => _val)
    )

    if (!isFilled) {
      setValidate(true)
      return
    }

    if (!isValidEmail(info.email)) {
      return
    }

    if (!isValidPwd(info.password)) {
      return
    }

    if (info.password !== info.confirmedPwd) {
      return
    }

    if (!isValidPhoneNum(info.managerNumber)) {
      return
    }

    const { success } = await signUp({
      ...sign.business,
      ...sign.bank,
      ...info,
      ...info.agree,
    })
    if (!success) {
      return
    }
    navigation('/register/complete')
  }

  const isIncorrectEmailForm = (val) => {
    if (shouldValidate && !val) {
      return { message: '필수 입력값입니다.', value: true }
    }

    if (val && !isValidEmail(val)) {
      return { message: '이메일 형식이 맞지 않습니다.', value: true }
    }
    return { message: '', value: false }
  }

  const isIncorrectPwdForm = (val, isConfirmed, comVal) => {
    if (isConfirmed && val !== comVal) {
      return { message: '비밀번호가 일치하지않습니다.', value: true }
    }

    if (shouldValidate && !val) {
      return { message: '필수 입력값입니다.', value: true }
    }

    if (val && !isValidPwd(val)) {
      return { message: '잘못된 비밀번호 형식입니다.', value: true }
    }

    return { message: '', value: false }
  }

  const isIncorrectForm = (val) => {
    if (shouldValidate && !val) {
      return { message: '필수 입력값입니다.', value: true }
    }

    return { message: '', value: false }
  }

  const isIncorrectPhoneNumberForm = (val) => {
    if (shouldValidate && !val) {
      return { message: '필수 입력값입니다.', value: true }
    }

    if (val && !isValidPhoneNum(val)) {
      return { message: '휴대폰 번호를 정확하게 입력해주세요.', value: true }
    }

    return { message: '', value: false }
  }

  return (
    <DefaultLayout>
      <Container align="center" sx={{ marginTop: '50px' }}>
        <RegisterHeader step={3} />

        <Box
          sx={{
            width: 460,
            maxWidth: '100%',
            textAlign: 'left',
            paddingTop: 4,
          }}
        >
          <p style={{ fontWeight: 'bolder', fontSize: 18 }}>계정 정보</p>
          <p style={{ fontSize: 14, color: '#707070' }}>
            바이스냅(BYSNAP) 파트너스 로그인 계정 정보를 입력 해 주세요.
          </p>
          <InputTextField
            marginBottom="20px"
            error={isIncorrectEmailForm(info.email).value}
            helperText={isIncorrectEmailForm(info.email).message}
            label="이메일 주소"
            id="register_email"
            value={info.email}
            onChange={(e) => {
              setInfo({ ...info, email: e.target.value })
            }}
          />
          <InputTextField
            marginBottom="20px"
            error={isIncorrectPwdForm(info.password).value}
            helperText={isIncorrectPwdForm(info.password).message}
            label="비밀번호 (8~16자 이내 영문대소문자 각 최소1개 이상, 숫자, 특수문자 조합)"
            type="password"
            id="register_password"
            value={info.password}
            onChange={(e) => {
              setInfo({ ...info, password: e.target.value })
            }}
          />
          <InputTextField
            marginBottom="20px"
            error={
              isIncorrectPwdForm(info.confirmedPwd, true, info.password).value
            }
            helperText={
              isIncorrectPwdForm(info.confirmedPwd, true, info.password).message
            }
            label="비밀번호 재입력"
            type="password"
            value={info.confirmedPwd}
            onChange={(e) => {
              setInfo({ ...info, confirmedPwd: e.target.value })
            }}
            id="check_password"
          />
        </Box>
        <Box
          sx={{
            width: 460,
            maxWidth: '100%',
            textAlign: 'left',
            paddingTop: 4,
          }}
        >
          <p style={{ fontWeight: 'bolder', fontSize: 18 }}>
            바이스냅(BYSNAP) 파트너 정보
          </p>

          <InputTextField
            marginBottom="20px"
            error={isIncorrectForm(info.sellerName).value}
            helperText={isIncorrectForm(info.sellerName).message}
            label="브랜드명"
            value={info.sellerName}
            onChange={(e) => {
              if (!/^[A-Za-z0-9\s:]*$/.test(e.target.value)) {
                return
              }

              setInfo({ ...info, sellerName: e.target.value })
            }}
            id="stylist_name"
          />
          <InputTextField
            marginBottom="20px"
            error={isIncorrectForm(info.managerName).value}
            helperText={isIncorrectForm(info.managerName).message}
            label="담당자명"
            value={info.managerName}
            onChange={(e) => {
              setInfo({ ...info, managerName: e.target.value })
            }}
            id="manager_name"
          />
          <InputTextField
            marginBottom="20px"
            error={isIncorrectPhoneNumberForm(info.managerNumber).value}
            helperText={isIncorrectPhoneNumberForm(info.managerNumber).message}
            label="담당자 연락처 (문자 수신 가능한 휴대폰 번호 - 없이)"
            value={info.managerNumber}
            onChange={(e) => {
              if (!/^[0-9]*$/.test(e.target.value)) {
                return
              }
              setInfo({ ...info, managerNumber: e.target.value })
            }}
            id="manager_phone"
          />
        </Box>
        <Box
          sx={{
            width: 460,
            maxWidth: '100%',
            textAlign: 'left',
            paddingTop: 4,
          }}
        >
          <p style={{ fontWeight: 'bolder', fontSize: 18, paddingBottom: 10 }}>
            이용약관
          </p>
          <Box
            sx={{
              display: 'block',
              justifyContent: 'start',
              alignItems: 'center',
              width: 460,
              px: 0,
              border: '1px solid #E4E4E4',
              borderRadius: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                margin: '10px auto',
                width: 430,
                height: 40,
                px: 0,
                border: '1px solid #E4E4E4',
                borderRadius: '6px',
              }}
            >
              <Checkbox checked={isAllAgreeTrue()} onClick={setAllAgree} />
              <p style={{ fontWeight: 'bold', fontSize: '14px' }}>전체 동의</p>
            </Box>

            <CheckBoxArea>
              <Checkbox
                value={info.agree.termsOfService}
                checked={info.agree.termsOfService}
                onClick={(e) => checkBoxHandler(e, 'termsOfService')}
              />
              <p
                style={{
                  fontSize: '14px',
                }}
              >
                바이스냅 어드민{` `}
                <span
                  style={{
                    fontSize: '14px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.location.href = '/term.html'
                  }}
                >
                  서비스 이용약관
                </span>
                {` `}동의
              </p>
            </CheckBoxArea>

            <CheckBoxArea>
              <Checkbox
                value={info.agree.privacyPolicy}
                checked={info.agree.privacyPolicy}
                onClick={(e) => checkBoxHandler(e, 'privacyPolicy')}
              />
              <p
                style={{
                  fontSize: '14px',
                }}
              >
                판매자{` `}
                <span
                  style={{
                    fontSize: '14px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.location.href = '/privacy.html'
                  }}
                >
                  개인정보 수집
                </span>
                {` `}동의
              </p>
            </CheckBoxArea>
          </Box>
          {shouldValidate && !isAllAgreeTrue() && (
            <p style={{ fontSize: 12, paddingTop: 4, color: '#d32f2f' }}>
              모든 동의가 필요합니다.
            </p>
          )}
        </Box>

        <br />
        <Box
          sx={{
            display: 'inline-block',
            width: '225px',
            paddingRight: '5px',
            paddingTop: '20px',
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            size="large"
            sx={{ color: 'black', borderColor: 'black' }}
            onClick={() => {
              navigation(-1)
            }}
          >
            이전
          </Button>
        </Box>
        <Box
          sx={{
            display: 'inline-block',
            width: '225px',
            paddingLeft: '5px',
          }}
        >
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{ backgroundColor: 'black' }}
            onClick={registerBtnHandler}
          >
            가입 신청
          </Button>
        </Box>
        <Box sx={{ height: 100 }}></Box>
      </Container>
    </DefaultLayout>
  )
}

const CheckBoxArea = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  margin: '12px auto',
  width: '430px',
})

export default RegisterUserInfo
