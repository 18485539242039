import { isValidEmail, isValidPwd } from '../utils'

import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { login } from '../api/auth'
import { InputTextField } from '../element/InputTextField'
import DefaultLayout from '../global/DefaultLayout'
import logo from '../static/bysnap.png'

const HomePage = () => {
  const navigation = useNavigate()
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')

  const registerStandingPoint = () => {
    navigation('/register/policy')
  }

  const loginHandler = async () => {
    if (!isValidEmail(email)) {
      return
    }

    if (!isValidPwd(pwd)) {
      return
    }

    const { success, path } = await login(email, pwd)
    if (success) {
      navigation(path)
    }
  }

  const isIncorrectEmailForm = () => {
    if (email && !isValidEmail(email)) {
      return { message: '잘못된 이메일 형식입니다.', value: true }
    }

    return { message: '', value: false }
  }

  const isIncorrectPwdForm = () => {
    if (pwd && !isValidPwd(pwd)) {
      return { message: '잘못된 비밀번호 형식입니다.', value: true }
    }

    return { message: '', value: false }
  }

  return (
    <DefaultLayout>
      <Container
        align="center"
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <img src={logo} alt="logo" style={{ height: '60px' }} />
        <p style={{ fontWeight: 'bolder', fontSize: 30, padding: 10 }}>
          Admin for Partners
        </p>
        <p style={{ fontSize: 14, color: '#707070' }}>
          바이스냅(BYSNAP) 서비스에 입점한 판매자들을 위한 관리자 페이지입니다.
          <br /> 입점신청 또는 기타 문의사항은 아래 '입점신청/문의하기'를 통해
          진행 해 주세요.
        </p>
        <Box
          sx={{
            width: 460,
            maxWidth: '100%',
            textAlign: 'left',
            paddingTop: 4,
          }}
        >
          <p style={{ fontWeight: 'bolder', fontSize: 18 }}>EMAIL</p>

          <InputTextField
            error={isIncorrectEmailForm().value}
            helperText={isIncorrectEmailForm().message}
            label="이메일 주소"
            id="email"
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        </Box>
        <Box
          sx={{
            width: 460,
            maxWidth: '100%',
            textAlign: 'left',
            paddingTop: 4,
          }}
        >
          <p style={{ fontWeight: 'bolder', fontSize: 18 }}>PASSWORD</p>

          <InputTextField
            error={isIncorrectPwdForm().value}
            helperText={isIncorrectPwdForm().message}
            label="비밀번호"
            id="password"
            type="password"
            onChange={(e) => {
              setPwd(e.target.value)
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                loginHandler()
              }
            }}
          />
        </Box>
        <Button
          variant="contained"
          sx={{
            display: 'block',
            marginTop: '80px',
            width: 460,
            height: 50,
            backgroundColor: 'black',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
          onClick={loginHandler}
        >
          로그인
        </Button>
        <Button
          variant="text"
          sx={{
            color: '#707070',
            marginTop: '30px',
            textDecorationLine: 'underline',
            fontSize: '16px',
          }}
          onClick={registerStandingPoint}
        >
          입점신청 / 문의하기
        </Button>
      </Container>
    </DefaultLayout>
  )
}

export default HomePage
