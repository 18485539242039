import { Box, Button } from '@mui/material'

import Checkbox from '@mui/material/Checkbox'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Image from '../../element/Image'
import { getDate } from '../../utils'

const heads = [
  '',
  '사진',
  '내용',
  '태그상품',
  '조회수',
  '좋아요',
  '북마크',
  '댓글',
  '공유',
  '등록일',
]

const PostTable = ({ posts, checked, setChecked, clickHandler }) => {
  const buttonRef = useRef([])

  const navigation = useNavigate()
  const isAllChecked = posts.every((post) =>
    checked.map((o) => o.id).includes(post.id)
  )

  const rowHandler = (e, id) => {
    for (let i = 0; i < buttonRef.current?.length; i += 1) {
      let ref = buttonRef.current[i]
      if (ref?.contains(e.target)) {
        return
      }
    }
    clickHandler(id)
  }

  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <Checkbox
              checked={isAllChecked}
              onClick={() => {
                if (isAllChecked) {
                  setChecked([])
                } else {
                  setChecked([...posts])
                }
              }}
            />
          </Th>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {posts.map((post, index) => {
          const [contentLine1, ...line] = post.content.split(' ')
          const contentLine2 = line.join('')
          return (
            <tr
              key={post.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
              onClick={(e) => rowHandler(e, post.id)}
            >
              <Td>
                <Box textAlign={'center'}>
                  <Checkbox
                    ref={(el) => (buttonRef.current[index * 2] = el)}
                    checked={checked.map((o) => o.id).includes(post.id)}
                    onClick={(e) => {
                      if (checked.map((o) => o.id).includes(post.id)) {
                        const idx = checked.findIndex((o) => o.id === post.id)
                        checked.splice(idx, 1)
                      } else {
                        checked.push(post)
                      }
                      setChecked([...checked])
                    }}
                  />
                </Box>
              </Td>
              <Td
                style={{ width: '56px' }}
                ref={(el) => (buttonRef.current[index * 2 + 1] = el)}
              >
                <Button
                  sx={{
                    width: '56px',
                    height: '36px',
                    backgroundColor: '#BACAFF',
                    color: '#003BFF',
                    fontWeight: 'bold',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: '#d9dff6',
                      boxShadow: 'none',
                    },
                  }}
                  onClick={() => navigation(`/snap/add/${post.id}`)}
                >
                  <p>수정</p>
                </Button>
              </Td>
              <Td>
                <Image alt="post" src={post.imgs[0]} width={100} height={100} />
              </Td>
              <Td
                style={{
                  width: '250px',
                  height: '100px',
                }}
              >
                <PBox>{contentLine1}</PBox>
                <PBox>{contentLine2}</PBox>
              </Td>
              <Td>
                <PImageBox post={post} />
              </Td>
              <Td>{post.viewsCnt}</Td>
              <Td>{post.likersCnt}</Td>
              <Td>{post.bookmarkersCnt}</Td>
              <Td>{post.commentsCnt}</Td>
              {/* 공유 수 */}
              <Td>{0}</Td>
              <Td>{getDate(post.createdAt)}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default PostTable

const PImage = ({ src }) => (
  <Image
    alt="p"
    src={src}
    width={50}
    height={50}
    padding={2}
    boxSizing="border-box"
    border="1px solid #e4e4e4"
    borderRadius="10px"
  />
)

const PImageBox = ({ post }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    {post.products?.length === 0 ? (
      <></> ? (
        post.products.length <= 2
      ) : (
        <Box>
          {post.products?.map((p, idx) => (
            <PImage key={idx} src={p.imgs[0]} />
          ))}
        </Box>
      )
    ) : (
      <>
        <Box>
          {post.products?.slice(0, 2).map((p, idx) => (
            <PImage key={idx} src={p.imgs[0]} />
          ))}
        </Box>

        <Box>
          {post.products?.slice(2, 4).map((p, idx) => (
            <PImage key={idx} src={p.imgs[0]} />
          ))}
        </Box>
      </>
    )}
  </Box>
)

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})

const PBox = styled('div')({
  width: '250px',
  boxSizing: 'border-box',
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})
