import { Divider, Grid } from '@mui/material'

import Button from '@mui/material/Button'
import React, { useEffect, useState } from 'react'
import { getCategory } from '../../api/category'
import { ALL_TAG_MAP } from '../../fixture/snap_tag'
import useOpponentTag from '../../hooks/tag/useOpponentTag'

const GENDER = ['여성복', '남성복', '유니섹스']

const CATEGORY = ['아우터', '상의', '하의']

const CIRCUMSTANCE = [
  ['데일리', '학교/캠퍼스', '직장/출근'],
  ['소풍/나들이', '파티', '휴가'],
]
const STYLE = [
  ['캐주얼', '빈티지', '스트릿'],
  ['모던', '스포티', '섹시', '클래식'],
  ['비치웨어/수영복', '발레/요가', '홈웨어', '골프/테니스'],
]

const ProductTags = ({ seller, setSeller }) => {
  const [categoryOption, setCategoryOption] = useState([])
  const { gender, category, circumstance, style } = seller.sellProductInfo

  const { apiOpponent } = useOpponentTag()
  const opponentTag = apiOpponent?.filter((a) => {
    return [...circumstance, ...style].some(
      (t) => ALL_TAG_MAP[t] === a.mainTag.tag
    )
  })

  const getDisable = (tag) => {
    const isInOpponent = opponentTag.some(
      (t) => t.opponentTag.tag === ALL_TAG_MAP[tag]
    )
    const isInCheck = [...circumstance, ...style].includes(tag)
    return isInOpponent && !isInCheck
  }

  const handler = {
    circumstance: (c) => () => {
      const idx = circumstance.findIndex((cir) => cir === c)
      if (idx === -1) {
        circumstance.push(c)
      } else {
        circumstance.splice(idx, 1)
      }
      setSeller({ ...seller })
    },
    style: (s) => () => {
      const idx = style.findIndex((sty) => sty === s)
      if (idx === -1) {
        style.push(s)
      } else {
        style.splice(idx, 1)
      }

      setSeller({
        ...seller,
      })
    },
  }

  useEffect(() => {
    ;(async () => {
      const categories = (await getCategory()).data
      const filtered = categories
        .filter((c) => c.parent === null)
        .map((c) => c.korName)
      setCategoryOption(filtered)
    })()
  }, [])

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
              fontWeight: 'bold',
            }}
          >
            성별
          </p>
          <p
            style={{
              fontSize: '14px',
              color: '#FF0000',
              fontWeight: 'bold',
              marginLeft: 2,
            }}
          >
            *
          </p>
        </Grid>
        <Grid item xs={10}>
          {GENDER.map((g, idx) => (
            <RadioButton
              key={idx}
              value={g}
              formValue={gender}
              onClick={() =>
                setSeller({
                  ...seller,
                  sellProductInfo: { ...seller.sellProductInfo, gender: g },
                })
              }
              text={g}
            />
          ))}
        </Grid>

        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
              fontWeight: 'bold',
            }}
          >
            주요 카테고리
          </p>
        </Grid>

        <Grid item xs={10}>
          {categoryOption.map((c, idx) => (
            <RadioButton
              key={idx}
              value={c}
              formValue={category}
              onClick={() =>
                setSeller({
                  ...seller,
                  sellProductInfo: { ...seller.sellProductInfo, category: c },
                })
              }
              text={c}
            />
          ))}
        </Grid>
        <Grid item xs={1.5} />
        <Grid item xs={10}>
          {gender === '' && category === '' && (
            <p style={{ fontSize: 14, color: 'red' }}>
              최소 1개 이상 선택 해 주세요.
            </p>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ height: 16 }} />
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
              fontWeight: 'bold',
            }}
          >
            상황
          </p>
        </Grid>

        {CIRCUMSTANCE.map((outer, idx) => (
          <React.Fragment key={idx}>
            <Grid item xs={10}>
              {outer.map((c, _idx) => (
                <CheckStyleButton
                  key={_idx}
                  value={c}
                  formValue={circumstance}
                  onClick={handler.circumstance(c)}
                  text={c}
                  disabled={getDisable(c)}
                />
              ))}
            </Grid>
            <Grid item xs={1.5}></Grid>
          </React.Fragment>
        ))}
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
              fontWeight: 'bold',
            }}
          >
            스타일
          </p>
          <p
            style={{
              fontSize: '14px',
              color: '#FF0000',
              fontWeight: 'bold',
              marginLeft: 2,
            }}
          >
            *
          </p>
        </Grid>

        {STYLE.map((outer, idx) => (
          <React.Fragment key={idx}>
            <Grid item xs={10}>
              {outer.map((c, _idx) => (
                <CheckStyleButton
                  key={_idx}
                  value={c}
                  formValue={style}
                  onClick={handler.style(c)}
                  text={c === '비치웨어/수영복' ? `비치웨어/\n수영복` : c}
                  disabled={getDisable(c)}
                />
              ))}
            </Grid>
            <Grid item xs={1.5}></Grid>
          </React.Fragment>
        ))}
      </Grid>
    </>
  )
}

export default ProductTags

const selected = {
  borderRadius: 3,
  backgroundColor: 'black',
  marginRight: 1,
  width: '100px',
  fontWeight: 700,
}

const unselected = {
  borderRadius: 3,
  color: '#707070',
  borderColor: '#707070',
  marginRight: 1,
  width: '100px',
}

/**
 * @param {*} value // button value
 * @param {*} formValue // form value
 * @param {*} onClick // button onclick
 * @param {*} text // button text
 * @returns
 */
const RadioButton = ({ value, formValue, onClick, text }) => {
  return (
    <Button
      variant={value === formValue ? 'contained' : 'outlined'}
      sx={value === formValue ? selected : unselected}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}

/**
 * @param {*} value // button value
 * @param {*} formValue // form value
 * @param {*} onClick // button onclick
 * @param {*} text // button text
 * @param {*} disabled // disable
 * @returns
 */
const CheckStyleButton = ({ value, formValue, onClick, text, disabled }) => {
  const isIn = formValue.includes(value)

  return (
    <Button
      variant={isIn ? 'contained' : 'outlined'}
      sx={isIn ? selected : unselected}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}
