import { Box, Stack } from '@mui/material'
import DefaultLayout from '../../global/DefaultLayout'

const PrivacyPage = () => {
  return (
    <DefaultLayout isLoggedIn isNotScroll>
      <Stack direction="column" alignItems="center">
        <Box
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            paddingX: '20px',
          }}
        >
          <iframe
            title="privacy"
            src="/privacy.html"
            style={{
              width: '87vw',
              height: '85vh',
              backgroundColor: '#ffffff',
              boxSizing: 'border-box',
              padding: '20px',
            }}
          />
        </Box>
      </Stack>
    </DefaultLayout>
  )
}

export default PrivacyPage
