import { instance } from './instance'

export const updateInfo = async (body) => {
  try {
    await instance.post(`/api/seller/update-info`, body, { token: true })
    return { success: true }
  } catch (error) {
    return { success: false }
  }
}

export const getDashboardInfo = async () => {
  try {
    const { data } = await instance.get(`/api/seller/dashboard`, {
      token: true,
    })
    return { success: true, data: data }
  } catch (error) {
    return { success: false }
  }
}

export const getSellerFee = async () => {
  try {
    const { data } = await instance.get(`/api/seller/fee`, {
      token: true,
    })
    return { success: true, data: data }
  } catch (error) {
    return { success: false }
  }
}

export const changePwd = async (password, newPassword) => {
  try {
    const data = await instance.post(
      `/api/seller/change-password`,
      {
        password,
        newPassword,
      },
      { token: true }
    )
    return { success: true, data }
  } catch (error) {
    return { success: false }
  }
}
