import { TextField, styled } from '@mui/material'
import { inputLabelPropsStyle } from '../utils'

export const InputTextField = styled(
  ({ marginTop, marginBottom, error, helperText, ...rest }) => (
    <TextField
      helperText={helperText}
      error={error}
      fullWidth
      InputProps={{
        style: {
          fontSize: '14px',
          height: 40,
        },
      }}
      InputLabelProps={inputLabelPropsStyle}
      {...rest}
    />
  )
)((props) => ({
  backgroundColor: props.bg,
  width: props.width || '100%',
  marginTop: props.marginTop || '10px',
  marginBottom: props.marginBottom,
  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
  '& label.MuiInputLabel-root[data-shrink=false]': {
    top: '-8px',
  },
}))
