import { Button, Divider, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getPosts, removePosts } from '../../api/post'

import Box from '@mui/material/Box'
import DeleteModal from '../../components/DeleteModal'
import { CheckSnackbar } from '../../components/Snackbar'
import { PreviewPostModal } from '../../components/modal/modal'
import Navigation from '../../components/post/navigation'
import PostTable from '../../components/post/post-table'
import DefaultLayout from '../../global/DefaultLayout'
import { CustomSmallDropDownField, SELECT_SIZE } from '../product/productList'
import { inputLabelPropsStyle } from '../../utils'

const PostListPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [posts, setPosts] = useState([])
  const [totalCtn, setTotalCtn] = useState(0)
  const [checked, setChecked] = useState([])
  const [id, setId] = useState('')
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    if (location?.state?.success) {
      setOnCheckBar(true)

      const newLocationState = {
        ...location.state,
        success: false,
      }

      // Navigate to the current location with the updated state
      navigate(location.pathname, { state: newLocationState })
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getPosts(page, size)
      if (success) {
        setPosts(data.data)
        if (totalCtn === 0 && data.count !== 0) {
          setTotalCtn(data.count)
        }
      }
    })()
    setChecked([])
  }, [page, size])

  const clickHandler = (id) => {
    setId(id)
    setPreview(true)
  }

  const [onCheckBar, setOnCheckBar] = useState(false)
  const [onDelModal, setOnDelModal] = useState(false)
  const handleOpen = () => {
    setOnDelModal(true)
  }
  const handleClose = () => {
    setOnDelModal(false)
  }
  const clickDelBtn = () => {
    if (checked.length === 0) {
      return
    }

    handleOpen()
  }

  const clickDelModalBtn = async () => {
    const { success } = await removePosts(checked.map((o) => o.id))
    if (success) {
      handleClose()
      setOnCheckBar(true)
      const filtered = posts.filter(
        (post) => !checked.map((o) => o.id).includes(post.id)
      )
      setPosts([...filtered])
      setTotalCtn(filtered.length)
      setChecked([])
    } else {
      alert('에러가 발생했습니다.')
    }
  }

  return (
    <DefaultLayout isLoggedIn>
      <DeleteModal
        open={onDelModal}
        handleClose={handleClose}
        clickModalBtn={clickDelModalBtn}
      />
      <CheckSnackbar open={onCheckBar} setOpen={setOnCheckBar} />

      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button
            sx={{
              width: '88px',
              height: '40px',
              backgroundColor: '#FFF0F0',
              color: '#FF0000',
              fontWeight: 'bold',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#fcf5f5',
                boxShadow: 'none',
              },
            }}
            onClick={clickDelBtn}
          >
            <p>선택 삭제</p>
          </Button>

          <CustomSmallDropDownField
            fullWidth
            disableClearable
            size="small"
            value=""
            onChange={(event, value) => {
              const size = Number(
                SELECT_SIZE.find((o) => o.name === value).value
              )
              setSize(size)
              setPage(1)
            }}
            ListboxProps={{
              style: {
                fontSize: '14px',
              },
            }}
            id="selling_option"
            options={SELECT_SIZE.map((o) => o.name)}
            filterOptions={(options, { inputValue }) => {
              return options.filter(
                (option) => option !== '' && option.includes(inputValue)
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={inputLabelPropsStyle}
                label="20개씩"
              />
            )}
          />
        </Box>

        <Divider sx={{ height: 16 }} />
        <PostTable
          posts={posts}
          checked={checked}
          setChecked={setChecked}
          clickHandler={clickHandler}
        />
        <Navigation page={page} setPage={setPage} count={totalCtn} />
      </Box>
      {preview && (
        <PreviewPostModal
          id={id}
          open={preview}
          onClose={() => setPreview(false)}
        />
      )}
    </DefaultLayout>
  )
}

export default PostListPage
