import { useEffect, useState } from 'react'
import { getNotice } from '../../api/notice'

const useNotice = (id) => {
  const [notice, setNotice] = useState({})

  useEffect(() => {
    ;(async () => {
      const { data } = await getNotice(id)
      setNotice(data)
    })()
  }, [])

  return { notice }
}

export default useNotice
