import styled from 'styled-components'

import Image from '../../element/Image'
import {
  getDiscountedSellingPrice,
  getUsPrice,
  priceWrapper,
} from '../../pages/product/addProduct'

const heads = [
  '상품번호',
  '사진',
  '상품명',
  '판매가',
  '할인가(KRW)',
  '할인가(USD)',
]

const SendingTable = ({ checked, discount }) => {
  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {checked.map((product, index) => {
          const { productNumber, name, imgs, sellingPrice } = product

          return (
            <tr
              key={product.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
            >
              <Td>{productNumber}</Td>
              <Td style={{ width: '80px' }}>
                <Image alt="product" src={imgs[0]} width={60} height={60} />
              </Td>
              <Td style={{ width: '400px' }}>{name}</Td>

              <Td>₩{priceWrapper(sellingPrice)}</Td>
              <Td style={{ color: 'red' }}>
                ₩
                {priceWrapper(
                  getDiscountedSellingPrice(
                    sellingPrice,
                    discount.type,
                    discount.amount
                  )
                )}
              </Td>
              <Td style={{ color: 'red' }}>
                $
                {getUsPrice(
                  priceWrapper(
                    getDiscountedSellingPrice(
                      sellingPrice,
                      discount.type,
                      discount.amount
                    )
                  )
                )}
              </Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default SendingTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
  borderTop: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})
