import styled from 'styled-components'
import Checkbox from '@mui/material/Checkbox'
import { Box, Button } from '@mui/material'

const heads = ['모델이름', '성별', '키', '몸무게', '대표모델', '수정']

const ModelTable = ({ models, checked, setChecked }) => {
  const isAllChecked = models.every((model) =>
    checked.map((o) => o.id).includes(model.id)
  )

  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <Checkbox
              checked={isAllChecked}
              onClick={() => {
                if (isAllChecked) {
                  setChecked([])
                } else {
                  setChecked([...models])
                }
              }}
            />
          </Th>
          {heads.map((head, idx) => (
            <Th key={idx}>{head}</Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {models.map((model) => {
          return (
            <tr key={model.id} style={{ borderBottom: '1px solid #ddd' }}>
              <Td>
                <Box textAlign={'center'}>
                  <Checkbox
                    checked={checked.map((o) => o.id).includes(model.id)}
                    onClick={(e) => {
                      if (checked.map((o) => o.id).includes(model.id)) {
                        const idx = checked.findIndex((o) => o.id === model.id)
                        checked.splice(idx, 1)
                      } else {
                        checked.push(model)
                      }
                      setChecked([...checked])
                    }}
                  />
                </Box>
              </Td>
              <Td>{model.name}</Td>
              <Td>{model.sex}</Td>
              <Td>{model.height}</Td>
              <Td>{model.weight}</Td>
              <Td>{model.isRepresentative && <RepBox>대표</RepBox>}</Td>
              <Td style={{ width: '56px' }}>
                <Button
                  sx={{
                    width: '75px',
                    height: '39px',
                    backgroundColor: '#BACAFF',
                    color: '#003BFF',
                    fontWeight: 'bold',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: '#d9dff6',
                      boxShadow: 'none',
                    },
                  }}
                >
                  수정하기
                </Button>
              </Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ModelTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  height: '59px',
})

const RepBox = styled('div')({
  height: '30px',
  width: '40px',
  margin: 'auto',
  borderRadius: '5px',
  backgroundColor: '#5d5d5d',
  color: '#ffffff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 'bold',
})
