import { isAvailableSeller, setSellerToLocalStorage } from './storage'

import axios from 'axios'
import { setToken } from './cookie'
import { instance } from './instance'

export const login = async (email, pwd) => {
  try {
    const {
      data: {
        accessToken,
        refreshToken,
        seller: {
          name,
          profileImg,
          id,
          approveStatus,
          managerName,
          managerNumber,
          sellProductInfo,
          bankInfo,
          businessInfo,
          sellerEmail,
        },
      },
    } = await instance.post(`/api/auth/login-seller`, {
      email,
      password: pwd,
    })

    await setToken(accessToken, refreshToken)
    await setSellerToLocalStorage(
      name,
      approveStatus,
      profileImg,
      id,
      managerName,
      managerNumber,
      sellProductInfo,
      bankInfo,
      businessInfo,
      sellerEmail
    )

    const isAvailable = isAvailableSeller()

    const path = isAvailable ? 'dashboard' : 'stylist/info'

    return { success: true, path }
  } catch (error) {
    let msg = '로그인에 실패하였습니다.'

    switch (error?.response?.data?.errorCode) {
      case 'ERR_01':
        msg = '셀러 가입한 유저를 찾을 수 없습니다.'
        break

      case 'ERR_02':
      case 'ERR_03':
        msg = '셀러권한이 없습니다.'
        break

      case 'ERR_04':
        msg = '이메일 혹은 비밀번호를 다시 입력해주세요.'
        break

      case 'ERR_06':
        msg = '미승인 판매자입니다.'
        break

      default:
        break
    }

    alert(msg)

    return { success: false }
  }
}

export const signUp = async (body) => {
  try {
    const data = await instance.post(`/api/auth/sign-seller`, body)
    return { success: true, data }
  } catch (error) {
    if (error?.response?.data?.errorCode === 'ERR_05') {
      alert('이미 등록된 이메일이 존재합니다.')
    }
    return { success: false }
  }
}

export const validateBizInfo = async (b_no, start_dt, p_nm) => {
  try {
    const { status, data } = await axios.post(
      `https://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey=${process.env.REACT_APP_BIZ_INFO_VALIDATE_SERVICE_KEY}`,
      {
        businesses: [
          {
            b_no,
            start_dt,
            p_nm,
          },
        ],
      },
      {
        headers: {
          Authorization: process.env.REACT_APP_BIZ_INFO_VALIDATE_PRIVATE_KEY,
        },
      }
    )
    if (status !== 200) {
      return { success: false }
    }
    if (data.data[0].valid !== '01') {
      return { success: false }
    }
    return { success: true }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}
