import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Divider, Stack, Typography } from '@mui/material'
import Image from '../../element/Image'
import useUntagProductList from '../../hooks/product/useUntagProductList'

const UntagProductList = () => {
  const { count, untagProduct, prevPage, nextPage } = useUntagProductList()

  return (
    <Box
      sx={{
        marginX: '10px',
        paddingX: '20px',
        paddingTop: '16px',
        paddingBottom: '40px',
        backgroundColor: 'white',
        marginBottom: '10px',
      }}
    >
      <Stack direction="row" alignItems="end" gap={1}>
        <Typography fontWeight={700} fontSize={18}>
          태그되지 않은 상품
        </Typography>
        <Typography color="#707070" fontSize={14}>
          {count}개의 상품이 스냅사진 태그를 기다리고 있어요!
        </Typography>
      </Stack>
      <Box height={20} />
      <Divider />
      <Box height={20} />
      <Stack direction="row" alignItems="center">
        <div onClick={prevPage}>
          <ArrowBackIos />
        </div>
        <Stack
          direction="row"
          flex={1}
          justifyContent="space-evenly"
          flexWrap="wrap"
        >
          {untagProduct.slice().map((product) => {
            return (
              <Stack direction="column" key={product.id} width={140} gap="10px">
                <Image src={product.imgs[0]} width={140} height={140} />
                <Typography
                  fontSize={14}
                  width="100%"
                  textOverflow="ellipsis"
                  wordBreak="break-word"
                  overflow="hidden"
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {product.name}
                </Typography>
              </Stack>
            )
          })}
        </Stack>
        <div onClick={nextPage}>
          <ArrowForwardIos />
        </div>
      </Stack>
    </Box>
  )
}

export default UntagProductList
