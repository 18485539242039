import { Box } from '@mui/material'
import logo from '../../static/bysnap.png'
import register1 from '../../static/register1.png'
import register2 from '../../static/register2.png'
import register3 from '../../static/register3.png'

const RegisterHeader = ({ step }) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="start"
        justifyContent="center"
        paddingBottom={'50px'}
      >
        <img
          src={logo}
          alt="logo"
          style={{ display: 'inline-block', height: '30px' }}
        />
        <p
          style={{
            paddingLeft: '10px',
            fontSize: '28px',
            fontWeight: 'bolder',
            lineHeight: '30px',
          }}
        >
          Partners 회원가입
        </p>
      </Box>
      {getImg(step)}
    </>
  )
}

export default RegisterHeader

const getImg = (step) => {
  let src = ''

  switch (step) {
    case 1:
      src = register1
      break
    case 2:
      src = register2
      break
    case 3:
      src = register3
      break
    default:
      throw new Error()
  }

  return <img src={src} style={{ width: '400px' }} alt="register_biz" />
}
