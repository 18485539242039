import { Divider, Grid, Input } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import remove_icon from '../../static/remove_icon.svg'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useState } from 'react'
import { InputTextField } from '../../element/InputTextField'

const _SIZE = ['XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL']
const SIZE0 = ['FREE']
const SIZE1 = [..._SIZE, '4XL', '5XL']
const SIZE2 = Array.from({ length: 16 }, (_, idx) => 25 + idx + '')
const SIZE20 = Array.from({ length: 4 }, (_, idx) => 41 + idx + '')
const SIZE3 = Array.from({ length: 4 }, (_, idx) => 1 + idx + '')
const SIZE4 = ['W44', 'W55', 'W66', 'W77', 'W88']
const SIZE5 = ['short', 'regular', 'long']
const [SIZE6, SIZE7, SIZE8] = SIZE5.map((type) =>
  _SIZE.map((size) => `${type}  ${size}`)
)

const SIZE = [
  SIZE0,
  SIZE1,
  SIZE2,
  SIZE20,
  SIZE3,
  SIZE4,
  SIZE5,
  SIZE6,
  SIZE7,
  SIZE8,
]

const getWidth = (idx) => {
  switch (idx) {
    case 2:
      return 60
    case 6:
    case 7:
    case 8:
      return 90
    default:
      return 80
  }
}

export const btnState = (clicked) =>
  clicked
    ? {
        variant: 'contained',
        sx: {
          backgroundColor: '#707070',
        },
      }
    : {
        variant: 'outlined',
        sx: {
          color: '#707070',
          borderColor: '#707070',
        },
      }

const AddProductOption = ({ setProduct, product, sizeOptions }) => {
  const [sizes, setSizes] = useState([])
  const [colorInput, setColorInput] = useState('')

  const rows = product.option.inventoryCounts.map((inv) => {
    const { index, count } = inv
    const ret = { count, index }

    for (let i = 0; i < index.length; ++i) {
      const idx = index[i]
      const opt = product.option.options[i]
      ret[opt.type] = opt.list[idx]
    }

    return ret
  })

  const optionClickHandler = () => {
    if (!colorInput || sizes.length === 0) {
      alert('색상 혹은 사이즈는 필수 선택값입니다.')
    }

    const colors = colorInput
      .trim()
      .split(',')
      .filter((c) => c !== '')
    const option1 = { type: 'color', list: colors }
    const option2 = { type: 'size', list: sizes }
    const options = [option1, option2]
    const inventoryCounts = colors
      .map((_, x) =>
        sizes.map((_, y) => ({
          index: [x, y],
          count: 10,
          discount: 0,
        }))
      )
      .flat(2)

    if (!sizeOptions || sizeOptions.length === 0) {
      alert('카테고리(중)을 선택해주세요.')
    }

    setProduct({
      ...product,
      option: { options, inventoryCounts },
      sizeGuide: sizes.map((size) => [
        `size:${size}`,
        ...sizeOptions.map((option) => `${option}:`),
      ]),
    })
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <p
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          컬러
        </p>
      </Box>
      <Divider sx={{ height: 8, marginBottom: 2 }} />
      <InputTextField
        width="500px"
        bg="white"
        label="판매하시려는 제품의 색상을 입력 해 주세요."
        onChange={(e) => {
          setColorInput(e.target.value)
        }}
      />
      <p
        style={{
          fontSize: '14px',
          color: '#707070',
          marginTop: '6px',
          marginBottom: '40px',
        }}
      >
        색상은 " , " 로 구분됩니다. 복수 입력 시 " , "를 꼭 입력 해 주세요. (ex.
        Black, White)
      </p>
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <p
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          사이즈
        </p>
      </Box>
      <Divider sx={{ height: 8, marginBottom: 2 }} />
      <Grid container columnSpacing={4} rowSpacing={2} sx={{ marginTop: 0 }}>
        {SIZE.map((row, idx) => (
          <Grid item xs={12} key={idx}>
            {row.map((sizeData) => (
              <Button
                key={idx + sizeData}
                value={sizeData}
                onClick={(e) => {
                  const newSize = e.target.value
                  const idx = sizes.findIndex((val) => val === newSize)

                  if (idx === -1) {
                    if (!sizes.every((s) => row.includes(s))) {
                      setSizes([newSize])
                    } else {
                      setSizes([...sizes, newSize])
                    }
                  } else {
                    sizes.splice(idx, 1)
                    setSizes([...sizes])
                  }
                }}
                variant={btnState(sizes.includes(sizeData)).variant}
                sx={{
                  fontSize: '12px',
                  borderRadius: 3,
                  marginRight: 1,
                  width: getWidth(idx),
                  height: '40px',
                  ...btnState(sizes.includes(sizeData)).sx,
                }}
              >
                {sizeData}
              </Button>
            ))}
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              borderRadius: 3,
              backgroundColor: 'black',
              marginRight: 1,
              marginY: 3,
              width: '130px',
            }}
            onClick={optionClickHandler}
          >
            옵션 등록하기
          </Button>
        </Grid>
        <Grid item xs={12}>
          <p
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            옵션 목록
          </p>
          <Divider sx={{ height: 8, marginBottom: 2 }} />
        </Grid>
        <Grid item xs={7}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ color: '#707070', fontWeight: 'bold' }}
                  >
                    컬러
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: '#707070', fontWeight: 'bold' }}
                  >
                    사이즈
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: '#707070', fontWeight: 'bold' }}
                  >
                    재고
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: '#707070', fontWeight: 'bold' }}
                  >
                    옵션 제외
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, idx) => (
                  <TableRow
                    key={idx}
                    disabled={true}
                    sx={
                      rows[idx]['count'] === -1
                        ? {
                            backgroundColor: '#e3e3e3',
                            color: '#e3e3e3',
                          }
                        : {}
                    }
                  >
                    <TableCell align="center" component="th" scope="row">
                      {row.color}
                    </TableCell>
                    <TableCell align="center">{row.size}</TableCell>
                    <TableCell align="center">
                      {
                        <Input
                          sx={{ width: '50px' }}
                          value={
                            rows[idx]['count'] === -1 ? 'X' : rows[idx]['count']
                          }
                          disabled={rows[idx]['count'] === -1}
                          onChange={(e) => {
                            const inventoryCounts =
                              product.option.inventoryCounts
                            const input =
                              e.target.value === '' ? 0 : e.target.value
                            if (!/^[0-9]+$/.test(input)) {
                              return
                            }
                            inventoryCounts[idx]['count'] = Number(
                              e.target.value
                            )
                            setProduct({
                              ...product,
                              option: { ...product.option, inventoryCounts },
                            })
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell align="center">
                      <DelBtnImg
                        onClick={() => {
                          const inventoryCounts = product.option.inventoryCounts
                          const count = inventoryCounts[idx]['count']
                          inventoryCounts[idx]['count'] = count === -1 ? 0 : -1
                          setProduct({
                            ...product,
                            option: { ...product.option, inventoryCounts },
                          })
                        }}
                        alt=""
                        src={remove_icon}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Box></Box>
        </Grid>
      </Grid>
    </>
  )
}

const DelBtnImg = styled('img')({
  width: '20px',
  cursor: 'pointer',
})

export default AddProductOption
