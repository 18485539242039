import { PlayArrow } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
  styled,
} from '@mui/material'

const CustomAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const CustomAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<PlayArrow sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  fontWeight: 600,
}))

const QNA = (props) => {
  const { question, answer } = props.qna

  return (
    <>
      <CustomAccordion>
        <CustomAccordionSummary>{question}</CustomAccordionSummary>
        <AccordionDetails sx={{ paddingLeft: '40px' }}>
          {answer}
        </AccordionDetails>
      </CustomAccordion>
      <Divider />
    </>
  )
}

const FAQCard = (props) => {
  const faq = props.faq
  const list = faq.list
  return (
    <Box>
      <Typography sx={{ fontWeight: 700, fontSize: '30px', padding: '10px' }}>
        {faq.category}
      </Typography>
      <Divider />
      {list && list.map((qna) => <QNA qna={qna} />)}
    </Box>
  )
}

export default FAQCard
