import { debounce } from 'lodash'
import React from 'react'
import { searchProduct } from '../../api/product'

const useSearchProduct = () => {
  const [keyword, setKeyword] = React.useState('')
  const [products, setProducts] = React.useState([])
  const [hasNext, setHasNext] = React.useState(true)
  const [page, setPage] = React.useState(1)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  const fetchData = async () => {
    try {
      setLoading(true)
      const { items, hasNext } = await searchProduct({ keyword, page })
      setProducts((prev) => prev.concat(items))
      setHasNext(hasNext)
      setPage(page + 1)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const debouncedFunction = React.useMemo(
    () =>
      debounce(() => {
        setPage(1)
        setHasNext(true)
        setProducts([])
        setLoading(true)
        setError(false)
      }, 500),
    [debounce]
  )

  React.useEffect(() => {
    debouncedFunction()
    return () => debouncedFunction.cancel()
  }, [keyword, debouncedFunction])

  const handler = {
    keyword: (e) => setKeyword(e.target.value),
    fetchData: fetchData,
  }

  return {
    keyword,
    loading,
    error,
    hasNext,
    handler,
    products,
  }
}

export default useSearchProduct
