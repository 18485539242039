import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import ProductLocs, {
  PREVIEW_IMAGE_SIZE,
} from '../../components/snap/ProductLocs'

import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getImagesUrl } from '../../api/image'
import Image from '../../element/Image'
import addSnapIcon from '../../static/add_snap.svg'
import ProductOption from './ProductOption'
import SnapTags from './SnapTags'

import { getSellerFromLocalStorage } from '../../api/storage'
import {
  MAX_IMAGE_SIZE,
  MAX_IMAGE_SIZE_MB,
  SNAP_IMAGE_LENGTH,
} from '../../fixture/file'
import { CATEGORY_MAP, SNAP_GENDER } from '../../pages/snap/constants'
import { inputLabelPropsStyle } from '../../utils'
import usePreviewSnapFormModal from './PreviewSnapFormModal'
// import { resizeImage } from '../../fixture/resize'

const MAX_TAG_IMAGE = 4

const SnapForm = ({
  imgs,
  content,
  productLocs,
  tags,
  setCurrentImageIndex,
  setModalProductLocs,
  setModal,
  setSnapForm,
  snapForm,
  onSubmit,
  buttonName,
}) => {
  const seller = getSellerFromLocalStorage()

  const getProductLocsByUrl = (url) => {
    const result = productLocs.filter((e) => e.url === url)

    return result
  }

  const openModal = (index) => {
    setCurrentImageIndex(index)

    const initData = productLocs.filter((e) => e.url === imgs[index])
    setModalProductLocs(initData)
    setModal(true)
  }

  const formHandler = (e) => {
    setSnapForm({
      ...snapForm,
      [e.target.name]: e.target.value,
    })
  }

  const tagHandler = (tags) => {
    setSnapForm({
      ...snapForm,
      tags: tags,
    })
  }

  const snapImageHandler = async (e) => {
    if (!e.target.files) {
      return alert('잘못된 이미지 형식입니다.')
    }

    for (let i = 0; i < e.target.files.length; i += 1) {
      if (e.target.files[i].size > MAX_IMAGE_SIZE) {
        return alert(`${e.target.files[i].name}의 사이즈가 20MB가 넘습니다.`)
      }
    }

    const files = e.target.files

    if (!files) return alert('잘못된 이미지 형식입니다.')

    for (let i = 0; i < files.length; i += 1) {
      if (files[i].size > MAX_IMAGE_SIZE) {
        return alert(`${files[i].name}의 사이즈가 20MB가 넘습니다.`)
      }
    }

    const formData = new FormData()
    formData.append('type', 'post')
    for (let i = 0; i < files.length; i++) {
      const newFileName = `${seller.name ? seller.name : 'post'}.${files[i].name
        .split('.')
        .pop()}`
      formData.append(
        'files',
        new Blob([files[i]], { type: files[i].type }),
        newFileName
      )
    }

    const { success, data } = await getImagesUrl(formData)

    if (!success) return

    if (imgs.length + data.length > SNAP_IMAGE_LENGTH)
      return alert('over 10 image')

    setSnapForm({ ...snapForm, imgs: [...imgs, ...data] })
  }

  const removeSnap = (index) => {
    const deletedLocs = productLocs.filter((e) => e.url !== imgs[index])
    const deleted = imgs.filter((_, idx) => idx !== index)

    setSnapForm({ ...snapForm, imgs: deleted, productLocs: deletedLocs })
  }

  const upImage = (index) => {
    if (index === 0) return

    const up = imgs

    ;[up[index - 1], up[index]] = [up[index], up[index - 1]]

    setSnapForm({ ...snapForm, imgs: up })
  }

  const downImage = (index) => {
    if (index === imgs.length - 1) return

    const down = imgs

    ;[down[index], down[index + 1]] = [down[index + 1], down[index]]

    setSnapForm({ ...snapForm, imgs: down })
  }

  const {
    canPreview,
    open,
    modal: PreviewSnapFormModal,
  } = usePreviewSnapFormModal(snapForm)

  const checkGender = (gender) => snapForm.gender === gender

  const getVariantStyle = (gender) =>
    checkGender(gender) ? 'contained' : 'outlined'
  const getBackgroundColor = (gender) => (checkGender(gender) ? 'black' : '')
  const getForegroundColor = (gender) => (checkGender(gender) ? '' : '#707070')

  const changeModelHeight = (e) => {
    const modelHeight = e.target.value
    const decimalRegex = /^\d+(\.\d*)?$/

    if (e.target.value === '') {
      setSnapForm((prev) => {
        return { ...prev, modelHeight: 0 }
      })
    }

    if (decimalRegex.test(modelHeight)) {
      setSnapForm((prev) => {
        return { ...prev, modelHeight }
      })
    }
  }

  const changeModelWeight = (e) => {
    const modelWeight = e.target.value
    const decimalRegex = /^\d+(\.\d*)?$/

    if (e.target.value === '') {
      setSnapForm((prev) => {
        return { ...prev, modelWeight: 0 }
      })
    }

    if (decimalRegex.test(modelWeight)) {
      setSnapForm((prev) => {
        return { ...prev, modelWeight }
      })
    }
  }

  const changeModelSize = (key) => (e) => {
    const regex = /^[^\u3131-\u318E\uAC00-\uD7A3]*$/
    if (!regex.test(e.target.value)) {
      return
    }
    let sizes = Array.from(snapForm.modelSizes)

    const index = sizes.findIndex((size) => size.startsWith(key))
    const value = `${key}:${e.target.value}`

    sizes[index] = value

    setSnapForm((prev) => ({
      ...prev,
      modelSizes: sizes,
    }))
  }

  return (
    <Box
      sx={{
        marginX: '10px',
        paddingX: '20px',
        paddingTop: '16px',
        paddingBottom: '40px',
        backgroundColor: 'white',
      }}
    >
      <PreviewSnapFormModal />
      <Box sx={{ display: 'flex', alignItems: 'end' }}>
        <p
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          스냅 이미지 추가 / 상품 태그
        </p>
      </Box>
      <Divider sx={{ height: 16 }} />
      <Box
        sx={{
          borderRadius: 2,
          backgroundColor: '#FFF0F0',
          height: '60px',
          position: 'relative',
          marginTop: '12px',
          marginBottom: '20px',
        }}
      >
        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#F57979',
            height: '60px',
            width: '10px',
            position: 'absolute',
            left: '0',
            top: '0',
          }}
        ></Box>
        <p
          style={{
            fontSize: '14px',
            marginLeft: '30px',
            position: 'absolute',
            top: '50%',
            transform: 'translate(0%, -50%)',
          }}
        >
          혹시 상품 등록은 해주셨나요? 스냅 사진에 있는 상품을 태그하려면 먼저
          상품 등록을 해주셔야합니다.
          <br />
          혹시 상품 등록을 안해주셨다면 상품 등록 페이지로 이동 후 상품 등록을
          먼저 진행 해 주세요.
          <Link to="/product/add">
            <span
              style={{
                color: '#FF0000',
                fontWeight: 'bold',
                paddingLeft: '10px',
                textDecoration: 'underline',
              }}
            >
              상품 등록 하러가기
            </span>
          </Link>
        </p>
      </Box>
      {imgs.length !== 0 && productLocs.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ color: 'red', fontSize: 14 }}>
            최소 1개 이상의 상품이 태그 되어야합니다. 상품 태그를 추가 해
            주세요!
          </Typography>
          <Typography sx={{ color: '#707070', fontSize: 14 }}>
            *이미지당 최대 {MAX_TAG_IMAGE}개 태그 추가 가능
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >
        <Box>
          <label htmlFor="snap-image">
            <Image src={addSnapIcon} alt="snap_image_add" width={160} />
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
              }}
            >
              · 최대 등록 개수: {SNAP_IMAGE_LENGTH}개
              <br />
              · 권장비율: 정방형(1:1)
              <br />· 허용 확장자: jpg, jpeg, png
              <br />· 이미지 장당 {MAX_IMAGE_SIZE_MB}MB 제한
            </p>
          </label>
          <input
            type="file"
            id="snap-image"
            style={{ display: 'none' }}
            accept="image/*"
            onChange={snapImageHandler}
            multiple
          />
        </Box>
        <Box sx={{ width: 40 }} />

        <Box>
          {imgs.map((img, index) => (
            <Box key={index}>
              {index !== 0 && <Box sx={{ height: 20 }} />}
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box
                  sx={{
                    width: PREVIEW_IMAGE_SIZE,
                    height: PREVIEW_IMAGE_SIZE,
                    position: 'relative',
                  }}
                >
                  <Image
                    src={img}
                    alt=""
                    style={{ objectFit: 'cover' }}
                    width={PREVIEW_IMAGE_SIZE}
                    height={PREVIEW_IMAGE_SIZE}
                  />
                  {getProductLocsByUrl(img).map((locs, idx) => (
                    <Box
                      key={idx}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        openModal(index)
                      }}
                    >
                      <ProductLocs loc={locs} size={PREVIEW_IMAGE_SIZE} />
                    </Box>
                  ))}
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      width: PREVIEW_IMAGE_SIZE,
                      height: 375,
                      zIndex: 2,
                      position: 'absolute',
                    }}
                  />
                  <Box
                    sx={{
                      bottom: 0,
                      left: 0,
                      width: PREVIEW_IMAGE_SIZE,
                      height: 55,
                      zIndex: 3,
                      position: 'absolute',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      padding: 2,
                      backgroundColor: 'rgba(112, 112, 112, 0.2)',
                    }}
                  >
                    <DeleteIcon
                      sx={{ color: '#ffffff' }}
                      onClick={() => {
                        removeSnap(index)
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: 'white', color: 'black' }}
                      onClick={() => {
                        openModal(index)
                      }}
                    >
                      + 태그추가
                    </Button>
                    <Box>
                      <KeyboardArrowUpIcon
                        sx={{ color: '#ffffff' }}
                        onClick={() => upImage(index)}
                      />
                      <KeyboardArrowDownIcon
                        sx={{ color: '#ffffff' }}
                        onClick={() => downImage(index)}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: 20 }} />
                <Box width={'100%'}>
                  <Grid container>
                    {getProductLocsByUrl(img).map((locs, idx) => (
                      <Grid
                        item
                        xs={getProductLocsByUrl(img).length === 1 ? 12 : 6}
                        key={idx}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <ProductOption product={locs.product} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  {getProductLocsByUrl(img).filter(
                    (p) => p.approveStatus === 'REJECT'
                  ).length !== 0 && (
                    <Typography sx={{ fontSize: 14, color: 'red' }}>
                      *승인 반려된 상품은 태그 등록이 불가합니다. 해당 상품 태그
                      제거 후 다시 등록 해 주세요.
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider sx={{ height: 16 }} />
      <Box sx={{ display: 'flex', alignItems: 'start', marginY: '20px' }}>
        <p
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          스냅 이미지 내용 및 스타일 추가
        </p>
      </Box>
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item xs={5.2}>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
              fontWeight: 'bold',
            }}
          >
            내용
          </p>
          <Box sx={{ height: '10px' }} />
          <InputMultiTextField
            id="outlined-multiline-static"
            label={
              !content && (
                <div>
                  스냅 사진에 대해 코멘트하실 내용이 있으시면 등록 해 주세요.
                  (최대 2,000자)
                  <br />
                  <br /> *해외 이용자들이 확인하는 게시물입니다. 가급적 영문으로
                  입력 부탁드리며,
                  <br />
                  영문으로 작성이 어려운 경우 국문으로 작성 부탁드립니다!
                </div>
              )
            }
            multiline
            size="small"
            fullWidth
            InputProps={{
              style: {
                fontSize: '14px',
              },
            }}
            InputLabelProps={inputLabelPropsStyle}
            rows={16}
            name="content"
            value={content}
            onChange={(e) => formHandler(e)}
          />
          <br />
          <br />
          <>
            <Typography color="#707070" fontWeight={700}>
              모델 정보
            </Typography>
            <Grid container>
              <Grid item xs={12} container>
                <Grid
                  item
                  xs={1}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography color="#707070" fontSize={14}>
                    성별
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={11}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  {SNAP_GENDER.map((g) => {
                    return (
                      <Button
                        key={`SNAP_GENDER_${g}`}
                        variant={getVariantStyle(g)}
                        sx={{
                          borderRadius: 3,
                          backgroundColor: getBackgroundColor(g),
                          color: getForegroundColor(g),
                          borderColor: getForegroundColor(g),
                          marginRight: 1,
                          width: '100px',
                        }}
                        onClick={() => setSnapForm({ ...snapForm, gender: g })}
                      >
                        {g}
                      </Button>
                    )
                  })}
                </Grid>
              </Grid>
              <br />
              <br />
              <br />
              <Grid
                item
                xs={6}
                display="flex"
                flexDirection="row"
                alignItems="center"
                container
              >
                <Grid item xs={2}>
                  <Typography color="#707070" fontSize={14}>
                    키
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    sx={{
                      padding: 0,
                      '& ::placeholder': {
                        fontSize: 14,
                        color: '#707070',
                        opacity: 1,
                      },
                    }}
                    size="small"
                    placeholder="cm 단위로 입력 해 주세요 :)"
                    value={
                      snapForm.modelHeight === 0 ? '' : snapForm.modelHeight
                    }
                    onChange={changeModelHeight}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                flexDirection="row"
                alignItems="center"
                container
                paddingX="10px"
              >
                <Grid item xs={2}>
                  <Typography color="#707070" fontSize={14}>
                    몸무게
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    sx={{
                      padding: 0,
                      '& ::placeholder': {
                        fontSize: 14,
                        color: '#707070',
                        opacity: 1,
                      },
                    }}
                    size="small"
                    placeholder="kg 단위로 입력 해 주세요 :)"
                    value={
                      snapForm.modelWeight === 0 ? '' : snapForm.modelWeight
                    }
                    onChange={changeModelWeight}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Typography color="#707070" fontWeight={700}>
              모델 착용 사이즈
            </Typography>
            <br />
            <Grid container>
              {snapForm.modelSizes.map((m) => {
                const [key, value] = m.split(':')
                return (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    key={m.split(':')[0]}
                    paddingY={1}
                    container
                  >
                    <Grid item xs={2.5}>
                      <Typography color="#707070" fontSize={14}>
                        {CATEGORY_MAP[key.toUpperCase()] ?? key}
                      </Typography>
                    </Grid>
                    <Grid item xs={9.5}>
                      <TextField
                        sx={{
                          padding: 0,
                          '& ::placeholder': {
                            fontSize: 14,
                            color: '#707070',
                            opacity: 1,
                          },
                        }}
                        size="small"
                        placeholder="태그하신 상품이 없으면 공란으로 남겨주세요!"
                        value={value}
                        onChange={changeModelSize(key)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </>
        </Grid>
        <Grid item xs={6}>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
              fontWeight: 'bold',
            }}
          >
            스타일
          </p>
          <SnapTags tags={tags} tagHandler={tagHandler} />
        </Grid>
      </Grid>
      <Box marginY={3}>
        <Button
          variant="contained"
          size="large"
          sx={{
            backgroundColor: '#FFF0F0',
            color: '#F57979',
            fontWeight: 'bold',
            boxShadow: 'none',
            marginY: 1.4,
            borderRadius: 2,
          }}
          onClick={() => {
            window.history.back()
          }}
        >
          취소
        </Button>
        <Button
          size="large"
          variant="contained"
          sx={{
            backgroundColor: 'black',
            fontWeight: 'bold',
            borderRadius: 2,
            boxShadow: 'none',
            marginX: 2,
          }}
          onClick={() => {
            onSubmit()
          }}
        >
          {buttonName}
        </Button>
        <Button
          size="large"
          variant="contained"
          sx={{
            backgroundColor: 'black',
            fontWeight: 'bold',
            borderRadius: 2,
            boxShadow: 'none',
            marginX: 2,
          }}
          onClick={open}
          disabled={!canPreview}
        >
          미리보기
        </Button>
      </Box>
    </Box>
  )
}

export default SnapForm

export const InputMultiTextField = styled(TextField)({
  marginTop: '10px',
  width: '90%',
  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    // 해당 input focus 되었을 때 placeholder text color
    // floatng label을 사용할 때 처리 필요하다
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
  '& label.MuiInputLabel-root[data-shrink=false]': {
    top: '-8px',
  },
})
