import { useEffect, useState } from 'react'
import {
  EN_CLIMATE_TAG,
  EN_OCCUPATION_SPECIAL_TAG,
  EN_STYLE_TAG,
  OCCUPATION_SPECIAL,
  STYLE,
} from '../../fixture/snap_tag'

import { CheckCircleOutline } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { addPost } from '../../api/post'
import { getSellerFromLocalStorage } from '../../api/storage'
import Snackbar from '../../components/Snackbar'
import SnapForm from '../../components/snap/SnapForm'
import SnapModal from '../../components/snap/SnapModal'
import UntagProductList from '../../components/snap/UntagProductList'
import DefaultLayout from '../../global/DefaultLayout'
import { SNAP_GENDER_MAP } from './constants'

const AddSnapPage = () => {
  const navigation = useNavigate()

  const [modal, setModal] = useState(false)
  const [snackbar, setSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const [modalProductLocs, setModalProductLocs] = useState([])
  const [snapForm, setSnapForm] = useState({
    content: '',
    tags: [],
    imgs: [],
    productIds: [],
    productLocs: [],
    gender: '해당없음',
    modelHeight: 0,
    modelWeight: 0,
    modelSizes: [],
  })
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const { tags, imgs, productLocs, content } = snapForm

  const onSubmit = async () => {
    const sortedProductLocs = imgs
      .map((img) => productLocs.filter((loc) => loc.url === img))
      .flat()
      .map((img) => ({
        ...img,
        imgIndex: imgs.indexOf(img.url),
      }))

    const _productIds = sortedProductLocs.map((e) => e.product.id)
    const _productLocs = sortedProductLocs.map((e) => ({
      x: e.x,
      y: e.y,
      imgIndex: e.imgIndex,
      pid: e.product.id,
    }))

    const checkStyle = EN_STYLE_TAG.filter((x) => tags.includes(x))
    const checkCircumstance = EN_OCCUPATION_SPECIAL_TAG.filter((x) =>
      tags.includes(x)
    )
    const checkClimate = EN_CLIMATE_TAG.filter((x) => tags.includes(x))

    try {
      // error 잡기
      if (!snapForm?.imgs?.length) throw Error('이미지를 등록해주세요.')
      if (!_productIds?.length) throw Error('최소 1개의 상품을 태그해 주세요.')
      if (!_productLocs?.length) throw Error('최소 1개의 상품을 태그해 주세요.')
      if (!snapForm?.content) throw Error('내용을 입력해주세요.')
      if (!checkStyle?.length) throw Error('스타일을 선택해 주세요.')
      if (checkStyle.length + checkCircumstance.length > 4)
        throw Error('스타일과 상황은 최대 4개까지 선택가능합니다.')
      if (checkClimate.length > 1) throw Error('날씨는 하나만 선택해주세요.')
      if (isNaN(parseFloat(snapForm.modelHeight))) {
        throw Error('모델의 키를 올바르게 입력해주세요')
      }
      if (isNaN(parseFloat(snapForm.modelWeight))) {
        throw Error('모델의 키를 올바르게 입력해주세요')
      }

      const body = {
        ...snapForm,
        productIds: [...new Set(_productIds)],
        productLocs: _productLocs,
        gender: SNAP_GENDER_MAP[snapForm.gender],
        modelHeight: parseFloat(snapForm.modelHeight),
        modelWeight: parseFloat(snapForm.modelWeight),
        modelSizes: snapForm.modelSizes.filter((size) => size.split(':')[1]),
      }
      await addPost(body)

      navigation('/snap', { state: { success: true } })
    } catch (error) {
      setSnackbar(true)
      setSnackbarMessage(error.message)
    }
  }

  useEffect(() => {
    const { sellProductInfo } = getSellerFromLocalStorage()
    const { style, circumstance } = sellProductInfo
    const changedCircumstance = circumstance.map((e) => OCCUPATION_SPECIAL[e])
    const changedStyle = style.map((e) => STYLE[e])

    setSnapForm({
      ...snapForm,
      tags: [...changedCircumstance, ...changedStyle],
    })
  }, [])

  return (
    <DefaultLayout isLoggedIn>
      <Snackbar
        open={snackbar}
        setOpen={setSnackbar}
        Icon={<CheckCircleOutline style={{ fontSize: 40, color: 'red' }} />}
        message={snackbarMessage}
      />
      <UntagProductList />
      <SnapForm
        imgs={imgs}
        productLocs={productLocs}
        tags={tags}
        setCurrentImageIndex={setCurrentImageIndex}
        setModalProductLocs={setModalProductLocs}
        setModal={setModal}
        setSnapForm={setSnapForm}
        snapForm={snapForm}
        onSubmit={onSubmit}
        content={content}
        buttonName={'등록하기'}
      />
      <SnapModal
        modal={modal}
        imgs={imgs}
        currentImageIndex={currentImageIndex}
        modalProductLocs={modalProductLocs}
        setModal={setModal}
        productLocs={productLocs}
        setSnapForm={setSnapForm}
        snapForm={snapForm}
        setModalProductLocs={setModalProductLocs}
        content={content}
      />
    </DefaultLayout>
  )
}

export default AddSnapPage
