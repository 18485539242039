import { Divider, Stack, Typography } from '@mui/material'

const Title = ({ title }) => {
  return <Typography sx={{ fontSize: 18, fontWeight: 700 }}>{title}</Typography>
}

const LinkTitle = ({ title, onClick }) => {
  return (
    <Typography
      sx={{
        fontSize: 18,
        fontWeight: 700,
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {title}
    </Typography>
  )
}

const withDate = (Component) => {
  return ({ date, ...rest }) => {
    return (
      <>
        <Component {...rest} />
        <Typography sx={{ color: '#707070' }}>
          최근 정산일 : {date.toString()}
        </Typography>
      </>
    )
  }
}

const TitleWithDate = withDate(Title)
const LinkTitleWithDate = withDate(LinkTitle)

const DashBoardSectionData = ({ data, label }) => {
  let nonZero = data !== 0 && data !== '0' && data !== '0 원'

  return (
    <Stack direction="column" alignItems="center" gap="10px">
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: 30,
          color: nonZero ? '#000000' : '#707070',
        }}
      >
        {data}
      </Typography>
      <Typography sx={{ fontWeight: 700, color: '#707070' }}>
        {label}
      </Typography>
    </Stack>
  )
}

const DashBoardSection = ({ header, body }) => {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '220px',
        bgcolor: '#ffffff',
      }}
      direction="column"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingX: '20px', paddingY: '15px' }}
      >
        {header}
      </Stack>
      <Divider />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        flex={1}
      >
        {body}
      </Stack>
    </Stack>
  )
}

DashBoardSection.Title = Title
DashBoardSection.LinkTitle = LinkTitle
DashBoardSection.TitleWithDate = TitleWithDate
DashBoardSection.LinkTitleWithDate = LinkTitleWithDate
DashBoardSection.Data = DashBoardSectionData

export default DashBoardSection
