export const getSellerFromLocalStorage = () => {
  let seller = localStorage.getItem('seller')
  seller = JSON.parse(seller)
  if (!seller) {
    return {}
  }
  return seller
}

export const setSellerToLocalStorage = async (
  sellerName,
  approveStatus,
  profileImg,
  sellerId,
  managerName,
  managerNumber,
  sellProductInfo,
  bankInfo,
  businessInfo,
  sellerEmail
) => {
  const seller = {
    name: sellerName,
    approveStatus,
    profileImg,
    sellerId,
    managerName,
    managerNumber,
    sellProductInfo: sellProductInfo
      ? sellProductInfo
      : { gender: '', category: '', circumstance: [], style: [] },
    bankInfo,
    businessInfo,
    sellerEmail,
  }
  localStorage.setItem('seller', JSON.stringify(seller))
}

export const clearLocalStorage = async () => {
  localStorage.clear()
}

export const isAvailableSeller = () => {
  const user = getSellerFromLocalStorage()

  if (!user?.profileImg) return false

  if (!user?.sellProductInfo?.gender) return false
  // if (!user?.sellProductInfo?.category) return false
  if (!user?.sellProductInfo?.style?.length === 0) return false

  if (!user?.managerName) return false
  if (!user?.managerNumber) return false

  if (!user?.bankInfo?.bankName) return false
  if (!user?.bankInfo?.accountHolder) return false
  if (!user?.bankInfo?.accountNumber) return false
  if (!user?.bankInfo?.email) return false

  if (!user?.businessInfo?.type) return false
  if (!user?.businessInfo?.representativeName) return false
  // I think businessInfo?.phoneNumber
  // if(!user?.businessInfo?.type) return false
  if (!user?.businessInfo?.businessName) return false
  if (!user?.businessInfo?.businessNumber) return false

  return true
}

export const isEmptyObj = (obj) => {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true
  }

  return false
}
