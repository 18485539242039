import { useEffect, useState } from 'react'
import { getPathFromCheckSeller } from '../utils'

import { Box } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { getSellerFromLocalStorage } from '../api/storage'
import NavigationTap from '../components/Navigation'
import SubHeader from '../components/SubHeader'
import Header from './Header'

const DefaultLayout = ({
  children,
  isLoggedIn,
  onClickSubHeaderBtn,
  isNotScroll,
}) => {
  const [seller, setSeller] = useState({
    name: '',
    approveStatus: 'WAITING', // TODO
    profileImg: getSellerFromLocalStorage().profileImg ?? null,
    sellerId: '',
  })

  useEffect(() => {
    const pathname = window.location.pathname

    if (pathname.includes('register') || pathname.includes('faq')) {
      return
    }

    const { shouldDirect, path, seller } = getPathFromCheckSeller()
    setSeller(seller)

    if (shouldDirect && path !== pathname) {
      window.location.href = path
      return
    }

    if (!shouldDirect && pathname === '/') {
      window.location.href = '/dashboard'
      return
    }
  }, [])

  const NOT_SCROLL = {
    overflow: 'hidden',
    position: 'fixed',
    width: '100%',
  }

  let LAYOUT_STYLE = {
    marginTop: isLoggedIn ? '100px' : '50px',
    marginLeft: isLoggedIn ? '200px' : 0,
    paddingTop: '20px',
    minHeight: '95vh',
    backgroundColor: isLoggedIn ? '#E4E4E4' : 'white',
    height: '100%',
    minWidth: '1540px',
  }

  if (isNotScroll) {
    LAYOUT_STYLE = { ...LAYOUT_STYLE, ...NOT_SCROLL }
  }

  return (
    <>
      <Header name={seller?.name} />
      {isLoggedIn && <SubHeader onClickSubHeaderBtn={onClickSubHeaderBtn} />}
      <CssBaseline>
        <Box sx={LAYOUT_STYLE}>{children}</Box>

        {isLoggedIn && (
          <Box
            sx={{
              position: 'fixed',
              zIndex: 0,
              top: 0,
              left: 0,
              width: '200px',
              overflowX: 'scroll',
              height: '100vh',
            }}
          >
            <NavigationTap seller={seller} />
          </Box>
        )}
      </CssBaseline>
    </>
  )
}

export default DefaultLayout
