import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DefaultLayout from '../../global/DefaultLayout'
import { useEffect, useState } from 'react'
import { getModels, patchModel, removeModels } from '../../api/model'
import ModelTable from '../../components/model/model-table'
import DeleteModal from '../../components/DeleteModal'
import { CheckSnackbar } from '../../components/Snackbar'
import { useLocation, useNavigate } from 'react-router-dom'

const StylistModelInfoPage = () => {
  const [models, setModels] = useState([])
  const [checked, setChecked] = useState([])
  const location = useLocation()
  const navigation = useNavigate()
  const isPop = location.search.includes('pop=true')

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getModels()
      if (success) {
        setModels(data.items)
      }
    })()

    if (isPop) {
      navigation(location.pathname)
    }
  }, [])

  const onClickRepModel = async () => {
    if (checked.length !== 1) {
      alert('대표 모델은 한 명만 설정 가능합니다.')
      return
    }
    await patchModel({ modelId: checked[0].id, isRepresentative: true })
    alert('대표 설정이 완료되었습니다.')
    window.location.reload()
  }

  const [open, setOpen] = useState(false)
  const [onCheckBar, setOnCheckBar] = useState(isPop)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const clickDelBtn = () => {
    if (checked.length === 0) {
      return
    }
    handleOpen()
  }

  const clickModalBtn = async () => {
    const { success } = await removeModels(checked.map((o) => o.id))
    if (success) {
      handleClose()
      setOnCheckBar(true)
      const filtered = models.filter(
        (model) => !checked.map((o) => o.id).includes(model.id)
      )
      setModels([...filtered])
      setChecked([])
    } else {
      alert('에러가 발생했습니다.')
    }
  }

  return (
    <DefaultLayout isLoggedIn>
      <DeleteModal
        open={open}
        handleClose={handleClose}
        clickModalBtn={clickModalBtn}
      />
      <CheckSnackbar open={onCheckBar} setOpen={setOnCheckBar} />

      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Box>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            선택한 모델 : {checked.map((o) => o.name).toString()}
          </p>
        </Box>
        <Divider sx={{ height: 16 }} />
        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FFF0F0',
              color: '#FF0000',
              fontWeight: 'bold',
              boxShadow: 'none',
              marginY: 1.4,
            }}
            onClick={clickDelBtn}
          >
            선택 모델 삭제
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#E4E4E4',
              color: 'black',
              fontWeight: 'bold',
              boxShadow: 'none',
              marginX: 2,
            }}
            onClick={onClickRepModel}
          >
            대표 모델 설정
          </Button>
          <Box sx={{ width: '972px' }}>
            <Divider sx={{ height: 16 }} />

            <ModelTable
              models={models}
              checked={checked}
              setChecked={setChecked}
            />
          </Box>
        </Box>
      </Box>
    </DefaultLayout>
  )
}

export default StylistModelInfoPage
