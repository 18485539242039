import {
  CloseOutlined,
  ContentCopy,
  FileUploadOutlined,
} from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  Modal,
  Slide,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'

import {
  BULK_IMAGE_LENGTH,
  BULK_IMAGE_TOTAL_SIZE_GB,
  MAX_IMAGE_SIZE,
  MAX_IMAGE_SIZE_MB,
} from '../../fixture/file'
// import { resizeImage } from '../../fixture/resize'

import { getImagesUrl } from '../../api/image'
import { getSellerFromLocalStorage } from '../../api/storage'

export const BulkProductLoadingModal = ({ open, style }) => {
  return (
    <Modal open={open}>
      <Box sx={style}>
        <CircularProgress sx={{ color: '#ffffff' }} size={200} />
      </Box>
    </Modal>
  )
}

const FAILURE_MESSAGE = `등록 실패한 상품 정보를 수정 후 다시 업로드 해 주세요.\n
실패 사유는 아래 확인 버튼을 누르신 후 표를 확인 해 주세요.`
const SUCCESS_MESSAGE = `아래 버튼을 눌러 일괄 등록을 완료 해 주세요.\n
일괄등록된 상품은 상품 조회 페이지에서 확인 가능합니다.`

export const BulkProductFinishModal = ({
  open,
  style,
  onClose,
  products,
  submit,
}) => {
  const success = products.filter((p) => p.status === '성공')
  const failure = products.filter((p) => p.status === '실패')
  const isAllSuccess = failure.length === 0
  const message = isAllSuccess ? SUCCESS_MESSAGE : FAILURE_MESSAGE
  const buttonText = isAllSuccess ? '일괄등록 완료하기' : '확인'

  return (
    <Modal open={open}>
      <Stack
        sx={style}
        direction="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography sx={{ fontWeight: 700, fontSize: 30 }}>
          일괄등록 결과
        </Typography>
        <Stack direction="row" gap={'10px'}>
          <Typography sx={{ fontWeight: 700, fontSize: 30 }}>등록:</Typography>
          <Typography sx={{ fontSize: 30, fontWeight: 700 }}>/</Typography>
          <Typography sx={{ fontSize: 30, fontWeight: 700, color: '#00a53d' }}>
            성공: {success.length}
          </Typography>
          <Typography sx={{ fontSize: 30, fontWeight: 700 }}>/</Typography>
          <Typography sx={{ fontSize: 30, fontWeight: 700, color: '#FF0200' }}>
            실패: {failure.length}
          </Typography>
        </Stack>
        <Typography sx={{ color: '#707070', textAlign: 'center' }}>
          {message}
        </Typography>
        <Button
          variant="contained"
          sx={{
            width: '140px',
            bgcolor: '#000000',
            color: '#ffffff',
            '&:hover': {
              bgcolor: '#000000',
              color: '#ffffff',
              borderColor: '#707070',
            },
            '&:active': {
              bgcolor: '#000000',
              color: '#ffffff',
              borderColor: '#707070',
            },
            fontWeight: 700,
          }}
          onClick={isAllSuccess ? submit : onClose}
        >
          {buttonText}
        </Button>
      </Stack>
    </Modal>
  )
}

export const BulkImageUploadModal = ({ open, onClose, style }) => {
  const seller = getSellerFromLocalStorage()

  const IMAGE_SLPIT_SIZE = 10

  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState([])
  const [max, setMax] = useState(0)
  const [count, setCount] = useState(0)
  const [snackBar, setSnackBar] = useState(false)

  const uploadImage = async (e) => {
    setLoading(true)
    if (e.target.files.length > BULK_IMAGE_LENGTH) {
      setLoading(false)
      return alert('이미지가 200개가 넘습니다.')
    }

    if (!e.target.files) {
      setLoading(false)
      return alert('잘못된 이미지 형식입니다.')
    }

    for (let i = 0; i < e.target.files.length; i += 1) {
      if (e.target.files[i].size > MAX_IMAGE_SIZE) {
        setLoading(false)
        return alert(`${e.target.files[i].name}의 사이즈가 20MB가 넘습니다.`)
      }
    }

    // const files = await resizeImage(e)
    const files = e.target.files

    const length = files.length
    setMax(length)
    const bundle = Math.ceil(length / 10)
    for (let i = 0; i < bundle; i += 1) {
      const formData = new FormData()
      formData.append('type', 'product')
      let each = length - i * IMAGE_SLPIT_SIZE
      each = each > IMAGE_SLPIT_SIZE ? IMAGE_SLPIT_SIZE : each
      for (let j = 0; j < each; j += 1) {
        const current = i * 10 + j
        const newFileName = `${seller.name ? seller.name : 'product'}.${files[
          current
        ].name
          .split('.')
          .pop()}`
        formData.append(
          'files',
          new Blob([files[current]], {
            type: files[current].type,
          }),
          newFileName,
        )
      }
      const { success, data } = await getImagesUrl(formData)
      if (!success) break
      setImage((prev) => [...prev, ...data])
      setCount((prev) => prev + each)
    }
    setLoading(false)
    setMax(0)
    setCount(0)
    e.target.files = null
    e.target.value = null
  }

  const copyImage = (id) => {
    const text = document.getElementById(id).textContent
    const textarea = document.createElement('textarea')
    textarea.value = text

    // Select the text field
    textarea.select()
    textarea.setSelectionRange(0, 99999) // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(textarea.value)
    setSnackBar(true)
  }

  useEffect(() => {
    if (!open) return
    setImage([])
  }, [open])

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackBar}
        onClose={() => setSnackBar(false)}
        message="복사되었습니다."
        autoHideDuration={1000}
        TransitionComponent={Slide}
      />
      <Modal open={open}>
        <Box sx={style}>
          <Stack direction="column">
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: '100%' }}
            >
              <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
                상품 일괄등록
              </Typography>
              <CloseOutlined onClick={onClose} sx={{ cursor: 'pointer' }} />
            </Stack>
            <Box sx={{ height: '10px' }} />
            <Divider width="100%" />
            <Box sx={{ height: '10px' }} />
            <Typography sx={{ color: '#707070' }}>
              이미지는 한번에 최대 {BULK_IMAGE_LENGTH}개,
              {BULK_IMAGE_TOTAL_SIZE_GB}GB(장당 {MAX_IMAGE_SIZE_MB}MB)까지
              업로드 할 수 있습니다. <br /> <br />
              * 대표 이미지 : 업로드한 상품이미지 목록의 첫번째 이미지 <br />*
              권장크기 : 640 x 640 * 제한 용량 : {BULK_IMAGE_TOTAL_SIZE_GB}
              GB(장당 {MAX_IMAGE_SIZE_MB}MB 제한)
              <br />* 허용확장자 : jpg, jpeg, png
            </Typography>
            <Typography sx={{ color: '#707070', fontWeight: 700 }}>
              * 이미지 추가 후 아래 업로드창에 보여지는 파일명(확장자 포함)을
              엑셀 파일에 기재해주시기 바랍니다.
            </Typography>
            <br />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="outlined"
                startIcon={<FileUploadOutlined />}
                component="label"
                sx={menuButtonStyle}
              >
                이미지 업로드
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={uploadImage}
                  multiple
                />
              </Button>
            </Stack>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">이미지</TableCell>
                    <TableCell align="center">파일명</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <LinearProgress
                          variant="determinate"
                          value={(count / max) * 100}
                        />
                        <Typography>
                          {count}/{max}장
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {image.map((url) => {
                    return (
                      <TableRow key={url}>
                        <TableCell align="center">
                          <img src={url} alt="" width={100} height={100} />
                        </TableCell>
                        <TableCell
                          align="center"
                          id={`server_image_${url}`}
                          onClick={() => copyImage(`server_image_${url}`)}
                          sx={{ cursor: 'copy' }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            gap="10px"
                          >
                            {url?.split('/')[4]}
                            <ContentCopy sx={{ color: '#707070' }} />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}

const menuButtonStyle = {
  borderColor: '#707070',
  color: '#707070',
  '&:hover': {
    borderColor: '#707070',
    color: '#707070',
    boxShadow: 'none',
  },
  '&:active': {
    borderColor: '#707070',
    color: '#707070',
    boxShadow: 'none',
  },
}
