import { FileDownloadOutlined, FileUploadOutlined } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import {
  BulkImageUploadModal,
  BulkProductFinishModal,
  BulkProductLoadingModal,
} from '../../components/product/modal'

import { getCategory } from '../../api/category'
import { uploadExcel } from '../../api/product'
import BulkProductTable from '../../components/product/BulkTable'
import { EXCEL } from '../../fixture/excel'
import { PRODUCT } from '../../fixture/product'
import DefaultLayout from '../../global/DefaultLayout'

const BulkProductPage = () => {
  const apiCategoryRef = useRef() // api category no rendering

  const [{ loading, finish, image, submit }, setModal] = useState({
    loading: false,
    finish: false,
    image: false,
    submit: false,
  })
  const setLoading = (loading) => setModal((prev) => ({ ...prev, loading }))
  const setFinish = (finish) => setModal((prev) => ({ ...prev, finish }))
  const setImage = (image) => setModal((prev) => ({ ...prev, image }))
  const setSubmit = (submit) => setModal((prev) => ({ ...prev, submit }))

  const [products, setProducts] = useState([])

  const uploadExcelHandler = (e) => {
    setSubmit(false)
    if (!e.target.files?.item(0)) return

    const file = e.target.files.item(0)
    e.target.value = null
    if (!EXCEL.getExtensionOfFilename(file)) {
      alert('.xlsx 확장자로 올려주세요.')
      return
    }
    setLoading(true)

    let categories = apiCategoryRef.current
    categories = categories.filter((c) => c.parent === null)

    EXCEL.readExcel(file, (workbook) => {
      const excelJson = EXCEL.convertWorkbookToJson(workbook)
      const PRODUCT_START_ROW_NUMBER = 4
      const productsByJson = excelJson.map((json, index) => {
        return {
          ...new PRODUCT().getProductByExcelJson(json, categories),
          cellNumber: index + PRODUCT_START_ROW_NUMBER,
        }
      })
      productsByJson.sort((a, b) => {
        a = a.status === '성공' ? 1 : 0
        b = b.status === '성공' ? 1 : 0
        return a - b
      })
      setProducts(productsByJson)
    })

    setLoading(false)
    setFinish(true)
  }

  const uploadBulkProduct = async () => {
    setLoading(true)
    try {
      await uploadExcel(products)
      setSubmit(true)
    } catch (error) {
      alert('엑셀 업로드에 실패했습니다.')
      setProducts((prev) => prev.map((p) => ({ ...p, status: '실패' })))
      setSubmit(false)
    } finally {
      setLoading(false)
      setFinish(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      const { data: categories } = await getCategory()
      apiCategoryRef.current = categories
    })()
  }, [])

  return (
    <DefaultLayout isLoggedIn>
      {/* Upload Loading Modal */}
      <BulkProductLoadingModal open={loading} style={modalStyle} />
      {/* Upload Finish Modal */}
      <BulkProductFinishModal
        open={finish}
        style={{
          ...modalStyle,
          bgcolor: '#ffffff',
          width: 700,
          height: 300,
          padding: '40px',
          borderRadius: '20px',
          overflow: 'auto',
        }}
        onClose={() => setFinish(false)}
        products={products}
        submit={uploadBulkProduct}
      />
      {/* Upload Image File Modal */}
      <BulkImageUploadModal
        open={image}
        onClose={() => setImage(false)}
        style={{
          ...modalStyle,
          bgcolor: '#ffffff',
          width: 930,
          maxHeight: 930,
          height: '90vh',
          padding: '20px',
          borderRadius: '20px',
          overflow: 'auto',
        }}
      />
      <Box sx={boxStyle}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" gap="10px" alignItems="end">
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 24,
                color: submit ? '#000000' : '#707070',
              }}
            >
              총 {products.length}건
            </Typography>
            {submit && (
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: 20,
                }}
              >
                등록이 완료되었습니다.
              </Typography>
            )}
          </Stack>
          <Stack direction="row" spacing={'10px'}>
            <Button
              variant="outlined"
              startIcon={<FileDownloadOutlined />}
              sx={menuButtonStyle}
              onClick={EXCEL.dowLoad}
            >
              양식 다운로드
            </Button>
            <Button
              variant="outlined"
              startIcon={<FileUploadOutlined />}
              sx={menuButtonStyle}
              onClick={() => setImage(true)}
            >
              이미지 업로드
            </Button>
            <Button
              variant="outlined"
              startIcon={<FileUploadOutlined />}
              sx={menuButtonStyle}
              component="label"
            >
              엑셀 업로드
              <input
                type="file"
                hidden
                accept=".xlsx"
                onChange={uploadExcelHandler}
                multiple
              />
            </Button>
          </Stack>
        </Stack>
        <Box sx={{ height: '10px' }} />
        <BulkProductTable products={products} />
        <Box sx={{ height: '10px' }} />
      </Box>
    </DefaultLayout>
  )
}

export default BulkProductPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

const menuButtonStyle = {
  borderColor: '#707070',
  color: '#707070',
  '&:hover': {
    borderColor: '#707070',
    color: '#707070',
    boxShadow: 'none',
  },
  '&:active': {
    borderColor: '#707070',
    color: '#707070',
    boxShadow: 'none',
  },
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}
