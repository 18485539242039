import { CircularProgress, Stack, TextField } from '@mui/material'
import React from 'react'
import useSearchProduct from '../../hooks/product/useSearchProduct'
import ProductOption from './ProductOption'

const SearchProductInput = ({ left, top, choiceProduct }) => {
  const end = React.useRef()
  const { keyword, handler, products, hasNext } = useSearchProduct()

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0]

        if (firstEntry.isIntersecting) {
          handler.fetchData()
        }
      },
      { threshold: 0.5 }
    )
    if (end.current) {
      observer.observe(end.current)
    }

    return () => {
      if (end.current) {
        observer.unobserve(end.current)
      }
    }
  }, [products])

  return (
    <Stack
      width={300}
      height={500}
      overflow="hidden"
      zIndex={51}
      top={top}
      left={left}
      position="absolute"
      bgcolor="#ffffff"
    >
      <TextField
        variant="outlined"
        placeholder="태그할 상품을 입력 해 주세요."
        value={keyword}
        onChange={handler.keyword}
        width={300}
        height={40}
      />
      <Stack overflow="scroll" direction="column">
        {products.map((product, index) => {
          return (
            <div key={index} onClick={choiceProduct(product)}>
              <ProductOption product={product} />
            </div>
          )
        })}
        {hasNext && (
          <Stack
            direction="row"
            justifyContent="center"
            ref={end}
            paddingY={2}
            bgcolor="#ffffff"
          >
            <CircularProgress size={20} sx={{ color: '#707070' }} />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}

export default SearchProductInput
