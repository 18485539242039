import { Link, useLocation, useNavigate } from 'react-router-dom'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { useState } from 'react'
import styled from 'styled-components'
import { isAvailableSeller } from '../api/storage'
import productIcon from '../static/product_icon.svg'
import sellIcon from '../static/sell_icon.png'
import snapIcon from '../static/snap_icon.svg'
import stylistIcon from '../static/stylist_icon.svg'
import transactionIcon from '../static/transaction_icon.svg'
import { getLayoutProfileImg } from '../utils'
import { UnAvailableUserSnackbar } from './Snackbar'

const NAV_MENU_STYLIST = [
  { name: '기본 정보', path: '/stylist/info', view: true },
  { name: '모델 정보', path: '/stylist/model', view: false },
]
const NAV_MENU_SNAPS = [
  { name: '스냅 게시물 목록', path: '/snap', view: true },
  { name: '스냅 게시물 등록', path: '/snap/add', view: true },
  { name: '스냅 게시물 수정', path: '/snap/add/', view: false },
]
const NAV_MENU_PRODUCTS = [
  { name: '상품 목록', path: '/product', view: true },
  { name: '상품 등록', path: '/product/add', view: true },
  { name: '상품 일괄등록', path: '/product/bulk', view: true },
  { name: '상품 수정', path: '/product/add/', view: false },
]
const NAV_MENU_SELLS = [
  { name: '판매 내역 조회', path: '/sell', view: true },
  { name: '재고 관리', path: '/sell/stock', view: true },
]
const NAV_MENU_TRANSACTIONS = [
  { name: '정산 내역', path: '/transaction', view: true },
]

export const MENU_LIST = [
  {
    name: '스냅 게시물 관리',
    key: 'snap',
    icon: snapIcon,
    list: NAV_MENU_SNAPS,
  },
  {
    name: '상품 관리',
    key: 'product',
    icon: productIcon,
    list: NAV_MENU_PRODUCTS,
  },
  {
    name: '판매 관리',
    key: 'sell',
    icon: sellIcon,
    list: NAV_MENU_SELLS,
  },
  {
    name: '정산 관리',
    key: 'transaction',
    icon: transactionIcon,
    list: NAV_MENU_TRANSACTIONS,
  },
  {
    name: '파트너 정보',
    key: 'stylist',
    icon: stylistIcon,
    list: NAV_MENU_STYLIST,
  },
]

const NavigationTap = ({ seller }) => {
  const [snackbar, setSnackbar] = useState(false)
  const navigation = useNavigate()
  const location = useLocation()

  const clickNavTap = (path) => {
    const isAvailable = isAvailableSeller()
    if (!isAvailable) {
      setSnackbar(true)
      return
    }

    window.scrollTo(0, 0)

    navigation(path)
  }

  const isFocus = (path) => location.pathname === path

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '200px',
        backgroundColor: '#707070',
      }}
    >
      <UnAvailableUserSnackbar open={snackbar} setOpen={setSnackbar} />
      <Box
        paddingTop={10}
        paddingBottom={4}
        flexDirection={'column'}
        sx={{
          width: '200px',
          backgroundColor: '#5D5D5D',
        }}
      >
        <Box textAlign={'center'} marginBottom={1}>
          <img
            style={{
              borderRadius: '50px',
              objectFit: 'cover',
            }}
            width={'100px'}
            height={'100px'}
            alt="profile img"
            src={getLayoutProfileImg(seller.profileImg)}
          />
        </Box>
        <Box textAlign={'center'}>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'white',
              paddingTop: '10px',
            }}
          >
            {seller.name}
          </p>
        </Box>
      </Box>

      {MENU_LIST.map(({ name, key, icon, list }, idx) => (
        <Box key={idx} sx={{ paddingY: 2 }}>
          <Accordion
            defaultExpanded={location.pathname.includes(key)}
            elevation={0}
            sx={{
              alignItems: 'center',
              backgroundColor: '#707070',
            }}
          >
            <AccordionSummary
              expandIcon={
                ({ order: -1 }, (<ExpandMoreIcon sx={{ color: '#E4E4E4' }} />))
              }
              aria-controls="panel1a-content"
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img src={icon} alt="snap_icon" style={{ width: 23 }} />
                <p
                  style={{
                    display: 'flex',
                    color: '#E4E4E4',
                    fontSize: '14px',
                    marginLeft: 10,
                  }}
                >
                  {name}
                </p>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingY: '0',
                display: 'block',
                backgroundColor: '#5D5D5D',
              }}
            >
              <Box>
                {list.map(
                  ({ name, path, view }) =>
                    view && (
                      <NavItem
                        key={name}
                        onClick={() => {
                          clickNavTap(path)
                        }}
                        focus={isFocus(path)}
                      >
                        {name}
                      </NavItem>
                    )
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'end',
        }}
      >
        <Box
          sx={{
            display: 'block',
            width: '200px',
            fontSize: '14px',
            marginLeft: 2,
          }}
        >
          <Link
            to="/notice"
            style={{
              display: 'block',
              paddingTop: '40px',
              paddingBottom: '20px',
              color: '#E4E4E4',
              textDecoration: 'underline',
            }}
          >
            공지사항
          </Link>
          <Link
            to="/docs/term"
            style={{
              display: 'block',
              paddingTop: '40px',
              paddingBottom: '20px',
              color: '#E4E4E4',
              textDecoration: 'underline',
            }}
          >
            이용약관
          </Link>
          <Link
            to="/docs/privacy"
            style={{
              display: 'block',
              paddingTop: '40px',
              paddingBottom: '100px',
              color: '#E4E4E4',
              textDecoration: 'underline',
            }}
          >
            개인정보 처리방침
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default NavigationTap

const NavItem = styled.p`
  padding-bottom: 30px;
  padding-top: 30px;
  :hover {
    cursor: pointer;
  }
  font-size: 14px;
  font-weight: ${(props) => (props.focus ? 'bold' : 'normal')};
  color: ${(props) => (props.focus ? 'white' : '#E4E4E4')};
`
