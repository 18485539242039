import { Divider, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { getProducts, updateStock } from '../../api/product'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { CheckSnackbar } from '../../components/Snackbar'
import Navigation from '../../components/post/navigation'
import { InputTextField } from '../../element/InputTextField'
import DefaultLayout from '../../global/DefaultLayout'
import { inputLabelPropsStyle } from '../../utils'
import { CustomSmallDropDownField, SELECT_SIZE } from '../product/productList'
import StockProductTable from '../../components/sell/stock-product-table'

const ProductStockListPage = () => {
  const [products, setProducts] = useState([])

  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [count, setCount] = useState(0)

  const fetch = async () => {
    const { data, count } = await getProducts(
      page,
      '',
      keyword,
      '',
      '',
      '',
      '',
      size
    )
    setProducts(data)
    setCount(count)
  }

  useEffect(() => {
    fetch()
  }, [page, size])

  const clickSearchBtn = async () => {
    if (page === 1) {
      fetch()
    } else {
      setPage(1)
    }
  }

  const [onCheckBar, setOnCheckBar] = useState(false)

  const onClickSubHeaderBtn = async () => {
    const { success } = await updateStock(
      products.map((p) => ({ productId: p.id, option: p.option }))
    )
    if (success) {
      setOnCheckBar(true)
    }
  }

  return (
    <DefaultLayout isLoggedIn onClickSubHeaderBtn={onClickSubHeaderBtn}>
      <CheckSnackbar
        open={onCheckBar}
        setOpen={setOnCheckBar}
        message="재고 수량 변경이 완료되었습니다."
      />

      <Box sx={boxStyle}>
        <p
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          옵션별 재고 조회
        </p>
        <Divider sx={{ height: 16 }} />

        <Grid
          container
          spacing={3}
          sx={{ marginTop: 0, display: 'flex', alignItems: 'center' }}
        >
          <Grid item xs={1}>
            <p
              style={{
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              상품명 입력 :
            </p>
          </Grid>
          <Grid item xs={11}>
            <InputTextField
              width="450px"
              label="상품 검색어를 입력해주세요."
              InputLabelProps={inputLabelPropsStyle}
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  clickSearchBtn()
                }
              }}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 2,
            justifyContent: 'end',
          }}
        >
          <CustomSmallDropDownField
            fullWidth
            disableClearable
            size="small"
            value=""
            onChange={(event, value) => {
              const size = Number(
                SELECT_SIZE.find((o) => o.name === value).value
              )
              setSize(size)
              setPage(1)
            }}
            ListboxProps={{
              style: {
                fontSize: '14px',
              },
            }}
            id="selling_option"
            options={SELECT_SIZE.map((o) => o.name)}
            filterOptions={(options, { inputValue }) => {
              return options.filter(
                (option) => option !== '' && option.includes(inputValue)
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={inputLabelPropsStyle}
                label="20개씩"
              />
            )}
          />
        </Box>
        <Divider sx={{ height: 16 }} />

        <StockProductTable products={products} setProducts={setProducts} />
        <Navigation page={page} setPage={setPage} count={count} />
      </Box>
    </DefaultLayout>
  )
}

export default ProductStockListPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}
