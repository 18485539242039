import { instance } from './instance'

export const getPosts = async (page, size) => {
  try {
    const { data } = await instance.get(
      `/api/post/page?page=${page}&size=${size}`,
      {
        token: true,
      }
    )
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const removePosts = async (postIds) => {
  try {
    let path = postIds.reduce((acc, id) => acc + `postIds=${id}&`, '')
    await instance.delete(`/api/post?${path}`, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const addPost = async (form) => {
  const body = { ...form }
  try {
    await instance.post(`/api/post`, body, { token: true })
  } catch (error) {
    throw error
  }
}

export const getPost = async (id) => {
  try {
    const { data } = await instance.get(`/api/post/detail/${id}`)
    return { success: true, data }
  } catch (error) {
    return { success: false }
  }
}

export const editPost = async (id, body) => {
  try {
    await instance.patch(
      `/api/post`,
      {
        postId: id,
        ...body,
      },
      { token: true }
    )
  } catch (error) {
    return { success: false }
  }
}

export const getOpponentTag = async () => {
  try {
    const { data } = await instance.get(`/api/post/opponent-tag`, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    return { success: false }
  }
}
