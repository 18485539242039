import { Container } from '@mui/material'
import DefaultLayout from '../global/DefaultLayout'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import RegisterHeader from '../components/register/Header'

const RegisterComplete = () => {
  const navigation = useNavigate()

  return (
    <DefaultLayout>
      <Container align="center" sx={{ marginTop: '50px' }}>
        <RegisterHeader step={3} />
        <Box
          sx={{
            width: 480,
            maxWidth: '100%',
            textAlign: 'left',
            paddingTop: 4,
          }}
        >
          <p
            style={{
              display: 'inline-block',
              fontSize: '20px',
              fontWeight: 'bolder',
            }}
          >
            바이스냅(BYSNAP) 파트너스 가입 신청이 완료되었습니다!
          </p>
          <br />
          <br />

          <p
            style={{
              display: 'inline-block',
              fontSize: '18px',
            }}
          >
            가입 승인은 3일 이내로 진행되며, 승인 완료시 기재해주신 휴대폰
            문자를 통해 안내 드릴 예정입니다.
            <br />
            다시 한번 바이스냅과 함께 해주신 점 감사 말씀 드립니다!
          </p>
          <Box sx={{ height: 100 }}></Box>

          <Button
            fullWidth
            variant="outlined"
            size="large"
            sx={{ color: 'black', borderColor: 'black' }}
            onClick={() => {
              navigation('/')
            }}
          >
            첫 페이지로 이동
          </Button>
        </Box>
      </Container>
    </DefaultLayout>
  )
}

export default RegisterComplete
