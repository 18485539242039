import { useEffect, useState } from 'react'
import { getOpponentTag } from '../../api/post'

const useOpponentTag = () => {
  const [apiOpponent, setApiOpponent] = useState([])

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await getOpponentTag()
        setApiOpponent(data.data)
      } catch (error) {}
    })()
  }, [])

  return { apiOpponent }
}

export default useOpponentTag
