import { Box, Button, Checkbox, Container, Typography } from '@mui/material'

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Image from '../element/Image'
import DefaultLayout from '../global/DefaultLayout'

const RegisterPolicy = () => {
  const navigation = useNavigate()
  const [policy, setPolicy] = useState([false, false])
  const [policy1, policy2] = policy
  const checkAll = policy.every((p) => p)

  const buttonHandler = () => {
    navigation('/register/biz')
  }

  return (
    <DefaultLayout>
      <Container align="center" sx={{ marginTop: '50px' }}>
        <Typography sx={{ fontWeight: 700, fontSize: 30 }}>
          BYSNAP Partners 회원가입
        </Typography>
        <Box sx={{ height: 40 }} />
        <Image src="/warning.png" width={200} height={200} />
        <Typography sx={{ fontWeight: 700, fontSize: 30 }}>
          반드시 확인 해 주세요!
        </Typography>
        <Typography sx={{ fontSize: 20 }}>
          바이스냅에서는 아래에 해당하는 브랜드/스토어만 입점 가능합니다.
        </Typography>
        <Box sx={{ height: 40 }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Checkbox
            checked={policy1}
            onClick={() => {
              setPolicy([!policy1, policy2])
            }}
          />
          <Box sx={{ width: 10 }} />
          <Typography sx={{ color: 'red' }}>
            상품 디자인 도용 브랜드/스토어에 해당하지 않으며, 입점 이후 발생 시
            법적 조치에 동의합니다.
          </Typography>
        </Box>
        <Box sx={{ height: 20 }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Checkbox
            checked={policy2}
            onClick={() => {
              setPolicy([policy1, !policy2])
            }}
          />
          <Box sx={{ width: 10 }} />
          <Typography sx={{ color: 'red' }}>
            섬유 혼용률을 비롯한 원산지, 제조국명, 제조일자, 취급상 주의사항 등
            표시사항이 사실과 일치하며, 거짓 기재 시 법적 조치에 동의합니다.
          </Typography>
        </Box>
        <Box sx={{ height: 40 }} />
        <Button
          onClick={buttonHandler}
          disabled={!checkAll}
          variant="contain"
          sx={{
            bgcolor: 'black',
            color: 'white',
            width: '400px',
            fontWeight: 700,
            borderRadius: '10px',
            ':hover': {
              bgcolor: '#000000',
            },
            ':disabled': {
              bgcolor: '#707070',
              color: 'white',
            },
          }}
        >
          다음
        </Button>
      </Container>
    </DefaultLayout>
  )
}

export default RegisterPolicy
