import styled from 'styled-components'

export const Table = styled('table')({
  width: '100%',
})

export const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

export const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})

export const PBox = styled('div')({
  width: '250px',
  boxSizing: 'border-box',
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})
