import { Box, Button, Divider, Grid, Modal, TextField } from '@mui/material'
import { useRef, useState } from 'react'
import { TAG_DOT_SIZE, TAG_IMAGE_SIZE } from './ProductLocs'

import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
import ProductLocs from '../../components/snap/ProductLocs'
import Image from '../../element/Image'
import ProductOption from './ProductOption'
import SearchProductInput from './SearchProductInput'

const MAX_TAG_IMAGE = 4

const SnapModal = ({
  modal,
  imgs,
  currentImageIndex,
  modalProductLocs,
  setModal,
  productLocs,
  setSnapForm,
  snapForm,
  setModalProductLocs,
}) => {
  const [isDrag, setIsDrag] = useState(false)
  const [productIndex, setProductIndex] = useState(-1)

  const modalImageRef = useRef(null)
  const deleteButtonRef = useRef([])

  const currentModalProductLocs = modalProductLocs.filter(
    (e) => e.url === imgs[currentImageIndex]
  )

  const closeModal = () => {
    removeNoProductLoc()
    setModal(false)
    setProductIndex(-1)
  }

  const addProductLocs = () => {
    const removed = removeNoProductLoc()
    const notIndexLoc = productLocs.filter(
      (e) => e.url !== imgs[currentImageIndex]
    )

    const _productLocs = [...notIndexLoc, ...removed]

    const category = _productLocs.map((loc) => loc.product.category)

    const current = [
      ...new Set(category.map((c) => c?.parent.name.toUpperCase() ?? c)),
    ]

    const origin = snapForm.modelSizes

    const merge = []

    for (const c of current) {
      const find = origin.findIndex((o) => o.startsWith(c))

      merge.push(origin?.[find] ?? `${c}:`)
    }

    setSnapForm({
      ...snapForm,
      productLocs: _productLocs,
      modelSizes: merge,
    })
  }

  const setModalProductLocXY = (e) => {
    setProductIndex(-1)
    removeNoProductLoc()
    const length = currentModalProductLocs.length

    if (length >= MAX_TAG_IMAGE) return

    const { x, y } = modalImageRef.current.getBoundingClientRect()
    const { clientX, clientY } = e
    const productLoc = {
      x: (clientX - x - TAG_DOT_SIZE / 2) / TAG_IMAGE_SIZE,
      y: (clientY - y - TAG_DOT_SIZE / 2) / TAG_IMAGE_SIZE,
      url: imgs[currentImageIndex],
      product: null,
    }

    setModalProductLocs((prev) => [...prev, productLoc])
  }

  const setModalProductLocItem = (product) => () => {
    if (!product) {
      return
    }
    let updated = currentModalProductLocs
    const isDuplicate = updated.filter(
      (e) => e?.product?.id === product.id
    ).length
    if (isDuplicate) return removeNoProductLoc()

    const last = currentModalProductLocs[currentModalProductLocs.length - 1]
    last.product = product
    updated.pop()
    updated.push(last)
    setModalProductLocs(updated)
  }

  const removeNoProductLoc = () => {
    const removed = modalProductLocs.filter((e) => e.product !== null)
    setModalProductLocs(removed)

    return removed
  }

  const removeModalProductLocByIndex = (index) => {
    const removed = modalProductLocs.filter((_, idx) => idx !== index)

    setModalProductLocs(removed)
  }

  const handleDragStart = () => {
    setIsDrag(true)
    setProductIndex(-1)
  }

  const handleDragEnd = (e, index) => {
    if (!isDrag) {
      setProductIndex((prev) => (prev === index ? -1 : index))
    }

    const { x, y } = modalImageRef.current.getBoundingClientRect()
    const { clientX, clientY } = e
    let locX = (clientX - x - TAG_DOT_SIZE / 2) / TAG_IMAGE_SIZE
    let locY = (clientY - y - TAG_DOT_SIZE / 2) / TAG_IMAGE_SIZE

    if (locX < 0) {
      locX = 0 / TAG_IMAGE_SIZE
    }
    if (locX > 1) {
      locX = (500 - TAG_DOT_SIZE) / TAG_IMAGE_SIZE
    }
    if (locY < 0) {
      locY = 0 / TAG_IMAGE_SIZE
    }
    if (locY > 1) {
      locY = (500 - TAG_DOT_SIZE) / TAG_IMAGE_SIZE
    }

    const productLoc = {
      ...currentModalProductLocs[index],
      x: locX,
      y: locY,
    }

    const updated = modalProductLocs.map((_, idx) => {
      if (idx !== index) return _
      else return productLoc
    })

    setModalProductLocs(updated)

    setIsDrag(false)
  }

  const modalEvent = (e) => {
    if (modalImageRef.current.contains(e.target)) {
      return
    }
    for (let i = 0; i < deleteButtonRef.current?.length; i += 1) {
      let ref = deleteButtonRef.current[i]
      if (ref?.contains(e.target)) {
        return
      }
    }
    removeNoProductLoc()
  }

  return (
    <Modal
      open={modal}
      onClose={() => {
        closeModal()
      }}
      onClick={modalEvent}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={modalStyle} sx={{ backgroundColor: '#ffffff' }}>
        <Box padding={2}>
          <p style={{ fontSize: 18, fontWeight: 700 }}>스냅 이미지 상품 추가</p>
        </Box>
        <Divider sx={{ color: '#e4e4e4' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box paddingX={5} paddingY={2}>
            <Box
              position="relative"
              ref={modalImageRef}
              onDragOver={(e) => {
                e.preventDefault()
              }}
              onDrop={(e) => {
                e.preventDefault()
              }}
            >
              <Image
                src={imgs[currentImageIndex]}
                alt=""
                width={500}
                height={500}
                border="1px solid #707070"
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  width: TAG_IMAGE_SIZE,
                  height: TAG_IMAGE_SIZE,
                  zIndex: 3,
                  position: 'absolute',
                }}
                onClick={(e) => setModalProductLocXY(e)}
              />
              {currentModalProductLocs.length === 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    width: TAG_IMAGE_SIZE,
                    height: TAG_IMAGE_SIZE,
                    backgroundColor: 'rgba(112, 112, 112, 0.2)',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: 'white',
                      padding: 1,
                      borderRadius: 1,
                    }}
                  >
                    <p style={{ fontSize: 14, fontWeight: 700 }}>
                      마우스 클릭 후 태그 할 상품을 등록 해 주세요.
                    </p>
                  </Box>
                </Box>
              ) : (
                currentModalProductLocs.map((loc, index) => (
                  <div key={index}>
                    <div
                      id={`tag${index}`}
                      draggable
                      onDragStart={handleDragStart}
                      onDragEnd={(e) => handleDragEnd(e, index)}
                      onClick={() => {
                        setProductIndex((prev) => (prev === index ? -1 : index))
                      }}
                    >
                      <ProductLocs loc={loc} />
                    </div>
                    {index === productIndex &&
                      currentModalProductLocs[productIndex].product !==
                        null && (
                        <Box
                          sx={{
                            width: 300,
                            position: 'absolute',
                            left: loc.x * TAG_IMAGE_SIZE - 150,
                            top: loc.y * TAG_IMAGE_SIZE + 30,
                            zIndex: 100,
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            border: '1px solid #e4e4e4',
                          }}
                        >
                          <ProductOption
                            product={
                              currentModalProductLocs[productIndex].product
                            }
                          />
                        </Box>
                      )}
                    {!loc.product && (
                      <SearchProductInput
                        left={loc.x * TAG_IMAGE_SIZE - 150}
                        top={loc.y * TAG_IMAGE_SIZE + 30}
                        choiceProduct={setModalProductLocItem}
                      />
                    )}
                  </div>
                ))
              )}
            </Box>
          </Box>
          <Box sx={{ width: 20 }} paddingY={2} />
          <Box paddingY={2} width={'100%'} paddingRight={5}>
            <p style={{ fontWeight: 700 }}>태그된 상품</p>
            <Divider sx={{ color: '#e4e4e4' }} />
            <Grid container>
              {currentModalProductLocs.map(
                (locs, index) =>
                  locs.product && (
                    <Grid item xs={6} key={locs.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <ProductOption product={locs.product} />
                        <CloseIcon
                          ref={(element) => {
                            deleteButtonRef.current[index] = element
                          }}
                          onClick={() => removeModalProductLocByIndex(index)}
                        />
                      </Box>
                    </Grid>
                  )
              )}
            </Grid>
          </Box>
        </Box>
        <Divider sx={{ color: '#e4e4e4' }} />
        <Box
          paddingX={8}
          paddingY={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            sx={{
              color: '#f57979',
              backgroundColor: '#fff0f0',
              width: 80,
              height: 50,
              borderRadius: '10px',
              fontWeight: 700,
            }}
            onClick={() => {
              closeModal()
            }}
          >
            취소
          </Button>
          <Box sx={{ width: 20 }} />
          <Button
            variant="contained"
            sx={{
              color: 'white',
              backgroundColor: 'black',
              width: 80,
              height: 50,
              borderRadius: '10px',
              fontWeight: 700,
            }}
            onClick={() => {
              addProductLocs()
              closeModal()
            }}
          >
            저장
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default SnapModal

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height: 700,
}

const ShortInputTextField = styled(TextField)({
  borderRadius: 4,
  paddingTop: 5,
  backgroundColor: 'white',
  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    // 해당 input focus 되었을 때 placeholder text color
    // floatng label을 사용할 때 처리 필요하다
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
  '& label.MuiInputLabel-root[data-shrink=false]': {
    top: '-8px',
  },
})
