import { Box, Typography } from '@mui/material'

import { CheckCircleOutline } from '@mui/icons-material'
import { useEffect } from 'react'

const Snackbar = ({ open, setOpen, Icon, message }) => {
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
    return
  }, [open])

  return (
    <Box sx={{ ...style, display: open ? '' : 'none' }}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
      >
        {Icon}
        <Box width={'10px'} />
        <p>{message}</p>
      </Typography>
    </Box>
  )
}

export const CheckSnackbar = ({ open, setOpen, message }) => (
  <Snackbar
    open={open}
    setOpen={setOpen}
    message={message ?? '성공적으로 저장되었습니다.'}
    Icon={<CheckCircleOutline style={{ fontSize: 40, color: '#00d819' }} />}
  />
)

export const ForbiddenSnackbar = ({ open, setOpen }) => (
  <Snackbar
    open={open}
    setOpen={setOpen}
    message="대표자명, 사업자 등록번호, 개업연원일을 확인해주세요."
    Icon={<CheckCircleOutline style={{ fontSize: 40, color: 'red' }} />}
  />
)

export const UnAvailableUserSnackbar = ({ open, setOpen }) => (
  <Snackbar
    open={open}
    setOpen={setOpen}
    message="기본 정보를 모두 입력해주세요."
    Icon={<CheckCircleOutline style={{ fontSize: 40, color: 'red' }} />}
  />
)

export const UnAvailableRegisterProductSnackbar = ({ open, setOpen }) => (
  <Snackbar
    open={open}
    setOpen={setOpen}
    message="상품 등록에 실패하였습니다. 등록하신 정보를 확인해주세요!"
    Icon={<CheckCircleOutline style={{ fontSize: 40, color: 'red' }} />}
  />
)

const style = {
  zIndex: 1000,
  position: 'fixed',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '800px',
  height: '60px',
  bgcolor: '#e4e4e4',
  boxShadow: 24,
  borderRadius: 3,
  p: 1.3,
}

export default Snackbar
