import { instance } from './instance'
import { getSellerFromLocalStorage } from './storage'

export const getImgUrls = async (imgFiles) => {
  try {
    const seller = getSellerFromLocalStorage()

    const formData = new FormData()
    formData.append('type', 'product')
    for (const file of imgFiles) {
      const newFileName = `${seller.name ? seller.name : 'product'}.${file.name
        .split('.')
        .pop()}`

      formData.append(
        'files',
        new Blob([file], { type: file.type }),
        newFileName
      )
    }

    const ret = await instance.post(`/api/image/uploads`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      token: true,
    })

    return {
      success: true,
      data: ret.data.data.map((o) => o.url),
    }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const createProduct = async (body) => {
  try {
    await instance.post(`/api/product`, body, {
      token: true,
    })
    return {
      success: true,
    }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const putProduct = async (body, pid) => {
  try {
    await instance.put(
      `/api/product`,
      { productId: pid, ...body },
      {
        token: true,
      }
    )
    return {
      success: true,
    }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const getProducts = async (
  page,
  categoryId,
  keyword,
  sellStatus,
  approveStatus,
  productSort,
  sortType,
  size
) => {
  try {
    const query = {
      page,
      categoryId,
      keyword,
      sellStatus,
      approveStatus,
      productSort,
      sortType,
      size,
    }

    const queryStr = Object.keys(query)
      .filter((key) => query[key])
      .map((key) => `${key}=${query[key]}`)
      .join('&')

    const { data } = await instance.get(`/api/product/seller?${queryStr}`, {
      token: true,
    })

    return data
  } catch (error) {
    throw error
  }
}

export const getProduct = async (pid) => {
  try {
    const { data } = await instance.get(`/api/product/detail/${pid}`, {
      token: true,
    })

    return data
  } catch (error) {
    throw error
  }
}

export const searchProduct = async (params) => {
  const QUERY = new URLSearchParams()
  if (params?.page) QUERY.append('page', params.page)
  if (params?.keyword) QUERY.append('keyword', params.keyword)

  try {
    const { data } = await instance.get(`/api/seller/seller-product?${QUERY}`, {
      token: true,
    })

    return { items: data.items, hasNext: data.hasNext }
  } catch (error) {
    throw error
  }
}

export const removeProducts = async (productIds) => {
  try {
    let path = productIds.reduce((acc, id) => acc + `productIds=${id}&`, '')
    await instance.delete(`/api/product?${path}`, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const updateSellStatus = async (productIds, sellStatus) => {
  try {
    await instance.post(
      `/api/product/sell-status`,
      { productIds, sellStatus },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const updateDiscount = async (
  productIds,
  discountType,
  discountAmount
) => {
  try {
    await instance.patch(
      `/api/product/discount`,
      { productIds, discountType, discountAmount },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const updateStock = async (products) => {
  try {
    await instance.patch(
      `/api/product/stock`,
      {
        products,
      },
      { token: true }
    )
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const uploadExcel = async (products) => {
  try {
    const body = products.map((p) => {
      const {
        categoryBig,
        category,
        sum,
        count,
        status,
        reason,
        cellNumber,
        ...etc
      } = p
      return etc
    })

    await instance.post(
      `/api/product/excel`,
      { products: body },
      { token: true }
    )
  } catch (error) {
    throw error
  }
}

export const getUntagProductList = async (page) => {
  const URL = `/api/product/untag?page=${page}&size=${10}`
  try {
    const { data } = await instance.get(URL, {
      token: true,
    })

    return { data: data.data, count: data.count }
  } catch (error) {
    throw error
  }
}
