import { Skeleton } from '@mui/material'
import { useState } from 'react'
import styled from 'styled-components'

const Image = ({
  src,
  alt,
  width,
  height,
  borderRadius,
  padding,
  margin,
  onClick,
  cursor,
  border,
  boxSizing,
  hoverStyle,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [loading, setLoading] = useState(true)

  const skeleton = (
    <Skeleton
      variant="rectangular"
      width={width}
      height={height}
      sx={{ display: 'inline-block' }}
    />
  )

  if (!src) {
    return skeleton
  }

  return (
    <>
      {loading && skeleton}
      <Img
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        src={src}
        alt={alt}
        style={{
          width,
          height,
          borderRadius,
          padding,
          margin,
          cursor,
          border,
          boxSizing,
        }}
        hoverStyle={hoverStyle}
        onClick={onClick}
        onLoad={() => {
          setLoading(false)
        }}
        loading={loading.toString()}
      />
    </>
  )
}

export default Image

const Img = styled('img')(({ hoverStyle, loading }) => ({
  objectFit: 'cover',
  ':hover': hoverStyle,
  display: loading === 'true' ? 'none' : '',
}))
