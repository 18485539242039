import { Divider, SvgIcon } from '@mui/material'
import { useEffect, useState } from 'react'
import { getOrderItems } from '../../api/order'

import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Navigation from '../../components/post/navigation'
import { InputTextField } from '../../element/InputTextField'
import DefaultLayout from '../../global/DefaultLayout'
import RadioSelect from '../../components/RadioSelect'
import SellTable from '../../components/sell/sell-table'
import DatePickerValue from '../../components/common/DataPicker'
import SetInvoiceModal from '../../components/sell/set-invoice-modal'
import { Button } from '../../element/Button'
import { CheckSnackbar } from '../../components/Snackbar'
import { inputLabelPropsStyle, isValidDate } from '../../utils'
import dayjs from 'dayjs'

const INNER_SHIPPING_STATUS = [
  { name: '전체', value: '' },
  { name: '발송요청', value: 'REQUEST_SENT' },
  { name: '발송완료', value: 'SENT_COMPLETE' },
  // { name: '배송중', value: 'SHIPPING' },
  // { name: '센터도착', value: 'ARRIVED_CENTER' },
  { name: '입고완료', value: 'RECEIVED_COMPLETE' },
  { name: '반송', value: 'RETURN' },
]

const OUTER_SHIPPING_STATUS = [
  { name: '전체', value: '' },
  { name: '국내배송중', value: 'IN_TRANSIT' },
  { name: '출고대기중', value: 'PENDING_EXPORT' },
  { name: '출고완료', value: 'EXPORT_COMPLETE' },
  { name: '해외배송중', value: 'SHIPPING' },
  { name: '배송완료', value: 'DELIVERY_COMPLETE' },
]

const SETTLED_PAYMENT_STATUS = [
  { name: '전체', value: '' },
  { name: '정산대기', value: false },
  { name: '정산완료', value: true },
]

const RETURN_STATUS = [
  { name: '전체', value: '' },
  { name: '예(Y)', value: true },
  { name: '아니오(N)', value: false },
]

const SellListPage = () => {
  const [checked, setChecked] = useState([])
  const [orderItems, setOrderItems] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getOrderItems(page)
      if (success) {
        setOrderItems(data.data)
        setCount(data.count)
      }
    })()

    setChecked([])
  }, [page])

  const clickSearchBtn = async () => {
    if (!isValidDate(date.startAt, date.endAt)) {
      return
    }

    const { success, data } = await getOrderItems(
      page,
      date.startAt.toISOString(),
      date.endAt.toISOString(),
      keyword,
      inner,
      outer,
      settled,
      ret
    )
    if (success) {
      setOrderItems(data.data)
      setCount(data.count)
    }
  }

  const [date, setDate] = useState({
    startAt: dayjs().subtract(1, 'months').startOf('day'),
    endAt: dayjs(),
  })
  const [keyword, setKeyword] = useState('')
  const [inner, setInner] = useState('')
  const [outer, setOuter] = useState('')
  const [settled, setSettled] = useState('')
  const [ret, setRet] = useState('')

  const [open, setOpen] = useState(false)
  const [isModify, setModify] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setChecked([])
  }

  const [onCheckBar, setOnCheckBar] = useState(false)
  const [snackBarMsg, setSnackBarMsg] =
    useState('발송정보 등록이 완료되었습니다.')

  return (
    <DefaultLayout isLoggedIn>
      <CheckSnackbar
        open={onCheckBar}
        setOpen={setOnCheckBar}
        message={snackBarMsg}
      />
      <Box sx={boxStyle}>
        <Box display="flex">
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            날짜/상품별 조회
          </p>

          <Box width={30} />

          <p
            style={{
              marginTop: 'auto',
              color: '#707070',
            }}
          >
            * 조회 기간 선택 시 결제 기준으로 적용됩니다.
          </p>
        </Box>

        <Divider sx={{ height: 16 }} />

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              조회기간 <span style={{ color: 'red' }}>*</span> :
            </p>
          </LeftBox>

          <DatePickerValue date={date} setDate={setDate} />
        </OptionContainer>

        <p
          style={{
            marginLeft: 130,
            color: '#ff0000',
          }}
        >
          조회기간은 최대 1년 이내 기간으로 입력해주세요.
        </p>

        <SetInvoiceModal
          isModify={isModify}
          setModify={setModify}
          setOnCheckBar={setOnCheckBar}
          setSnackBarMsg={setSnackBarMsg}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          open={open}
          handleClose={handleClose}
          checked={checked}
        />

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              상품명 입력 :
            </p>
          </LeftBox>

          <InputTextField
            width="300px"
            label="상품 검색어를 입력해주세요."
            InputLabelProps={inputLabelPropsStyle}
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
          />
        </OptionContainer>

        <RadioSelect
          label="국내배송상태"
          list={INNER_SHIPPING_STATUS}
          setState={setInner}
          state={inner}
        />

        <RadioSelect
          label="해외배송상태"
          list={OUTER_SHIPPING_STATUS}
          setState={setOuter}
          state={outer}
        />

        <RadioSelect
          label="정산여부"
          list={SETTLED_PAYMENT_STATUS}
          setState={setSettled}
          state={settled}
        />

        <RadioSelect
          label="반품여부"
          list={RETURN_STATUS}
          setState={setRet}
          state={ret}
        />

        <OptionContainer>
          <Button
            size="large"
            bold
            borderRadius={2}
            height="40px"
            sx={{
              marginLeft: '130px',
            }}
            onClick={clickSearchBtn}
          >
            <SvgIcon component={SearchIcon} inheritViewBox />
            <p style={{ marginLeft: '10px' }}>상품 검색</p>
          </Button>
        </OptionContainer>
      </Box>

      <Box sx={boxStyle}>
        <Box display="flex" gap={2}>
          <Button
            height="40px"
            bg="#BACAFF"
            color="#003BFF"
            bold
            borderRadius="10px"
            onClick={() => {
              if (checked.length === 0) {
                return
              }
              if (checked.some((o) => o.innerInvoiceNumber)) {
                alert(
                  '이미 발송 정보가 등록된 상품이 존재합니다.\n해당 상품을 제거 후 다시 시도 해 주세요.'
                )
                return
              }
              setModify(false)
              handleOpen()
            }}
          >
            <p>선택 발송정보 입력</p>
          </Button>

          <Button
            height="40px"
            bg="#baffd5"
            color="#00a52d"
            bold
            borderRadius="10px"
            onClick={() => {
              if (checked.length === 0) {
                return
              }

              if (!isSameItem(checked)) {
                alert(
                  '같은 발송정보의 상품만 선택 수정 가능합니다.\n같은 발송정보의 상품을 선택 하신 후 다시 시도해 주세요.'
                )
                return
              }

              if (existEmptyItem(checked)) {
                alert(
                  '아직 발송정보가 등록되지 않은 상품이 존재합니다.\n선택 발송 정보 입력 버튼을 통해 발송 정보를 등록해 주세요.'
                )
                return
              }

              setModify(true)
              handleOpen()
            }}
          >
            <p>선택 발송정보 수정</p>
          </Button>
        </Box>

        <Divider sx={{ height: 30 }} />

        <SellTable
          orderItems={orderItems}
          checked={checked}
          setChecked={setChecked}
          handleOpen={handleOpen}
          setModify={setModify}
        />
        <Navigation page={page} setPage={setPage} count={count} />
      </Box>
    </DefaultLayout>
  )
}
export default SellListPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

export const LeftBox = ({ children }) => (
  <Box
    width={100}
    display="flex"
    justifyContent="right"
    alignItems="center"
    marginRight={3}
  >
    {children}
  </Box>
)

export const OptionContainer = ({ children }) => (
  <Box display="flex" paddingTop="20px">
    {children}
  </Box>
)

const isSameItem = (checked) => {
  const first = checked[0]
  return (
    checked.every((o) => o.innerInvoiceNumber === first.innerInvoiceNumber) &&
    checked.every((o) => o.innerShippingCompany === first.innerShippingCompany)
  )
}

const existEmptyItem = (checked) => {
  return checked.some((o) => !o.innerInvoiceNumber)
}
