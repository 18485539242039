import React from 'react'
import { searchProduct } from '../../../api/product'

const useLogic = () => {
  const [keyword, setKeyword] = React.useState('')
  const [page, setPage] = React.useState(1)
  const [hasNext, setHasNext] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [products, setProducts] = React.useState([])
  const end = React.useRef()

  const handleKeyword = (e) => setKeyword(e.target.value)

  const handleSearch = async () => {
    setLoading(true)
    setProducts([])
    try {
      const { items, hasNext } = await searchProduct({ keyword, page: 1 })
      setProducts((prev) => prev.concat(items))
      setHasNext(hasNext)
      setPage(2)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const handleFetch = async () => {
    try {
      const { items, hasNext } = await searchProduct({ keyword, page })
      setProducts((prev) => prev.concat(items))
      setHasNext(hasNext)
      setPage((prev) => prev + 1)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0]

        if (firstEntry.isIntersecting) {
          handleFetch()
        }
      },
      { threshold: 0.5 }
    )
    if (end.current) {
      observer.observe(end.current)
    }

    return () => {
      if (end.current) {
        observer.unobserve(end.current)
      }
    }
  }, [products])

  return {
    ref: { end },
    value: { keyword, products, loading, hasNext, error },
    handler: {
      keyword: handleKeyword,
      search: handleSearch,
      fetch: handleFetch,
    },
  }
}

export default useLogic
