import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

const GlobalStyle = createGlobalStyle`
  ${reset};

  .slick-prev, .slick-next {
    display: none !important; // 화살표 숨기기
  }

  p {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 14px;
  }
`

export default GlobalStyle
