import { Divider, Grid, SvgIcon } from '@mui/material'
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  getProducts,
  removeProducts,
  updateSellStatus,
} from '../../api/product'

import SearchIcon from '@mui/icons-material/Search'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import { getCategory } from '../../api/category'
import DeleteModal from '../../components/DeleteModal'
import { CheckSnackbar } from '../../components/Snackbar'
import { PreviewProductModal } from '../../components/modal/modal'
import Navigation from '../../components/post/navigation'
import ProductTable from '../../components/product/product-table'
import { InputTextField } from '../../element/InputTextField'
import DefaultLayout from '../../global/DefaultLayout'
import { inputLabelPropsStyle } from '../../utils'
import SetDiscountModal from '../../components/product/set-discount-modal'

const SELL_STATUS = [
  { name: '전체', value: '' },
  { name: '판매중', value: 'SELLING' },
  { name: '판매종료', value: 'ENDING' },
  { name: '품절', value: 'SOLDOUT' },
]

const SELECT_SELL_STATUS = [
  { name: '', value: '' },
  { name: '판매중', value: 'SELLING' },
  { name: '판매종료', value: 'ENDING' },
  { name: '품절', value: 'SOLDOUT' },
]

export const SELECT_SIZE = [
  { name: '', value: '' },
  { name: '20개씩', value: 20 },
  { name: '50개씩', value: 50 },
  { name: '100개씩', value: 100 },
  { name: '300개씩', value: 300 },
]

const APPROVE_STATUS = [
  { name: '전체', value: '' },
  { name: '승인요청', value: 'REQUEST' },
  { name: '승인대기', value: 'WAITING' },
  { name: '승인완료', value: 'APPROVED' },
  { name: '승인반려', value: 'REJECT' },
]

const ProductListPage = () => {
  const [checked, setChecked] = useState([])
  const [products, setProducts] = useState([])
  const [approvedStatus, setApproveStatus] = useState('')
  const [sellStatus, setSellStatus] = useState('')

  const [categoryOption, setCategoryOption] = useState([])
  const [category, setCategory] = useState({})

  const location = useLocation()
  const isPop = location.search.includes('pop=true')
  const navigation = useNavigate()

  const [id, setId] = useState('')
  const [preview, setPreview] = useState(false)

  const [open, setOpen] = useState(false)
  const closeDiscountModal = () => setOpen(false)
  const openDiscountModal = () => {
    if (checked.length === 0) {
      alert('선택된 상품이 없습니다.')
      return
    }

    setOpen(true)
  }

  const clickHandler = (id) => {
    setId(id)
    setPreview(true)
  }

  useEffect(() => {
    ;(async () => {
      const categories = (await getCategory()).data
      const filtered = categories.filter((c) => c.parent === null)
      setCategoryOption(filtered)
    })()

    if (isPop) {
      navigation(location.pathname)
    }
  }, [])

  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState({ productSort: '', sortType: '' })

  useEffect(() => {
    ;(async () => {
      const { data, count } = await getProducts(
        page,
        category.id,
        keyword,
        sellStatus,
        approvedStatus,
        sort.productSort,
        sort.sortType,
        size
      )
      setProducts(data)
      setCount(count)
    })()

    setChecked([])
  }, [page, sort, size])

  const clickSearchBtn = async () => {
    setPage(1)
    setSort({ sortType: '', productSort: '' })
  }

  const [onCheckBar, setOnCheckBar] = useState(isPop)
  const [onDelModal, setOnDelModal] = useState(false)
  const handleOpen = () => {
    setOnDelModal(true)
  }
  const handleClose = () => {
    setOnDelModal(false)
  }
  const clickDelBtn = () => {
    if (checked.length === 0) {
      return
    }

    handleOpen()
  }

  const clickDelModalBtn = async () => {
    const { success } = await removeProducts(checked.map((o) => o.id))
    if (success) {
      handleClose()
      setOnCheckBar(true)
      const filtered = products.filter(
        (post) => !checked.map((o) => o.id).includes(post.id)
      )
      setProducts([...filtered])
      setCount(filtered.length)
      setChecked([])
    } else {
      alert('에러가 발생했습니다.')
    }
  }

  const selectSellStatus = async (sellStatus) => {
    if (checked.length === 0) {
      return
    }

    const { success } = await updateSellStatus(
      checked.map((o) => o.id),
      sellStatus
    )

    if (success) {
      handleClose()
      setOnCheckBar(true)

      for (const p of checked) {
        p.sellStatus = sellStatus
      }

      setProducts([...products])
      setChecked([])
    } else {
      alert('에러가 발생했습니다.')
    }
  }

  return (
    <DefaultLayout isLoggedIn>
      <SetDiscountModal
        products={products}
        setProducts={setProducts}
        open={open}
        handleClose={closeDiscountModal}
        checked={checked}
        setChecked={setChecked}
        setPage={setPage}
      />

      <DeleteModal
        open={onDelModal}
        handleClose={handleClose}
        clickModalBtn={clickDelModalBtn}
      />
      <CheckSnackbar open={onCheckBar} setOpen={setOnCheckBar} />

      <Box sx={boxStyle}>
        <p
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          상품 검색
        </p>
        <Divider sx={{ height: 16 }} />
        <Grid
          container
          spacing={3}
          sx={{ marginTop: 0, display: 'flex', alignItems: 'center' }}
        >
          <Grid item xs={1.6}>
            <p
              style={{
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              카테고리
            </p>
          </Grid>
          <Grid item xs={10}>
            <CustomDropDownField
              fullWidth
              disableClearable
              size="small"
              value={category.korName}
              onChange={(_, value) => {
                setCategory(categoryOption.find((c) => c.korName === value))
              }}
              ListboxProps={{
                style: {
                  fontSize: '14px',
                },
              }}
              id="category_option"
              options={['', ...categoryOption.map((o) => o.korName)]}
              filterOptions={(options, { inputValue }) => {
                return options.filter(
                  (option) => option !== '' && option.includes(inputValue)
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={inputLabelPropsStyle}
                  label="카테고리 선택"
                />
              )}
            />
          </Grid>
          <Grid item xs={1.6}>
            <p
              style={{
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              검색어 입력
            </p>
          </Grid>
          <Grid item xs={10}>
            <InputTextField
              width="450px"
              label="매니저명, 상품명"
              InputLabelProps={inputLabelPropsStyle}
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  clickSearchBtn()
                }
              }}
            />
          </Grid>
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              판매 상태
            </p>
          </Grid>
          <Grid item xs={10}>
            <RadioGroup row defaultValue="all">
              {SELL_STATUS.map(({ name, value }, idx) => (
                <MyFormControlLabel
                  key={idx}
                  value="request"
                  control={
                    <Radio
                      onClick={() => {
                        setSellStatus(value)
                      }}
                      size="small"
                      value={value}
                      checked={value === sellStatus}
                      sx={{
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                    />
                  }
                  label={name}
                />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              승인 상태
            </p>
          </Grid>
          <Grid item xs={10} sx={{ display: 'flex' }}>
            <RadioGroup row defaultValue="all">
              {APPROVE_STATUS.map(({ name, value }, idx) => (
                <MyFormControlLabel
                  key={idx}
                  value="request"
                  control={
                    <Radio
                      onClick={() => {
                        setApproveStatus(value)
                      }}
                      size="small"
                      value={value}
                      checked={value === approvedStatus}
                      sx={{
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                    />
                  }
                  label={name}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={1.6}></Grid>
          <Grid item xs={10}>
            <Button
              size="large"
              variant="contained"
              sx={{
                backgroundColor: 'black',
                fontWeight: 'bold',
                borderRadius: 2,
                boxShadow: 'none',
                height: '50px',
              }}
              onClick={clickSearchBtn}
            >
              <SvgIcon component={SearchIcon} inheritViewBox />
              <p style={{ marginLeft: '10px' }}>상품 검색</p>
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={boxStyle}>
        <p
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          상품 목록
        </p>
        <Divider sx={{ height: 16 }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 2,
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex">
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#FFF0F0',
                color: '#FF0000',
                fontWeight: 'bold',
                boxShadow: 'none',
                borderRadius: 2,
                height: 44,
                marginRight: 2,
              }}
              onClick={clickDelBtn}
            >
              <p>선택 삭제</p>
            </Button>

            <Button
              variant="contained"
              sx={{
                backgroundColor: '#BACAFF',
                color: '#003BFF',
                fontWeight: 'bold',
                boxShadow: 'none',
                borderRadius: 2,
                height: 44,
                marginRight: 2,
              }}
              onClick={openDiscountModal}
            >
              <p>선택 할인 적용</p>
            </Button>

            <CustomSmallDropDownField
              fullWidth
              disableClearable
              size="small"
              value=""
              onChange={(event, value) => {
                selectSellStatus(
                  SELECT_SELL_STATUS.find((o) => o.name === value).value
                )
              }}
              ListboxProps={{
                style: {
                  fontSize: '14px',
                },
              }}
              id="selling_option"
              options={SELECT_SELL_STATUS.map((o) => o.name)}
              filterOptions={(options, { inputValue }) => {
                return options.filter(
                  (option) => option !== '' && option.includes(inputValue)
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={inputLabelPropsStyle}
                  label="판매 변경"
                />
              )}
            />
          </Box>

          <CustomSmallDropDownField
            fullWidth
            disableClearable
            size="small"
            value=""
            onChange={(event, value) => {
              const size = Number(
                SELECT_SIZE.find((o) => o.name === value).value
              )
              setSize(size)
              setPage(1)
            }}
            ListboxProps={{
              style: {
                fontSize: '14px',
              },
            }}
            id="selling_option"
            options={SELECT_SIZE.map((o) => o.name)}
            filterOptions={(options, { inputValue }) => {
              return options.filter(
                (option) => option !== '' && option.includes(inputValue)
              )
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={inputLabelPropsStyle}
                label="20개씩"
              />
            )}
          />
        </Box>
        <Divider sx={{ height: 16 }} />

        <ProductTable
          products={products}
          checked={checked}
          setChecked={setChecked}
          clickHandler={clickHandler}
          sort={sort}
          setSort={setSort}
          page={page}
          setPage={setPage}
        />
        <Navigation page={page} setPage={setPage} count={count} />
      </Box>
      {preview && (
        <PreviewProductModal
          id={id}
          open={preview}
          onClose={() => setPreview(false)}
          buttonGroup={<></>}
        />
      )}
    </DefaultLayout>
  )
}
const CustomDropDownField = styled(Autocomplete)({
  marginTop: '10px',
  width: '450px',

  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
})
export const CustomSmallDropDownField = styled(Autocomplete)({
  marginTop: '6px',
  width: '110px',
  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
})

export default ProductListPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  marginRight: '60px',
  '.MuiFormControlLabel-label': checked
    ? {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '14px',
      }
    : {
        color: '#707070',
        fontSize: '14px',
      },
}))

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup()

  let checked = false

  if (radioGroup) {
    checked = radioGroup.value === props.value
  }

  return <StyledFormControlLabel checked={checked} {...props} />
}
