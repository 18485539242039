import { Box, Button, Modal, Stack, Typography } from '@mui/material'
import { InputTextField } from '../../element/InputTextField'
import { useState } from 'react'
import { isValidPwd } from '../../utils'
import { changePwd } from '../../api/seller'

export const PasswordChangeModal = ({ open, onClose }) => {
  const [info, setInfo] = useState({ pwd: '', newPwd: '', compNewPwd: '' })
  const onClickBtn = async () => {
    if (!Object.values(info).every((pwd) => isValidPwd(pwd))) {
      alert('비밀번호 형식이 올바르지 않습니다. 비밀번호 형식을 확인해주세요.')
      return
    }

    if (info.pwd === info.newPwd) {
      alert('기존 비밀번호와 새 비밀번호가 같습니다.')
      return
    }

    if (info.newPwd !== info.compNewPwd) {
      alert('변경 비밀번호와 변경 비밀번호 확인이 올바르지 않습니다.')
      return
    }

    const { success } = await changePwd(info.pwd, info.newPwd)
    console.log(success)
    if (success) {
      alert('비밀번호가 변경되었습니다.')
      onClose()
      return
    }

    alert('비밀번호 변경에 실패하였습니다.')
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Stack
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          backgroundColor: 'white',
          padding: '20px',
        }}
        direction="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography sx={{ fontWeight: 700, fontSize: 30 }}>
          비밀번호 변경
        </Typography>

        <Typography sx={{ fontWeight: 400, fontSize: 15 }}>
          (8~16자 이내 영문대소문자 각 최소1개 이상, 숫자, 특수문자 조합)
        </Typography>

        <Box sx={{ height: '20px' }} />

        {[
          {
            text: '기존 비밀번호',
            getValue: (value) => ({ ...info, pwd: value }),
            value: info.pwd,
          },
          {
            text: '변경 비밀번호',
            getValue: (value) => ({ ...info, newPwd: value }),
            value: info.newPwd,
          },
          {
            text: '변경 비밀번호 확인',
            getValue: (value) => ({ ...info, compNewPwd: value }),
            value: info.compNewPwd,
          },
        ].map(({ text, getValue, value }, idx) => (
          <Stack
            direction="row"
            gap={'10px'}
            key={idx}
            sx={{ marginTop: '10px' }}
          >
            <Box>
              <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
                {text}
              </Typography>
              <InputTextField
                type="password"
                width="450px"
                label={text}
                value={value}
                onChange={(e) => {
                  setInfo(getValue(e.target.value))
                }}
              />
            </Box>
          </Stack>
        ))}

        <Button
          variant="contained"
          sx={{
            width: '140px',
            bgcolor: '#000000',
            color: '#ffffff',
            '&:hover': {
              bgcolor: '#000000',
              color: '#ffffff',
              borderColor: '#707070',
            },
            '&:active': {
              bgcolor: '#000000',
              color: '#ffffff',
              borderColor: '#707070',
            },
            fontWeight: 700,
            marginTop: '30px',
          }}
          onClick={onClickBtn}
        >
          비밀번호 변경
        </Button>
      </Stack>
    </Modal>
  )
}
