import Checkbox from '@mui/material/Checkbox'
import styled from 'styled-components'

import { getDate } from '../../utils'
import { priceWrapper } from '../../pages/product/addProduct'
import { Box } from '@mui/material'
import { valueToName } from './select-btn'

const heads = [
  '',
  '주문번호',
  '상품주문번호',
  '판매일',
  '상품명',
  '판매국가',
  '판매금액($)',
  '판매금액(원)',
  '정산금액',
  '개수',
  '총 정산금액',
  '국내배송상태',
  '해외배송상태',
  '정산여부',
  '송장번호',
  '국내발송정보',
]

const INNER_SHIPPING_STATUS_TABLE = {
  REQUEST_SENT: '발송요청',
  SENT_COMPLETE: '발송완료',
  SHIPPING: '배송중',
  ARRIVED_CENTER: '센터도착',
  RECEIVED_COMPLETE: '입고완료',
  RETURN: '반송',
}

const OUTER_SHIPPING_STATUS_TABLE = {
  IN_TRANSIT: '국내 배송중',
  PENDING_EXPORT: '출고대기',
  EXPORT_COMPLETE: '출고완료',
  SHIPPING: '해외 배송중',
  DELIVERY_COMPLETE: '배송완료',
}

const SellTable = ({
  orderItems,
  checked,
  setChecked,
  handleOpen,
  setModify,
}) => {
  const onClickCheckBtn = (item) => {
    if (checked.map((o) => o.id).includes(item.id)) {
      const idx = checked.findIndex((o) => o.id === item.id)
      checked.splice(idx, 1)
    } else {
      checked.push(item)
    }
    setChecked([...checked])
  }

  const onClickSendPaymentInput = (item, innerInvoiceNumber) => {
    setChecked([item])

    handleOpen()
    setModify(innerInvoiceNumber ? true : false)
  }

  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>
              <p style={{ color: '#707070' }}>{head}</p>
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {orderItems.map((item, index) => {
          const {
            order: { orderNumber },
            product: { name },
            orderItemNumber,
            createdAt,
            orderPrice,
            domesticPrice,
            transactionPrice,
            orderCount,
            innerShippingStatus,
            outerShippingStatus,
            isSettledPayment,
            innerInvoiceNumber,
            outerInvoiceNumber,
            innerShippingCompany,
            countryCode,
          } = item

          return (
            <tr
              key={item.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
            >
              <Td>
                <Box textAlign={'center'}>
                  <Checkbox
                    checked={checked.map((o) => o.id).includes(item.id)}
                    onClick={(e) => {
                      onClickCheckBtn(item)
                    }}
                  />
                </Box>
              </Td>
              <Td>{orderNumber}</Td>
              <Td>{orderItemNumber}</Td>
              <Td>{getDate(createdAt)}</Td>
              <Td width="400px">
                <PBox>{name}</PBox>
              </Td>
              <Td>{countryCode}</Td>
              <Td>{orderPrice}</Td>
              <Td>{priceWrapper(domesticPrice)}</Td>
              <Td>{priceWrapper(transactionPrice)}</Td>
              <Td>{orderCount}</Td>
              <Td>{priceWrapper(transactionPrice * orderCount)}</Td>
              <Td>
                {getInnerShippingTap(
                  innerShippingStatus,
                  innerShippingCompany,
                  innerInvoiceNumber
                )}
              </Td>
              <Td>
                {getOuterShippingTap(outerShippingStatus, outerInvoiceNumber)}
              </Td>
              <Td>{isSettledPayment ? 'Y' : 'N'}</Td>
              <Td>{innerInvoiceNumber}</Td>
              <Td>
                {getInvoiceState(
                  innerInvoiceNumber,
                  outerShippingStatus,
                  onClickSendPaymentInput,
                  item,
                  innerInvoiceNumber
                )}
              </Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default SellTable

const Table = styled('table')({
  width: '100%',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '14px',
})

const P = styled('p')({
  cursor: 'pointer',
  display: 'inline',
  borderBottom: '1px solid',
})

const getInvoiceState = (
  invoice,
  outerShippingStatus,
  onClickSendPaymentInput,
  item,
  invoiceNumber
) => {
  if (outerShippingStatus !== 'IN_TRANSIT') {
    return <></>
  }

  const style = {}
  let message = '발송정보 변경'
  if (!invoice) {
    style['color'] = '#003bff'
    message = '발송정보 입력하기'
  }

  return (
    <P
      style={style}
      onClick={() => {
        onClickSendPaymentInput(item, invoiceNumber)
      }}
    >
      {message}
    </P>
  )
}

const PBox = styled('div')({
  width: '400px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const getInnerShippingTap = (
  innerShippingStatus,
  innerShippingCompany,
  innerInvoiceNumber
) => {
  const name = INNER_SHIPPING_STATUS_TABLE[innerShippingStatus]

  if (innerShippingStatus !== 'SENT_COMPLETE') {
    return <p>{name}</p>
  }

  return (
    <>
      <p>{name}</p>
      <p>
        <a
          href={`https://search.naver.com/search.naver?where=nexearch&query=${valueToName(
            innerShippingCompany
          )}+${innerInvoiceNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          배송상태 확인
        </a>
      </p>
    </>
  )
}

const getOuterShippingTap = (outerShippingStatus, outerInvoiceNumber) => {
  const name = OUTER_SHIPPING_STATUS_TABLE[outerShippingStatus]

  if (outerShippingStatus !== 'EXPORT_COMPLETE') {
    return <p>{name}</p>
  }

  return (
    <>
      <p>{name}</p>
      <p>
        <a
          href={`https://search.naver.com/search.naver?where=nexearch&query=DHL+${outerInvoiceNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          배송상태 확인
        </a>
      </p>
    </>
  )
}
