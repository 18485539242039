import * as React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export default function SelectDiscountType({ product, setProduct }) {
  const handleChange = (event) => {
    setProduct({
      ...product,
      discountType: event.target.value,
      discountAmount: 0,
    })
  }

  return (
    <Box sx={{ minWidth: 10, marginX: 1 }}>
      <FormControl fullWidth>
        <Select
          sx={{ height: '45px' }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={product.discountType}
          onChange={handleChange}
        >
          <MenuItem value={'PERCENTAGE'}>%</MenuItem>
          <MenuItem value={'ABSOLUTE'}>원</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}
