import { getToken } from './api/cookie'
import { getSellerFromLocalStorage } from './api/storage'

export const formatDateToKorean = (date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}년 ${month}월 ${day}일`
}

export const getDate = (input) => {
  const date = new Date(input)
  const dateStr = date.toLocaleDateString()
  const hours = date.getHours().toLocaleString().padStart(2, '0')
  const minute = date.getMinutes().toLocaleString().padStart(2, '0')
  return `${dateStr} ${hours}:${minute}`
}

export const getDateExceptDay = (input) => {
  const date = new Date(input)
  const dateStr = date.toLocaleDateString()
  return `${dateStr}`
}

export const isValidPwd = (pwd) => {
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&]).{8,16}$/

  return regex.test(pwd)
}

export const isValidPhoneNum = (number) => /^01[016789]\d{7,8}$/.test(number)

export const isValidEmail = (email) => {
  // 이메일 유효성을 검사하는 정규 표현식
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  // 정규 표현식을 이용하여 유효성 검사
  return emailRegex.test(email) && email.length <= 50
}

export const getLayoutProfileImg = (url) => {
  if (!url) {
    return 'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png'
  }
  return url
}

export const isValidDate = (startAt, endAt) => {
  if (startAt && endAt) {
    const start = new Date(startAt)
    const end = new Date(endAt)

    if (start > end) {
      alert('조회 기간 끝나는 날짜가 더 이를 수 없습니다.')
      return false
    }

    if (end - start > 1000 * 60 * 60 * 24 * 365) {
      alert('조회기간은 최대 1년 이내로 선택해주세요.')
      return false
    }

    return true
  }
}

export const getValueFromName = (list, name) => {
  const item = list.find((item) => item.name === name)
  if (!item) {
    return ''
  }
  return item.value
}

export const getNameFromValue = (list, value) => {
  const item = list.find((item) => item.value === value)
  if (!item) {
    return ''
  }
  return item.name
}

export const getPathFromCheckSeller = () => {
  const seller = getSellerFromLocalStorage()
  const token = getToken()

  if (
    !seller.name ||
    !seller.approveStatus ||
    !seller?.sellerId ||
    !seller?.managerName ||
    !seller?.managerNumber ||
    !seller?.bankInfo ||
    !seller?.businessInfo ||
    !seller?.sellProductInfo ||
    !token ||
    !token.access ||
    !token.refresh
  ) {
    return { shouldDirect: true, path: '/', seller }
  }

  const { gender, style } = seller.sellProductInfo
  if (!seller.profileImg || !gender || Object.keys(style).length === 0) {
    return { shouldDirect: true, path: '/stylist/info', seller }
  }

  return { shouldDirect: false, path: '', seller }
}

export const wrapPrice = (value) => {
  return Number((Math.round(value * 100) / 100).toFixed(2))
}

export const DiscountType = {
  Percentage: 'PERCENTAGE',
  Absolute: 'ABSOLUTE',
  None: 'NONE',
}

export const SortType = {
  DESC: 'DESC',
  ASC: 'ASC',
}

export const inputLabelPropsStyle = {
  style: {
    fontSize: '14px',
    zIndex: 0,
  },
}
