import { instance } from './instance'

export const getNotices = async (page) => {
  try {
    const { data } = await instance.get(
      `/api/notice/admin?type=SELLER&page=${page}`,
      {
        token: true,
      }
    )
    return { success: true, data: data.data, count: data.count }
  } catch (error) {
    return { success: false, data: [], count: 0 }
  }
}

export const getNotice = async (id) => {
  try {
    const { data } = await instance.get(`/api/notice/detail/${id}`, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    return { success: false, data: {} }
  }
}
