import { Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getDashboardInfo } from '../../api/seller'
import DashBoardSection from '../../components/dashboard/section'
import DefaultLayout from '../../global/DefaultLayout'
import { formatDateToKorean } from '../../utils'

const INIT_DATA = {
  grade: {
    grade: '-',
    fee: 0.16,
  },
  product: {
    REJECT: '0',
    WAITING: '0',
    APPROVED: '0',
  },
  store: {
    postsCnt: '0',
    followersCnt: '0',
  },
  sellStatus: {
    REQUEST_SENT: '0', // 신규
    SENT_COMPLETE: '0', // 국내
    // SHIPPING: '0',
    // ARRIVED_CENTER: '0',
    RECEIVED_COMPLETE: '0', // 물류센터 도착(둘 중 하나만 사용)
    // RETURN: '0',
    // IN_TRANSIT: '9',
    PENDING_EXPORT: '0', // 물류센터 도착(중복)
    EXPORT_COMPLETE: '0', // 해외 배송중
    DELIVERY_COMPLETE: '0', // 배송완료
  },
  settlement: {
    unsettledCnt: 0,
    amount: 0,
    settledAt: Date.now(),
  },
  refund: {
    refundCnt: 0,
    refundingCnt: 0,
    storingCenterCnt: 0,
    resellCnt: 0,
  },
}

const DashBoardPage = () => {
  const navigation = useNavigate()

  const [
    { grade, product, store, sellStatus, settlement, refund },
    setDashboard,
  ] = useState(INIT_DATA)

  useEffect(() => {
    ;(async () => {
      const response = await getDashboardInfo()
      if (response.success === false) {
        return
      }
      setDashboard(response.data)
    })()
  }, [])

  return (
    <DefaultLayout isLoggedIn>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ paddingX: '20px' }}
        gap="10px"
      >
        <DashBoardSection
          header={<DashBoardSection.Title title="파트너 등급" />}
          body={
            <>
              <DashBoardSection.Data data={grade?.grade} label="현재 등급" />
              <DashBoardSection.Data
                data={`${(grade?.fee * 100).toFixed(1)}%`}
                label="판매 중개 수수료"
              />
            </>
          }
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          gap="10px"
        >
          <DashBoardSection
            header={
              <DashBoardSection.LinkTitle
                title="상품 현황"
                onClick={() => navigation('/product')}
              />
            }
            body={
              <>
                <DashBoardSection.Data
                  data={
                    parseInt(product?.WAITING) +
                    parseInt(product?.APPROVED) +
                    parseInt(product?.REJECT)
                  }
                  label="등록 상품 수"
                />
                <DashBoardSection.Data
                  data={product?.WAITING}
                  label="승인 대기"
                />
                <DashBoardSection.Data
                  data={product?.APPROVED}
                  label="승인 완료"
                />
              </>
            }
          />
          <DashBoardSection
            header={<DashBoardSection.Title title="스토어 현황" />}
            body={
              <>
                <DashBoardSection.Data
                  data={store?.followersCnt}
                  label="팔로워 수"
                />
                <DashBoardSection.Data
                  data={store?.postsCnt}
                  label="등록 스냅 수"
                />
              </>
            }
          />
        </Stack>
        <DashBoardSection
          header={
            <DashBoardSection.LinkTitle
              title="판매 현황"
              onClick={() => navigation('/sell')}
            />
          }
          body={
            <>
              <DashBoardSection.Data
                data={sellStatus.REQUEST_SENT}
                label="신규 주문"
              />
              <DashBoardSection.Data
                data={sellStatus.SENT_COMPLETE}
                label="국내 배송중"
              />
              <DashBoardSection.Data
                data={sellStatus.RECEIVED_COMPLETE}
                label="물류센터 도착"
              />
              <DashBoardSection.Data
                data={sellStatus.EXPORT_COMPLETE}
                label="해외 배송중"
              />
              <DashBoardSection.Data
                data={sellStatus.DELIVERY_COMPLETE}
                label="배송완료"
              />
            </>
          }
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          gap="10px"
        >
          <DashBoardSection
            header={
              <DashBoardSection.LinkTitleWithDate
                title="정산 현황"
                date={formatDateToKorean(new Date(settlement?.settledAt))}
                onClick={() => navigation('/transaction')}
              />
            }
            body={
              <>
                <DashBoardSection.Data
                  data={settlement?.unsettledCnt}
                  label="정산 대기 건수"
                />
                <DashBoardSection.Data
                  data={`${settlement?.amount?.toLocaleString()} 원`}
                  label="정산 예상 금액"
                />
              </>
            }
          />
          <DashBoardSection
            header={<DashBoardSection.Title title="반품 현황" />}
            body={
              <>
                <DashBoardSection.Data
                  data={refund?.refundCnt}
                  label="반품 요청 건수"
                />
                <DashBoardSection.Data
                  data={refund?.refundingCnt}
                  label="반품 진행중"
                />
                <DashBoardSection.Data
                  data={refund?.storingCenterCnt}
                  label="센터 보관중"
                />
                <DashBoardSection.Data
                  data={refund?.resellCnt}
                  label="판매 완료"
                />
              </>
            }
          />
        </Stack>
        <Typography
          sx={{ color: '#707070', paddingTop: '40px', paddingBottom: '50px' }}
        >
          ©2024 Invincible Ventures, Inc. All Rights Reserved.
        </Typography>
      </Stack>
    </DefaultLayout>
  )
}

export default DashBoardPage
