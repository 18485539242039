import { Divider, SvgIcon } from '@mui/material'
import { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import Box from '@mui/material/Box'
import Navigation from '../../components/post/navigation'
import { InputTextField } from '../../element/InputTextField'
import DefaultLayout from '../../global/DefaultLayout'
import DatePickerValue from '../../components/common/DataPicker'
import { Button } from '../../element/Button'
import TransactionTable from '../../components/transaction/transaction-table'
import { getSettlement, getTransactions } from '../../api/order'
import dayjs from 'dayjs'
import {
  getDateExceptDay,
  inputLabelPropsStyle,
  isValidDate,
} from '../../utils'
import { getSellerFromLocalStorage } from '../../api/storage'

const TransactionPage = () => {
  const [transactions, setTransactions] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [settlement, setSettlement] = useState({
    unsettledAmount: 0,
    refundAmount: 0,
    settledAt: new Date(),
  })

  useEffect(() => {
    ;(async () => {
      const {
        success,
        data: { unsettledAmount, refundAmount, settledAt, updatedAt },
      } = await getSettlement(page)
      if (success) {
        setSettlement({
          unsettledAmount,
          refundAmount,
          settledAt: settledAt ? new Date(settledAt) : new Date(updatedAt),
        })
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const { success, data } = await getTransactions(page)
      if (success) {
        setTransactions(data.data)
        setCount(data.count)
      }
    })()
  }, [page])

  const clickSearchBtn = async () => {
    if (!isValidDate(date.startAt, date.endAt)) {
      return
    }

    const { success, data } = await getTransactions(
      page,
      date.startAt.toISOString(),
      date.endAt.toISOString(),
      keyword
    )
    if (success) {
      setTransactions(data.data)
      setCount(data.count)
    }
  }

  const [date, setDate] = useState({
    startAt: dayjs().subtract(1, 'months').startOf('day'),
    endAt: dayjs(),
  })
  const [keyword, setKeyword] = useState('')

  const seller = getSellerFromLocalStorage()

  return (
    <DefaultLayout isLoggedIn>
      <Box sx={boxStyle}>
        <Box display="flex">
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            정산 요약
          </p>
        </Box>

        <Divider sx={{ height: 16 }} />

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              정산 지급 기준일:
            </p>
          </LeftBox>

          <p style={{ color: 'black', fontWeight: 'bold' }}>
            {settlement.settledAt.toLocaleDateString()} ~
            {new Date().toLocaleDateString()}
          </p>
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              정산 계좌:
            </p>
          </LeftBox>

          <p style={{ color: 'black', fontWeight: 'bold' }}>
            {seller.bankInfo.bankName} {seller.bankInfo.accountNumber}
          </p>
        </OptionContainer>

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              정산 예상 금액:
            </p>
          </LeftBox>

          <p style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}>
            {settlement.unsettledAmount - settlement.refundAmount}
          </p>
        </OptionContainer>
      </Box>

      <Box sx={boxStyle}>
        <Box display="flex">
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            날짜/상품별 조회
          </p>

          <Box width={30} />

          <p
            style={{
              marginTop: 'auto',
              color: '#707070',
            }}
          >
            * 조회 기간 선택 시 결제 기준으로 적용됩니다.
          </p>
        </Box>

        <Divider sx={{ height: 16 }} />

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              조회기간 <span style={{ color: 'red' }}>*</span> :
            </p>
          </LeftBox>

          <DatePickerValue date={date} setDate={setDate} />
        </OptionContainer>

        <p
          style={{
            marginLeft: 155,
            color: '#ff0000',
          }}
        >
          조회기간은 최대 1년 이내 기간으로 입력해주세요.
        </p>

        <OptionContainer>
          <LeftBox>
            <p
              style={{
                color: '#707070',
              }}
            >
              상품명 입력 :
            </p>
          </LeftBox>

          <InputTextField
            width="300px"
            label="상품 검색어를 입력해주세요."
            InputLabelProps={inputLabelPropsStyle}
            onChange={(e) => {
              setKeyword(e.target.value)
            }}
          />
        </OptionContainer>

        <OptionContainer>
          <Button
            size="large"
            bold
            borderRadius={2}
            height="40px"
            sx={{
              marginLeft: '150px',
            }}
            onClick={clickSearchBtn}
          >
            <SvgIcon component={SearchIcon} inheritViewBox />
            <p style={{ marginLeft: '10px' }}>조회하기</p>
          </Button>
        </OptionContainer>
      </Box>

      <Box sx={boxStyle}>
        <Divider sx={{ height: 30 }} />

        <TransactionTable transactions={transactions} />
        <Navigation page={page} setPage={setPage} count={count} />
      </Box>
    </DefaultLayout>
  )
}
export default TransactionPage

const boxStyle = {
  marginX: '10px',
  paddingX: '20px',
  marginTop: '10px',
  paddingTop: '16px',
  paddingBottom: '40px',
  backgroundColor: 'white',
}

export const LeftBox = ({ children }) => (
  <Box
    width={130}
    display="flex"
    justifyContent="right"
    alignItems="center"
    marginRight={3}
  >
    {children}
  </Box>
)

export const OptionContainer = ({ children }) => (
  <Box display="flex" paddingTop="20px">
    {children}
  </Box>
)

const getMonth = () => {
  const startMonth = getDateExceptDay(dayjs().startOf('month').toISOString())
  const endMonth = getDateExceptDay(dayjs().endOf('month').toISOString())

  return {
    startMonth,
    endMonth,
  }
}
