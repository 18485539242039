const SET_BUSINESS = 'SIGN/SET_BUSINESS'
const SET_BANK = 'SIGN/SET_BANK'

export const getBusinessAction = (data) => ({
  type: SET_BUSINESS,
  data,
})

export const getBankAction = (data) => ({
  type: SET_BANK,
  data,
})

const initialState = {
  business: {
    isSettled: false,
    businessType: '',
    representativeName: '',
    businessName: '',
    businessNumber: '',
    businessConditions: '',
    businessLocation: '',
    openingDate: '',
  },
  bank: {
    isSettled: false,
    bankName: '',
    accountHolder: '',
    accountNumber: '',
    accountImg: '',
  },
}

const sign = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSINESS:
      return { ...state, business: { ...action.data, isSettled: true } }

    case SET_BANK:
      return { ...state, bank: { ...action.data, isSettled: true } }

    default:
      return state
  }
}

export default sign
