import { Container } from '@mui/material'
import DefaultLayout from '../global/DefaultLayout'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import { useState } from 'react'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBusinessAction } from '../redux/reducers/sign'
import { validateBizInfo } from '../api/auth'
import { InputTextField } from '../element/InputTextField'
import RegisterHeader from '../components/register/Header'
import { ForbiddenSnackbar } from '../components/Snackbar'
import { inputLabelPropsStyle } from '../utils'

export const bizType = [
  { name: '', value: '' },
  { name: '개인사업자 (일반과세자)', value: 'INDIVIDUAL_NORMAL' },
  { name: '개인사업자 (간이과세자)', value: 'INDIVIDUAL_EASE' },
  { name: '법인사업자', value: 'CORPORATE' },
]

const RegisterBizInfo = () => {
  const navigation = useNavigate()

  const init = useSelector((state) => state.sign.business)

  const [business, setBusiness] = useState(init)

  const dispatch = useDispatch()

  const [shouldValidate, setValidate] = useState(false)
  const nextBtnHandler = async () => {
    // TODO : 뒤로갔다가 다시 요청하면 재요청

    if (!Object.values(business).every((val) => val !== '')) {
      setValidate(true)
      return
    }

    {
      const { success } = await validateBizInfo(
        business.businessNumber,
        business.openingDate,
        business.representativeName
      )

      if (!success) {
        setOnBar(true)
        return
      }
    }

    dispatch(
      getBusinessAction({
        ...business,
        businessType: bizType.find((o) => o.name === business.businessType)
          .value,
      })
    )
    navigation('/register/account')
  }

  const isIncorrectForm = (val) => {
    if (shouldValidate && val === '') {
      return { message: '필수 입력값입니다.', value: true }
    }

    return { message: '', value: false }
  }

  const isIncorrectBizInfoForm = (val) => {
    if (shouldValidate && val === '') {
      return { message: '필수 입력값입니다.', value: true }
    }

    return { message: '', value: false }
  }

  const [onBar, setOnBar] = useState(false)

  return (
    <DefaultLayout>
      <Container align="center" sx={{ marginTop: '50px' }}>
        <ForbiddenSnackbar open={onBar} setOpen={setOnBar} />
        <RegisterHeader step={1} />
        <Box
          sx={{
            width: 460,
            maxWidth: '100%',
            textAlign: 'left',
            paddingTop: 4,
          }}
        >
          <p style={{ fontWeight: 'bolder', fontSize: 18 }}>사업자 유형</p>

          <CustomDropDownField
            fullWidth
            size="small"
            disableClearable
            ListboxProps={{
              style: {
                fontSize: '14px',
              },
            }}
            value={business.businessType}
            onChange={(event, newInputValue) => {
              setBusiness({
                ...business,
                businessType: newInputValue,
              })
            }}
            options={bizType.map((o) => o.name)}
            filterOptions={(options, { inputValue }) => {
              return options.filter(
                (option) => option !== '' && option.includes(inputValue)
              )
            }}
            renderInput={(params) => (
              <TextField
                error={isIncorrectForm(business.businessType).value}
                helperText={isIncorrectForm(business.businessType).message}
                {...params}
                InputLabelProps={inputLabelPropsStyle}
                label="사업자 구분"
              />
            )}
          />
        </Box>
        <Box
          sx={{
            width: 460,
            maxWidth: '100%',
            textAlign: 'left',
            paddingTop: 4,
          }}
        >
          <p style={{ fontWeight: 'bolder', fontSize: 18 }}>사업자 정보</p>

          <InputTextField
            marginBottom="20px"
            error={isIncorrectBizInfoForm(business.representativeName).value}
            helperText={
              isIncorrectBizInfoForm(business.representativeName).message
            }
            id="name"
            label="대표자명"
            value={business.representativeName}
            onChange={({ target }) => {
              setBusiness({ ...business, representativeName: target.value })
            }}
          />
          <InputTextField
            marginBottom="20px"
            error={isIncorrectForm(business.businessName).value}
            helperText={isIncorrectForm(business.businessName).message}
            label="사업자등록증 상호명"
            value={business.businessName}
            id="biz_number"
            onChange={({ target }) => {
              setBusiness({ ...business, businessName: target.value })
            }}
          />
          <InputTextField
            marginBottom="20px"
            error={isIncorrectBizInfoForm(business.businessNumber).value}
            helperText={isIncorrectBizInfoForm(business.businessNumber).message}
            label="사업자 등록번호('-' 없이 입력)"
            value={business.businessNumber}
            id="biz_number"
            onChange={({ target }) => {
              setBusiness({ ...business, businessNumber: target.value })
            }}
          />
          <InputTextField
            marginBottom="20px"
            error={isIncorrectBizInfoForm(business.openingDate).value}
            helperText={isIncorrectBizInfoForm(business.openingDate).message}
            label="개업연원일 ex)20240101"
            value={business.openingDate}
            id="biz_number"
            onChange={({ target }) => {
              setBusiness({ ...business, openingDate: target.value })
            }}
          />
          <InputTextField
            marginBottom="20px"
            error={isIncorrectForm(business.businessConditions).value}
            helperText={isIncorrectForm(business.businessConditions).message}
            label="업태 (사업자등록증상 표기된 대표 업태)"
            value={business.businessConditions}
            id="biz_number"
            onChange={({ target }) => {
              setBusiness({ ...business, businessConditions: target.value })
            }}
          />
          <InputTextField
            marginBottom="20px"
            error={isIncorrectForm(business.businessLocation).value}
            helperText={isIncorrectForm(business.businessLocation).message}
            label="사업장 주소 (사업자등록증상 기재된 주소)"
            value={business.businessLocation}
            id="biz_number"
            onChange={({ target }) => {
              setBusiness({ ...business, businessLocation: target.value })
            }}
          />
        </Box>
        <br />
        <Box
          sx={{
            display: 'inline-block',
            width: '225px',
            paddingRight: '5px',
            paddingTop: '20px',
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            size="large"
            sx={{ color: 'black', borderColor: 'black' }}
            onClick={() => {
              navigation(-1)
            }}
          >
            이전
          </Button>
        </Box>
        <Box
          sx={{
            display: 'inline-block',
            width: '225px',
            paddingLeft: '5px',
          }}
        >
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{ backgroundColor: 'black' }}
            onClick={nextBtnHandler}
          >
            다음
          </Button>
        </Box>
        <Box sx={{ height: 100 }}></Box>
      </Container>
    </DefaultLayout>
  )
}

const CustomDropDownField = styled(Autocomplete)({
  marginTop: '10px',
  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    // 해당 input focus 되었을 때 placeholder text color
    // floating label을 사용할 때 처리 필요하다
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
})

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 2,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export default RegisterBizInfo
