import { Box, Button } from '@mui/material'

import Checkbox from '@mui/material/Checkbox'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Image from '../../element/Image'
import { priceWrapper } from '../../pages/product/addProduct'
import { SortType, getDate } from '../../utils'
import { APPROVE_STATUS_MAP } from '../snap/ProductOption'
import { ProductSort } from '../../pages/product/utils'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const heads = [
  '수정',
  '복사',
  '상품번호',
  '사진',
  '상품명',
  '판매상태',
  '승인상태',
  '판매가',
  '할인가',
  '해외판매가',
  '해외할인가',
  '할인율',
  '옵션여부',
  '리뷰개수',
  '등록일',
]

const SellStatusTable = {
  WAITING: '판매대기',
  SELLING: '판매중',
  ENDING: '판매종료',
  SOLDOUT: '품절',
}

const ProductTable = ({
  products,
  checked,
  setChecked,
  clickHandler,
  sort,
  setSort,
  page,
  setPage,
}) => {
  const buttonRef = useRef([])

  const isAllChecked = products.every((product) =>
    checked.map((o) => o.id).includes(product.id)
  )

  const navigation = useNavigate()

  const rowHandler = (e, id) => {
    for (let i = 0; i < buttonRef.current?.length; i += 1) {
      let ref = buttonRef.current[i]
      if (ref?.contains(e.target)) {
        return
      }
    }
    clickHandler(id)
  }

  return (
    <Table>
      <thead>
        <tr>
          <Th>
            <Checkbox
              checked={isAllChecked}
              onClick={() => {
                if (isAllChecked) {
                  setChecked([])
                } else {
                  setChecked([...products])
                }
              }}
            />
          </Th>
          {heads.map((head, idx) => (
            <Th key={idx}>
              {HeadItem(
                head,
                sort.sortType,
                sort.productSort,
                setSort,
                page,
                setPage
              )}
            </Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {products.map((product, index) => {
          return (
            <tr
              key={product.id}
              style={{ borderBottom: '1px solid #ddd', height: '120px' }}
              onClick={(e) => rowHandler(e, product.id)}
            >
              <Td>
                <Box textAlign={'center'}>
                  <Checkbox
                    ref={(el) => (buttonRef.current[index * 3] = el)}
                    checked={checked.map((o) => o.id).includes(product.id)}
                    onClick={(e) => {
                      if (checked.map((o) => o.id).includes(product.id)) {
                        const idx = checked.findIndex(
                          (o) => o.id === product.id
                        )
                        checked.splice(idx, 1)
                      } else {
                        checked.push(product)
                      }
                      setChecked([...checked])
                    }}
                  />
                </Box>
              </Td>
              <Td
                style={{ width: '56px' }}
                ref={(el) => (buttonRef.current[index * 3 + 1] = el)}
              >
                <Button
                  sx={{
                    width: '56px',
                    height: '36px',
                    backgroundColor: '#BACAFF',
                    color: '#003BFF',
                    fontWeight: 'bold',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: '#d9dff6',
                      boxShadow: 'none',
                    },
                  }}
                  onClick={() => {
                    navigation(`/product/add?type=modify&pid=${product.id}`)
                  }}
                >
                  <p>수정</p>
                </Button>
              </Td>

              <Td
                style={{ width: '100px' }}
                ref={(el) => (buttonRef.current[index * 3 + 2] = el)}
              >
                <Button
                  sx={{
                    width: '56px',
                    height: '36px',
                    backgroundColor: '#baffd5',
                    color: '#00a52d',
                    fontWeight: 'bold',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: '#E1FFEC',
                      boxShadow: 'none',
                    },
                  }}
                  onClick={() => {
                    navigation(`/product/add?type=create&pid=${product.id}`)
                  }}
                >
                  <p>복사</p>
                </Button>
              </Td>
              <Td>{product.productNumber}</Td>
              <Td style={{ width: '80px' }}>
                <Image
                  alt="product"
                  src={product.imgs[0]}
                  width={60}
                  height={60}
                />
              </Td>
              <Td width={250}>{product.name}</Td>
              {sellStateTd(product)}
              <Td
                style={{
                  color: APPROVE_STATUS_MAP[product.approveStatus].color,
                  fontWeight: 'bold',
                }}
              >
                {APPROVE_STATUS_MAP[product.approveStatus].name}
              </Td>
              <Td>₩{priceWrapper(product.sellingPrice)}</Td>
              <Td>₩{priceWrapper(product.discountedSellingPrice)}</Td>
              <Td>${product.price}</Td>
              <Td>${product.discountedPrice}</Td>
              <Td>{product.discountRate}%</Td>
              <Td>{product.existOption ? 'Y' : 'N'}</Td>
              <Td>{product.reviewsCnt}</Td>
              <Td>{getDate(product.createdAt)}</Td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ProductTable

const Table = styled('table')({
  width: '100%',
  minWidth: '1480px',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
  fontSize: '13px',
  color: '#707070',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '13px',
})

const P = styled('p')`
  color: red;
`

const sellStateTd = (product) => {
  if (product.approveStatus !== 'APPROVED') {
    return <Td></Td>
  }

  if (product.sellStatus !== 'WAITING') {
    return <Td>{SellStatusTable[product.sellStatus]}</Td>
  }

  return (
    <Td>
      <P style={{ fontWeight: 'bold' }}>
        {SellStatusTable[product.sellStatus]}
      </P>
      <P>(관리자 승인)</P>
    </Td>
  )
}

const HeadItem = (name, sortType, pSort, setSort, page, setPage) => {
  const sortList = [
    '상품번호',
    '판매가',
    '할인가',
    '해외판매가',
    '해외할인가',
    '할인율',
    '리뷰개수',
    '등록일',
  ]
  if (!sortList.includes(name)) {
    return name
  }

  const onClick = () => {
    let type = SortType.DESC

    if (sortType === SortType.DESC) {
      type = SortType.ASC
    }

    let pSort = convertNameToProductSort(name)

    setSort({ productSort: pSort, sortType: type })
    if (page !== 1) {
      setPage(1)
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
    >
      {name}
      <Box display="flex" flexDirection="column" gap={0}>
        <ArrowDropUpIcon
          sx={{
            marginBottom: -1,
            ...onFocusStyle(name, sortType, pSort, true),
          }}
        />
        <ArrowDropDownIcon
          sx={{ marginTop: -1, ...onFocusStyle(name, sortType, pSort, false) }}
        />
      </Box>
    </Box>
  )
}

const onFocusStyle = (name, sortType, pSort, isUp) => {
  const style = { color: '#e4e4e4' }
  if (
    pSort === convertNameToProductSort(name) &&
    ((sortType === SortType.ASC && isUp) ||
      (sortType === SortType.DESC && !isUp))
  ) {
    style['color'] = '#000000'
  }

  return style
}

const convertNameToProductSort = (name) => {
  let pSort
  switch (name) {
    case '상품번호':
      pSort = ProductSort.ProductNumber
      break
    case '판매가':
      pSort = ProductSort.ProductSellingPrice
      break
    case '할인가':
      pSort = ProductSort.ProductDiscountedSellingPrice
      break
    case '해외판매가':
      pSort = ProductSort.ProductPrice
      break
    case '해외할인가':
      pSort = ProductSort.ProductDiscountedPrice
      break
    case '리뷰개수':
      pSort = ProductSort.ProductReviewsCnt
      break
    case '등록일':
      pSort = ProductSort.ProductCreatedAt
      break
    case '할인율':
      pSort = ProductSort.ProductDiscountRate
      break
    default:
      throw new Error('error')
  }
  return pSort
}
