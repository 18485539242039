import { Box, Button, Modal, Typography } from '@mui/material'

const DeleteModal = ({ open, handleClose, clickModalBtn }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign={'center'}
        >
          <p>삭제하시겠습니까?</p>
        </Typography>

        <Box display={'flex'} justifyContent={'space-around'} marginTop={5}>
          <Box textAlign={'center'}>
            <Button
              onClick={() => {
                clickModalBtn()
              }}
              variant="contained"
              sx={{
                backgroundColor: 'red',
                width: '150px',
                height: '44px',
                margin: '3px',
                ':hover': { backgroundColor: 'red' },
              }}
            >
              삭제
            </Button>
          </Box>

          <Box textAlign={'center'}>
            <Button
              onClick={() => {
                handleClose()
              }}
              variant="contained"
              sx={{
                backgroundColor: 'black',
                width: '150px',
                height: '44px',
                margin: '3px',
                ':hover': { backgroundColor: 'black' },
              }}
            >
              <p>취소</p>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
}

export default DeleteModal
