import { instance } from './instance'

export const getOrderItems = async (
  page,
  startAt,
  endAt,
  keyword,
  innerShippingStatus,
  outerShippingStatus,
  isSettledPayment,
  isReturn
) => {
  try {
    const queryObj = {
      startAt,
      endAt,
      keyword,
      innerShippingStatus,
      outerShippingStatus,
      isSettledPayment,
      isReturn,
    }

    let query = `page=${page}`
    for (const [key, value] of Object.entries(queryObj)) {
      if (value || typeof value === 'boolean') {
        query += `&${key}=${value}`
      }
    }

    const { data } = await instance.get(`/api/order/seller?${query}`, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const setOrderInvoice = async (body) => {
  try {
    const { data } = await instance.post(`/api/order/in-invoice`, body, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const getTransactions = async (page, startAt, endAt, keyword) => {
  try {
    const queryObj = {
      startAt,
      endAt,
      keyword,
    }

    let query = `page=${page}`
    for (const [key, value] of Object.entries(queryObj)) {
      if (value || typeof value === 'boolean') {
        query += `&${key}=${value}`
      }
    }

    const { data } = await instance.get(
      `/api/order/transaction/seller?${query}`,
      {
        token: true,
      }
    )
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const getSettlement = async () => {
  try {
    const { data } = await instance.get(`/api/order/settlement-seller`, {
      token: true,
    })

    return { success: true, data: data.data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}
