import { instance } from './instance'

export const getModels = async (lastId) => {
  try {
    let url = `/api/seller/model`
    if (lastId) {
      url += `&lastId=${lastId}`
    }
    const { data } = await instance.get(url, {
      token: true,
    })
    return { success: true, data }
  } catch (error) {
    console.error(error)
    return { success: false }
  }
}

export const createModel = async (body) => {
  try {
    await instance.post(`/api/seller/model`, body, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const patchModel = async (body) => {
  try {
    await instance.patch(`/api/seller/model`, body, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}

export const removeModels = async (modelIds) => {
  try {
    let path = modelIds.reduce((acc, id) => acc + `modelIds=${id}&`, '')
    await instance.delete(`/api/seller/model?${path}`, { token: true })
    return { success: true }
  } catch (error) {
    throw error
  }
}
