import { getUsPrice } from '../pages/product/addProduct'
import { DiscountType } from '../utils'

const GENDER_MAP = {
  전체: 'ALL',
  남성: 'MEN',
  여성: 'WOMEN',
}

const SEASON_MAP = {
  봄: 1,
  여름: 2,
  가을: 3,
  겨울: 4,
}

const COUNTRY_MAP = {
  대한민국: 'KR',
  한국: 'KR',
  중국: 'CN',
  일본: 'JP',
  미국: 'US',
  영국: 'GB',
  독일: 'DE',
  프랑스: 'FR',
  캐나다: 'CA',
}

const FREE = 'FREE'

const TEXTILES = {
  LINING: { 있음: 'YES', 없음: 'NO', 기모: 'FLEECE', '': '' },
  STRETCHINESS: { 좋음: 'HIGH', 보통: 'NORMAL', 없음: 'NO', '': '' },
  TRANSPARENCY: { 있음: 'YES', 약간: 'SLIGHTLY', 없음: 'NONE', '': '' },
  TEXTURE: { 부드러움: 'SOFT', 보통: 'NORMAL', 까칠함: 'ROUGH', '': '' },
  FIT: { 오버핏: 'OVERSIZED', 정사이즈: 'REGULAR', 타이트: 'TIGHT', '': '' },
  THICKNESS: { 도톰: 'THICK', 보통: 'NORMAL', 얇음: 'THIN', '': '' },
}

export const ERROR = {
  name: {
    noname: '[상풍명] 상품명을 입력해주세요.',
    english: '[상품명] 상품명을 영어로 입력해주세요.',
  },
  gender: '[성별] 성별을 입력해주세요.',
  category: '[카테고리] 존재하지 않는 카테고리 항목입니다.',
  price: '[판매가] 입력정보가 올바르지 않습니다.',
  priceLess5000: '[판매가] 판매가격이 5000원이 넘어야합니다.',
  season: '[계절] 입력정보가 올바르지 않습니다.',
  textiles: {
    lining: '[안감] 입력정보가 올바르지 않습니다.',
    stretchiness: '[신축성] 입력정보가 올바르지 않습니다.',
    transparency: '[비침] 입력정보가 올바르지 않습니다.',
    texture: '[촉감] 입력정보가 올바르지 않습니다.',
    fit: '[핏] 입력정보가 올바르지 않습니다. ',
    thickness: '[두께] 입력정보가 올바르지 않습니다. ',
  },
  materialMixingRate: '[소재혼용률] 입력정보가 올바르지 않습니다.',
  hsCode: '[HS코드] 잘못된 형식입니다. - 숫자 10자리',
  country: '[원산지] 입력정보가 올바르지 않습니다. ',
  careInstruction: '[취급시 주의사항] 입력정보가 올바르지 않습니다.',
  description: '[상세 정보] 입력정보가 올바르지 않습니다.',
  existOption: '[컬러/사이즈 등록 여부] 입력정보가 올바르지 않습니다.',
  size: '[사이즈] 잘못된 형식입니다.',
  count: {
    option:
      '[옵션 재고수량] 올바른 입력형태가 아닙니다. (컬러 개수 x 사이즈 개수의 옵션별 재고수량 입력) ',
    normal: '[재고수량] 입력정보가 올바르지 않습니다. ',
  },
  moreInfo: '[소재정보 추가여부] 입력정보가 올바르지 않습니다.',
  sizeGuide: '[사이즈가이드] 입력정보가 올바르지 않습니다.',
  color: '[컬러] 잘못된 형식입니다',
  image: {
    no: '[이미지] 대표 이미지가 없습니다.',
    noEnter: '[이미지] 이미지가 엔터로 구분되지 않았습니다.',
    duplicatedEnter: '[이미지] 엔터가 중복되었습니다.',
    exp: '[이미지] 이미지 이름이 올바르지 않습니다.',
  },
  moreImage: {
    no: '[추가이미지] 대표 이미지가 없습니다.',
    duplicatedEnter: '[이미지] 엔터가 중복되었습니다.',
    noEnter: '[추가이미지] 이미지가 엔터로 구분되지 않았습니다.',
    exp: '[추가이미지] 이미지 이름이 올바르지 않습니다.',
  },
  sizeColor: {
    y: '[컬러/사이즈 등록 여부] 등록 여부 Y 선택 시 컬러와 사이즈 필드에 값을 입력 해 주세요.',
    n: '[컬러/사이즈 등록 여부] 등록 여부 N 선택 시 컬러와 사이즈는 공란으로 비워주세요.',
  },
  discount: {
    type: '[상품할인 단위] % 또는 원이 아닙니다.',
    noType: '[상품할인 값, 상품할인 단위] 상품할인 단위가 없습니다.',
    invalidPrice: '[상품할인 값] 숫자가 아닙니다.',
    noPrice: '[상품할인 값] 상품할인 값이 없습니다.',
    invalidWonPrice:
      '[상품할인 값] 10원부터 판매가 사이의 가격을 설정해야 합니다.',
    invalidPercentPrice: '[상품할인 값] 11 ~ 100의 값으로 설정해야 합니다.',
    priceLess5000: '[상품할인 값] 상품할인 값이 5000원이 넘어야 합니다.',
  },
  custom: {
    option: '[주문제작 상품] 주문제작 상품 여부를 입력해 주세요.',
    date: '[주문제작 소요일 수] 올바른 값인지 확인해 주세요.',
  },
}

const PRODUCT_NAME_REGEXP = new RegExp(
  /^$|^[A-Za-z\s,.0-9\-!@#$%^&&*()_=+’“;:/\[\]]+$/
)
const HSCODE_REGEXP = new RegExp(/^\d{10}$/)
const MORE_INFO_REGEXP = new RegExp(/^(Y|N)$/)
const HTTP_CHECK_REGEXP = new RegExp(/http:\/\//g)
const HTTPS_CHECK_REGEXP = new RegExp(/https:\/\//g)
const DISCOUNT_TYPE_REGEXP = new RegExp(/^[원|%]$/)

const CATEGORY = {
  // 아우터: 1
  5: { name: '가디건', guide: ['A', 'B', 'C', 'D', 'E', 'F'] },
  6: { name: '코트', guide: ['A', 'B', 'C', 'D', 'E', 'F'] },
  7: { name: '자켓', guide: ['A', 'B', 'C', 'D', 'E', 'F'] },
  8: { name: '패딩', guide: ['A', 'B', 'C', 'D', 'E', 'F'] },
  9: { name: '조끼', guide: ['A', 'B', 'C', 'E'] },

  // 상의: 2
  10: { name: '티셔츠(롱슬리브)', guide: ['A', 'B', 'C', 'D', 'E', 'F'] },
  11: { name: '티셔츠(숏슬리브)', guide: ['A', 'B', 'C', 'D', 'E', 'F'] },
  12: { name: '티셔츠(터틀넥)', guide: ['A', 'B', 'C', 'D', 'E', 'F', 'G'] },
  13: { name: '티셔츠(민소매)', guide: ['A', 'B', 'C', 'D'] },
  14: { name: '셔츠', guide: ['A', 'B', 'C', 'D', 'E', 'F'] },
  38: { name: '비키니', guide: ['A', 'B', 'C'] },

  // 원피스: 3
  15: { name: '반팔', guide: ['A', 'B', 'C', 'D', 'E', 'F', 'G'] },
  16: { name: '긴팔', guide: ['A', 'B', 'C', 'D', 'E', 'F', 'G'] },
  17: { name: '민소매', guide: ['A', 'B', 'C', 'D', 'E'] },
  18: { name: '터틀넥', guide: ['A', 'B', 'C', 'D', 'E', 'F'] },

  // 하의: 4
  19: { name: '긴바지', guide: ['A', 'B', 'C', 'D', 'E'] },
  20: { name: '반바지', guide: ['A', 'B', 'C', 'D', 'E'] },
  21: { name: '오버롤/멜빵', guide: ['A', 'B', 'C', 'D', 'E', 'F'] },
  22: { name: '스커트', guide: ['A', 'B', 'C'] },
  39: { name: '비키니', guide: ['A', 'B'] },

  // 셋업의류: 23
  26: {
    name: '수트',
    guide: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
  },
  27: {
    name: '트레이닝',
    guide: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
  },
  37: {
    name: '비키니',
    guide: ['A', 'B', 'C', 'D', 'E'],
  },

  // 점프수트 & 모노키니: 40
  41: { name: '점프수트', guide: ['A', 'B', 'C', 'D', 'E', 'F'] },
  42: { name: '모니키니', guide: ['A', 'B', 'C', 'D', 'E', 'F', 'G'] },

  // 액세서리: 24
  28: { name: '볼캡', guide: ['A', 'B', 'C'] },
  29: { name: '비니', guide: ['A', 'B'] },
  30: { name: '페도라/버킷', guide: ['A', 'B'] },

  // 가방: 25
  31: { name: '미니백', guide: ['A', 'B', 'C'] },
  32: { name: '백팩', guide: ['A', 'B', 'C'] },
  33: { name: '메신저백/크로스백', guide: ['A', 'B', 'C', 'D'] },
  34: { name: '토트백', guide: ['A', 'B', 'C'] },
  35: { name: '클러치', guide: ['A', 'B', 'C'] },
  36: { name: '더플백', guide: ['A', 'B', 'C'] },
}

// error 여러개 띄우려면...

export class PRODUCT {
  getProductByExcelJson = (json, categories) => {
    let productJson = {}

    try {
      productJson.name = this.getProductName(json)
      productJson.gender = this.getProductGender(json)
      productJson.hsCode = this.getProductHSCode(json)
      productJson.materialMixingRate = this.getProductMaterialMixingRate(json)
      productJson.originCountry = this.getProductOriginCountry(json)
      productJson.careInstruction = this.getProductCareInstruction(json)
      productJson.description = this.getProductDescription(json)
      productJson.imgs = this.getProductImgs(json)
      productJson.moreImgs = this.getProductMoreImgs(json)
      productJson.customOrderDays = this.getIsOrderProduct(json)

      const checkMoreInfo = this.getProductMoreInfo(json)
      const seasonIds = this.getProductSeasonIds(json)
      const textiles = this.getProductTextiles(json)
      this.checkProductMoreInfo(checkMoreInfo, seasonIds, textiles)
      productJson.seasonIds = seasonIds
      productJson.textiles = textiles

      const categoryBig = this.getProductCategoryBig(json, categories)
      productJson.categoryBig = categoryBig
      const { children: list } = categoryBig
      const category = this.getProductCategoryMid(json, list)
      productJson.category = category
      productJson.categoryId = category.id
      productJson.sizeGuideImgs = [category.img]

      // selling price, setting price product 179 ~ 180
      const price = this.getProductPrice(json)

      // !!!! TEST 서로 바꿔주세요.
      // productJson.price = price
      productJson.price = getUsPrice(`${price}`)
      productJson.sellingPrice = price

      const existOption = this.getProductExistOption(json)
      const size = this.getProductSize(json, existOption)
      const color = this.getProductColor(json, existOption)
      const { count, sum } = this.getProductCount(json, existOption)

      productJson.existOption = existOption
      productJson.sizeGuide = this.getProductSizeGuide(json, size, category)
      productJson.option = this.getProductOption(
        color,
        size,
        count,
        existOption
      )
      productJson.count = sum
      productJson.discountType = this.getDiscountType(json)
      productJson.discountAmount = this.getDiscountAmount(json)

      productJson.status = '성공'

      return productJson
    } catch (error) {
      return {
        status: '실패',
        reason: error.message,
        ...productJson,
      }
    }
  }

  getIsOrderProduct = (json) => {
    const isOrder = json['주문제작 상품']
    if (!isOrder) throw Error(ERROR.custom.option)
    if (isOrder === 'N') return 0

    const date = json['주문제작 소요일 수'].replaceAll('일', '')
    try {
      return parseInt(date)
    } catch (error) {
      throw Error(ERROR.custom.date)
    }
    // TODO
  }

  getProductCategoryBig = (json, categories) => {
    const categoryName = json['카테고리(대)']
    if (!categoryName) throw Error(ERROR.category)

    const categoryBig = categories.filter(
      (c) => c.korName.replaceAll(' ', '') === categoryName.replaceAll(' ', '')
    )[0]
    if (!categoryBig) throw Error(ERROR.category)

    return categoryBig
  }

  getProductCategoryMid = (json, categories) => {
    const categoryName = json['카테고리(중)']
    if (!categoryName) throw Error(ERROR.category)

    const categoryMid = categories.filter(
      (c) => c.korName.replaceAll(' ', '') === categoryName.replaceAll(' ', '')
    )[0]
    if (!categoryMid) throw Error(ERROR.category)

    return categoryMid
  }

  getProductGender = (json) => {
    const key = json['성별']
    if (!key) throw Error(ERROR.gender)

    const gender = GENDER_MAP[key]
    if (!gender) throw Error(ERROR.gender)

    return gender
  }

  getProductMoreInfo = (json) => {
    const moreInfo = json['소재정보 추가여부']
    if (!MORE_INFO_REGEXP.test(moreInfo)) throw Error(ERROR.moreInfo)
    return moreInfo
  }

  getProductSeasonIds = (json) => {
    let keys = json['계절']
    if (!keys) keys = []
    else if (!keys.includes(',')) keys = [keys]
    else keys = keys.split(',')

    const seasonId = keys.map((key) => {
      const id = SEASON_MAP[key.trim()]
      if (!id) throw Error(ERROR.season)
      return id
    })

    return seasonId
  }

  getProductTextiles = (json) => {
    const lining = TEXTILES.LINING[json['안감'] ?? '']
    if (typeof lining === 'undefined') throw Error(ERROR.textiles.lining)

    const stretchiness = TEXTILES.STRETCHINESS[json['신축성'] ?? '']
    if (typeof stretchiness === 'undefined')
      throw Error(ERROR.textiles.stretchiness)

    const transparency = TEXTILES.TRANSPARENCY[json['비침'] ?? '']
    if (typeof transparency === 'undefined')
      throw Error(ERROR.textiles.transparency)

    const texture = TEXTILES.TEXTURE[json['촉감'] ?? '']
    if (typeof texture === 'undefined') throw Error(ERROR.textiles.texture)

    const fit = TEXTILES.FIT[json['핏'] ?? '']
    if (typeof fit === 'undefined') throw Error(ERROR.textiles.fit)

    const thickness = TEXTILES.THICKNESS[json['두께'] ?? '']
    if (typeof thickness === 'undefined') throw Error(ERROR.textiles.thickness)

    const textiles = []
    if (lining) textiles.push(`Lining:${lining}`)
    if (stretchiness) textiles.push(`Stretchiness:${stretchiness}`)
    if (transparency) textiles.push(`Transparency:${transparency}`)
    if (texture) textiles.push(`Texture:${texture}`)
    if (fit) textiles.push(`Fit:${fit}`)
    if (thickness) textiles.push(`Thickness:${thickness}`)

    return textiles
  }

  checkProductMoreInfo = (checkMoreInfo, seasonIds, textiles) => {
    const length = seasonIds.length + textiles.length
    if (checkMoreInfo === 'Y' && !length) throw Error(ERROR.moreInfo)
    if (checkMoreInfo === 'N' && length) throw Error(ERROR.moreInfo)
  }

  getProductName = (json) => {
    const name = json['상품명']
    if (!name) throw Error(ERROR.name.noname)
    if (!PRODUCT_NAME_REGEXP.test(name)) throw Error(ERROR.name.english)

    return name
  }

  getProductHSCode = (json) => {
    const hsCode = json['HS코드']
    if (!HSCODE_REGEXP.test(hsCode)) throw Error(ERROR.hsCode)

    return `${hsCode}`
  }

  getProductMaterialMixingRate = (json) => {
    const materialMixingRate = json['소재혼용률']
    if (!materialMixingRate) throw Error(ERROR.materialMixingRate)

    return materialMixingRate
  }

  getProductPrice = (json) => {
    const price = json['판매가']
    if (typeof price !== 'number') throw Error(ERROR.price)
    if (price % 10) throw Error(ERROR.price)
    if (price < 5000) throw Error(ERROR.priceLess5000)

    return price
  }

  getProductOriginCountry = (json) => {
    const key = json['원산지']
    const country = COUNTRY_MAP[key]
    if (!country) throw Error(ERROR.country)

    return country
  }

  getProductCareInstruction = (json) => {
    const value = json['취급시 주의사항']
    if (!value) throw Error(ERROR.careInstruction)
    if (value.length < 1) throw Error(ERROR.careInstruction)
    if (value.length > 1000) throw Error(ERROR.careInstruction)

    return `${value}`
  }

  getProductDescription = (json) => {
    let value = json['상세 정보']
    if (!value) throw Error(ERROR.description)
    if (value.length < 1) throw Error(ERROR.description)
    if (value.length > 2000) throw Error(ERROR.description)

    return `${value}`
  }

  getProductExistOption = (json) => {
    let option = json['컬러/사이즈 등록 여부']

    switch (option) {
      case 'Y':
        option = true
        break
      case 'N':
        option = false
        break
      default:
        throw Error(ERROR.existOption)
    }

    return option
  }

  getProductSize = (json, existOption) => {
    let sizes = json['사이즈']

    if (typeof sizes === 'undefined') sizes = []
    else if (!`${sizes}`.includes(',')) sizes = [`${sizes}`]
    else sizes = sizes.split(',').map((size) => size.trim().toUpperCase())

    if (sizes.length === 0 && existOption) throw Error(ERROR.sizeColor.y)
    if (sizes.length !== 0 && !existOption) throw Error(ERROR.sizeColor.n)

    return sizes
  }

  getProductSizeGuide = (json, size, category) => {
    const A = this.getProductSizeGuideAlphabet(json, 'A')
    const B = this.getProductSizeGuideAlphabet(json, 'B')
    const C = this.getProductSizeGuideAlphabet(json, 'C')
    const D = this.getProductSizeGuideAlphabet(json, 'D')
    const E = this.getProductSizeGuideAlphabet(json, 'E')
    const F = this.getProductSizeGuideAlphabet(json, 'F')
    const G = this.getProductSizeGuideAlphabet(json, 'G')
    const H = this.getProductSizeGuideAlphabet(json, 'H')
    const I = this.getProductSizeGuideAlphabet(json, 'I')
    const J = this.getProductSizeGuideAlphabet(json, 'J')
    const K = this.getProductSizeGuideAlphabet(json, 'K')

    const GUIDE = CATEGORY[category.id].guide

    size = size.length === 0 ? [FREE] : size
    const sizeGuide = []

    const loop = size.length
    if (A.length !== loop) throw Error(ERROR.sizeGuide)

    for (let i = 0; i < loop; i += 1) {
      const temp = []
      temp.push(`Size:${size[i]}`)
      temp.push(`A:${A[i]}`)
      if (B[i] && GUIDE.includes('B')) temp.push(`B:${B[i]}`)
      else temp.push('B:')
      if (C[i] && GUIDE.includes('C')) temp.push(`C:${C[i]}`)
      else temp.push('C:')
      if (D[i] && GUIDE.includes('D')) temp.push(`D:${D[i]}`)
      else temp.push('D:')
      if (E[i] && GUIDE.includes('E')) temp.push(`E:${E[i]}`)
      else temp.push('E:')
      if (F[i] && GUIDE.includes('F')) temp.push(`F:${F[i]}`)
      else temp.push('F:')
      if (G[i] && GUIDE.includes('G')) temp.push(`G:${G[i]}`)
      else temp.push('G:')
      if (H[i] && GUIDE.includes('H')) temp.push(`H:${H[i]}`)
      else temp.push('H:')
      if (I[i] && GUIDE.includes('I')) temp.push(`I:${I[i]}`)
      else temp.push('I:')
      if (J[i] && GUIDE.includes('J')) temp.push(`J:${J[i]}`)
      else temp.push('J:')
      if (K[i] && GUIDE.includes('K')) temp.push(`K:${K[i]}`)
      else temp.push('K:')
      sizeGuide.push(temp)
    }
    sizeGuide.push(
      'Measurement are made with the garment laid flat. (The size may differ by 1~3cm)'
    )

    return sizeGuide
  }

  getProductSizeGuideAlphabet = (json, alphabet) => {
    let guide = json[alphabet]

    if (typeof guide === 'undefined') guide = []
    else if (typeof guide === 'number') guide = [guide]
    else guide = guide.split(',').map((g) => g.trim())

    guide = guide.filter((s) => typeof parseFloat(s) === 'number')

    return guide
  }

  checkImage = (img, type) => {
    const checkHTTP = img.match(HTTP_CHECK_REGEXP)?.length ?? 0
    const checkHTTPS = img.match(HTTPS_CHECK_REGEXP)?.length ?? 0

    if (img === '') {
      throw new Error(
        type === 'normal'
          ? ERROR.image.duplicatedEnter
          : ERROR.moreImage.duplicatedEnter
      )
    }
    if (checkHTTP + checkHTTPS > 1) {
      throw new Error(
        type === 'normal' ? ERROR.image.noEnter : ERROR.moreImage.noEnter
      )
    }
    if (img.includes(',')) {
      throw new Error(
        type === 'normal' ? ERROR.image.noEnter : ERROR.moreImage.noEnter
      )
    }
  }

  getProductImgs = (json) => {
    let imgs = json['대표이미지']

    if (!imgs) imgs = []
    else if (!imgs.includes('\n')) imgs = [imgs.trim()]
    else imgs = imgs.split('\n').map((img) => img.trim())

    const domain = ['dev', 'local'].includes(process.env.REACT_APP_ENV)
      ? 'bysnap'
      : 'by-snap'

    imgs = imgs.map((img) => {
      this.checkImage(img, 'normal')
      if (img.includes('http')) {
        return encodeURI(img)
      } else if (img.includes('.jpg') || img.includes('.jpeg')) {
        return `https://${domain}.s3.amazonaws.com/product/${img}`
      } else throw new Error(ERROR.image.exp)
    })

    if (!imgs.length) throw Error(ERROR.image.no)

    return imgs
  }

  getProductMoreImgs = (json) => {
    let imgs = json['추가이미지']

    if (!imgs) imgs = []
    else if (!imgs.includes('\n')) imgs = [imgs.trim()]
    else imgs = imgs.split('\n').map((img) => img.trim())

    const domain = ['dev', 'local'].includes(process.env.REACT_APP_ENV)
      ? 'bysnap'
      : 'by-snap'

    imgs = imgs.map((img) => {
      this.checkImage(img, 'more')
      if (img.includes('http')) return encodeURI(img)
      else if (img.includes('.jpg') || img.includes('.jpeg')) {
        return `https://${domain}.s3.amazonaws.com/product/${img}`
      } else throw new Error(ERROR.moreImage.exp)
    })

    if (!imgs.length) throw Error(ERROR.moreImage.no)

    return imgs
  }

  getProductColor = (json, existOption) => {
    let color = json['컬러']
    if (!color) color = []
    else if (!color.includes(',')) color = [color.trim().toUpperCase()]
    else color = color.split(',').map((c) => c.trim().toUpperCase())

    if (color.length === 0 && existOption) throw Error(ERROR.sizeColor.y)
    if (color.length !== 0 && !existOption) throw Error(ERROR.sizeColor.n)

    return color
  }

  getProductCount = (json, existOption) => {
    const key = existOption ? '옵션 재고수량' : '재고수량'
    let count = json[key]
    if (typeof count === 'undefined')
      throw Error(
        key === '옵션 재고수량' ? ERROR.count.option : ERROR.count.normal
      )
    if (typeof count === 'number')
      count = [count].map((c) => {
        if (c !== parseInt(c)) throw Error(ERROR.count.normal)
        return c
      })
    else
      count = count.split(',').map((c) => {
        const parsingCount = parseInt(c.trim())
        if (parsingCount !== c - 0) throw Error(ERROR.count.option)
        return parsingCount
      })

    const sum = count.reduce((a, b) => a + b, 0)
    return { count, sum }
  }

  getProductOption = (color, size, count, existOption) => {
    if (existOption && (color.length === 0 || size.length === 0)) {
      throw Error(ERROR.count.option)
    }
    if (!existOption && (color.length !== 0 || size.length !== 0)) {
      throw Error(ERROR.count.option)
    }
    if (!existOption) {
      size = [FREE]
    }

    let options = this.getProductOptionOptions(color, size)
    let inventoryCounts = this.getProductOptionInventoryCounts(
      color,
      size,
      count,
      existOption
    )

    return { options, inventoryCounts }
  }

  getProductOptionOptions = (color, size) => {
    let colorOption = {
      type: 'color',
      list: color,
    }
    let sizeOption = {
      type: 'size',
      list: size,
    }
    let options = []
    if (colorOption.list.length !== 0) options.push(colorOption)
    options.push(sizeOption)

    return options
  }

  getProductOptionInventoryCounts = (color, size, count, existOption) => {
    const inventoryCounts = []
    if (!existOption) {
      const inventory = {
        index: [0],
        count: count[0],
        discount: 0,
      }
      inventoryCounts.push(inventory)
    } else {
      for (let i = 0; i < color.length; i += 1) {
        for (let j = 0; j < size.length; j += 1) {
          const currentCount = count[i * size.length + j]
          if (typeof currentCount === 'undefined') {
            throw Error(ERROR.count.option)
          }

          const inventory = {
            index: [i, j],
            count: currentCount,
            discount: 0,
          }
          inventoryCounts.push(inventory)
        }
      }
    }

    return inventoryCounts
  }

  getDiscountType = (json) => {
    let discountType = json['상품할인 단위']
    if (!discountType) return DiscountType.None

    if (!DISCOUNT_TYPE_REGEXP.test(discountType)) {
      throw new Error(ERROR.discount.type)
    }

    switch (discountType) {
      case '원':
        return DiscountType.Absolute
      case '%':
        return DiscountType.Percentage
      default:
        return DiscountType.None
    }
  }

  getDiscountAmount = (json) => {
    // 할인가 5000원 이상!
    let discountPrice = json['상품할인 값']
    let discountType = this.getDiscountType(json)
    let maxPrice = this.getProductPrice(json)

    if (discountType === DiscountType.None && discountPrice) {
      throw new Error(ERROR.discount.noType)
    }
    if (discountType !== DiscountType.None && !discountPrice) {
      throw new Error(ERROR.discount.noPrice)
    }

    if (
      typeof discountPrice !== 'undefined' &&
      typeof discountPrice !== 'number'
    ) {
      throw new Error(ERROR.discount.invalidPrice)
    }

    switch (discountType) {
      case DiscountType.Absolute:
        if (discountPrice < 10 || discountPrice >= maxPrice) {
          throw new Error(ERROR.discount.invalidWonPrice)
        }
        if (discountPrice < 5000) {
          throw new Error(ERROR.discount.priceLess5000)
        }
        break
      case DiscountType.Percentage:
        if (discountPrice < 1 || discountPrice > 99) {
          throw new Error(ERROR.discount.invalidPercentPrice)
        }
        if (maxPrice * (discountPrice / 100) < 5000) {
          throw new Error(ERROR.discount.priceLess5000)
        }
        break
      default:
        discountPrice = 0
        break
    }
    return discountPrice
  }
}
