const SET_HISTORY = 'HISTORY/SET_HISTORY'

class Stack {
  data = []

  peek = () => {
    if (this.data.length > 0) {
      return this.data[this.data.length - 1]
    }
    return null
  }
  pop = () => {
    return this.data.pop()
  }
  push = (data) => {
    return this.data.push(data)
  }
}

export const getHistoryAction = (history) => ({
  type: SET_HISTORY,
  history,
})

const initialState = {
  stack: new Stack(),
}

const history = (state = initialState, action) => {
  switch (action.type) {
    case SET_HISTORY:
      return action.history

    default:
      return state
  }
}

export default history
