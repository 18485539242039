import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import { Button } from '../../../element/Button'
import Image from '../../../element/Image'
import useLogic from './logic'

const AddSetUpProduct = ({
  relatedProductIds,
  updateRelatedProduct,
  removeRelatedProduct,
}) => {
  const { ref, value, handler } = useLogic()

  return (
    <Stack direction="column">
      <Stack direction="row" gap={1}>
        {relatedProductIds.map((product, index) => (
          <Image
            key={`select_set_up_product_${product}_${index}`}
            src={product.imgs[0]}
            width={100}
            height={100}
            onClick={removeRelatedProduct(index)}
          />
        ))}
      </Stack>
      <Box height={20} />
      <Stack
        direction="column"
        paddingX={3}
        paddingY={2}
        bgcolor="#f2f2f2"
        gap={2}
        width={720}
      >
        <Typography fontSize={14} color="#707070">
          검색
        </Typography>
        <Stack direction="row" gap={1}>
          <TextField
            variant="outlined"
            placeholder="상품번호 또는 상품명 입력"
            sx={{ bgcolor: '#ffffff', width: 260 }}
            value={value.keyword}
            onChange={handler.keyword}
          />
          <Button onClick={handler.search}>검색</Button>
        </Stack>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table bgcolor="#ffffff" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell width={300} align="center">
                  상품번호
                </TableCell>
                <TableCell width={300} align="center">
                  상품명
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {value.products.map((product, index) => (
                <TableRow key={`search_set_up_product_${index}`}>
                  <TableCell align="center">
                    <Image
                      key={index}
                      src={product.imgs[0]}
                      width={60}
                      height={60}
                    />
                  </TableCell>
                  <TableCell align="center">{product.productNumber}</TableCell>
                  <TableCell align="center">{product.name}</TableCell>
                  <TableCell align="center">
                    <Button
                      color="#003bff"
                      bg="#bacaff"
                      bold
                      onClick={updateRelatedProduct(product)}
                    >
                      추가
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {value.hasNext && (
                <TableRow ref={ref.end}>
                  <TableCell colSpan={4} align="center">
                    <CircularProgress size={20} sx={{ color: '#707070' }} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  )
}

export default AddSetUpProduct
