import { Box, Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'

import { MENU_LIST } from './Navigation'
import NoticeSlider from './NoticeSlider'

const getTagTitle = (outerPath) => {
  if (outerPath.includes('/notice')) {
    return '공지사항'
  }

  let ret = ''
  MENU_LIST.forEach(({ list }) =>
    list.forEach(({ name, path }) => {
      if (path === outerPath) {
        ret = name
        return
      }
      if (path !== outerPath && outerPath.includes(path)) {
        ret = name
        return
      }
    })
  )
  return ret
}

const getPath = (path) => {
  switch (path) {
    case '/snap':
      return { path: '/snap/add', existBtn: true, btnText: '스냅등록' }
    case '/product':
      return { path: '/product/add', existBtn: true, btnText: '상품등록' }
    case '/stylist/model':
      return { path: '/stylist/model/add', existBtn: true, btnText: '모델등록' }
    case '/stylist/info':
      return { path: '/stylist/info', existBtn: true, btnText: '저장하기' }
    case '/sell/stock':
      return { path: '/sell/stock', existBtn: true, btnText: '저장하기' }
    default:
      return { path: '', existBtn: false, btnText: '' }
  }
}

const SubHeader = ({ onClickSubHeaderBtn }) => {
  const navigation = useNavigate()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const pid = searchParams.get('pid')
  const type = searchParams.get('type')
  const url =
    pid && type === 'modify' ? location.pathname + `/${pid}` : location.pathname
  const { path, existBtn, btnText } = getPath(location.pathname)

  const isNoticeTitle = location.pathname.includes('/dashboard')

  return (
    <Box
      sx={{
        height: '60px',
        backgroundColor: '#ffffff',
        paddingX: '20px',
        paddingY: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        zIndex: 100,
        top: 50,
        left: 200,
        width: 'calc(100% - 200px)',
        overflowX: 'scroll',
        boxSizing: 'border-box',
      }}
    >
      {isNoticeTitle ? (
        <>
          <NoticeSlider />
        </>
      ) : (
        <>
          <Box>
            <Box textAlign={'left'}>
              <p
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  color: 'black',
                }}
              >
                {getTagTitle(url)}
              </p>
            </Box>
          </Box>
          {existBtn && (
            <Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'black',
                  position: 'fixed',
                  zIndex: 100,
                  top: 60,
                  right: 30,
                  width: '100px',
                  ':hover': { backgroundColor: '#555755' },
                }}
                onClick={() => {
                  if (onClickSubHeaderBtn) {
                    onClickSubHeaderBtn()
                  } else {
                    navigation(path)
                  }
                }}
              >
                <p>{btnText}</p>
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  )
}

export default SubHeader
