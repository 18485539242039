import { Modal, Stack, Typography } from '@mui/material'
import { Button } from '../../element/Button'

const AddProductSuccessModal = ({ open, onClose, goAddSnap }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          height: 250,
          bgcolor: 'white',
          boxSizing: 'border-box',
          padding: '30px',
          borderRadius: '20px',
          gap: '20px',
        }}
      >
        <Typography color="#707070" fontWeight={700} fontSize={20}>
          상품 등록 완료!
        </Typography>
        <div>
          <Typography textAlign="center">
            상품 등록이 완료되었습니다.
          </Typography>
          <Typography textAlign="center">
            스냅 게시물 등록 페이지로 이동하시겠습니까?
          </Typography>
        </div>
        <Stack direction="row" gap="10px" width="100%">
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'black',
              width: '210px',
              height: '50px',
              ':hover': { backgroundColor: '#555755' },
            }}
            onClick={() => {
              goAddSnap()
            }}
          >
            <Typography fontWeight={700}>스냅 등록 페이지로 이동</Typography>
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#e4e4e4',
              width: '210px',
              height: '50px',
              ':hover': { backgroundColor: '#eeeeee' },
            }}
            onClick={() => {
              onClose()
            }}
          >
            <Typography color="#5d5d5d" fontWeight={700}>
              상품 목록 페이지로 이동
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default AddProductSuccessModal
