import { Box } from '@mui/material'
import Image from '../../element/Image'

export const APPROVE_STATUS_MAP = {
  REQUEST: {
    name: '승인요청',
    color: 'blue',
  },
  WAITING: {
    name: '승인대기',
    color: 'black',
  },
  RE_WAITING: {
    name: '재승인대기',
    color: 'green',
  },
  APPROVED: {
    name: '승인완료',
    color: 'green',
  },
  REJECT: {
    name: '승인반려',
    color: 'red',
  },
}

const ProductOption = ({ product }) => {
  return (
    <Box
      paddingX={2}
      paddingY={1}
      gap={1}
      bgcolor={'#ffffff'}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box>
        <Image src={product.imgs[0]} alt="" width={60} height={60} />
      </Box>
      <Box>
        <p
          style={{
            fontSize: 14,
            color: APPROVE_STATUS_MAP[product.approveStatus].color,
          }}
        >
          {APPROVE_STATUS_MAP[product.approveStatus].name}
        </p>
        <p style={{ fontSize: 14 }}>{product.name}</p>
        <Box sx={{ height: '4px' }} />
        <p style={{ fontWeight: 700 }}>${product.price}</p>
      </Box>
    </Box>
  )
}

export default ProductOption
