import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import DefaultLayout from '../../global/DefaultLayout'
import Autocomplete from '@mui/material/Autocomplete'
import { useState } from 'react'
import { createModel } from '../../api/model'
import { useNavigate } from 'react-router-dom'
import { InputTextField } from '../../element/InputTextField'
import { inputLabelPropsStyle } from '../../utils'

const genders = [
  { name: '', value: '' },
  { name: '남자', value: 'MALE' },
  { name: '여자', value: 'FEMALE' },
]

const isValidNum = (input) => {
  return /^\d+$/.test(input)
}

const StylistAddModelPage = () => {
  const navigation = useNavigate()
  const [modelInfo, setModelInfo] = useState({
    name: '',
    gender: '',
    height: '',
    weight: '',
    isRepresentative: false,
  })

  const [shouldValidate, setValidate] = useState(false)

  const onClickStoreModel = async () => {
    if (Object.values(modelInfo).some((o) => o === '')) {
      setValidate(true)

      return
    }

    if (!isValidNum(modelInfo.weight) || !isValidNum(modelInfo.height)) {
      setValidate(true)
      return
    }

    const { success } = await createModel({
      ...modelInfo,
      sex: genders.find((g) => g.name === modelInfo.gender).value,
    })
    if (success) {
      navigation('/stylist/model?pop=true')
    }
  }

  const isIncorrectForm = (val) => {
    if (shouldValidate && val === '') {
      return {
        message: '필수 입력값입니다.',
        value: true,
        intMessage: '1단위 숫자(정수)로 입력해주세요.',
        modelMessage: '모델명은 영문으로 입력해주세요. (최대 40자)',
      }
    }
    return { message: '', value: false, intMessage: '', modelMessage: '' }
  }

  return (
    <DefaultLayout isLoggedIn>
      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Box>
          <Grid
            container
            spacing={4}
            justify="flex-end"
            alignItems="center"
            sx={{ marginTop: 0 }}
          >
            <Grid item xs={1.6} sx={{ display: 'flex' }}>
              <p
                style={{
                  fontSize: '14px',
                  color: '#707070',
                  fontWeight: 'bold',
                }}
              >
                모델 이름
              </p>
              <p
                style={{
                  fontSize: '14px',
                  color: '#FF0000',
                  fontWeight: 'bold',
                  marginLeft: 2,
                }}
              >
                *
              </p>
            </Grid>
            <Grid item xs={10}>
              <InputTextField
                width="300px"
                error={isIncorrectForm(modelInfo.name).value}
                helperText={`${isIncorrectForm(modelInfo.name).message}${
                  isIncorrectForm(modelInfo.name).modelMessage
                }`}
                label="모델 이름"
                value={modelInfo.name}
                onChange={(e) => {
                  setModelInfo({ ...modelInfo, name: e.target.value })
                }}
              />
            </Grid>
            <Grid item xs={1.6} sx={{ display: 'flex' }}>
              <p
                style={{
                  fontSize: '14px',
                  color: '#707070',
                  fontWeight: 'bold',
                }}
              >
                성별
              </p>
              <p
                style={{
                  fontSize: '14px',
                  color: '#FF0000',
                  fontWeight: 'bold',
                  marginLeft: 2,
                }}
              >
                *
              </p>
            </Grid>
            <Grid item xs={10}>
              <CustomDropDownField
                fullWidth
                disableClearable
                size="small"
                value={modelInfo.gender}
                onChange={(e, input) => {
                  setModelInfo({
                    ...modelInfo,
                    gender: input,
                  })
                }}
                ListboxProps={{
                  style: {
                    fontSize: '14px',
                  },
                }}
                id="gender_option"
                options={genders.map((g) => g.name)}
                filterOptions={(options, { inputValue }) => {
                  return options.filter(
                    (option) => option !== '' && option.includes(inputValue)
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={isIncorrectForm(modelInfo.gender).value}
                    helperText={isIncorrectForm(modelInfo.gender).message}
                    InputLabelProps={inputLabelPropsStyle}
                    label="성별"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1.6} sx={{ display: 'flex' }}>
              <p
                style={{
                  fontSize: '14px',
                  color: '#707070',
                  fontWeight: 'bold',
                }}
              >
                키
              </p>
              <p
                style={{
                  fontSize: '14px',
                  color: '#FF0000',
                  fontWeight: 'bold',
                  marginLeft: 2,
                }}
              >
                *
              </p>
            </Grid>
            <Grid item xs={10} sx={{ display: 'flex', alignItems: 'end' }}>
              <InputTextField
                width="300px"
                label="키"
                error={isIncorrectForm(modelInfo.height).value}
                helperText={`${isIncorrectForm(modelInfo.height).message}${
                  isIncorrectForm(modelInfo.height).intMessage
                }`}
                value={modelInfo.height}
                onChange={(e) => {
                  if (!/^[0-9.]*$/.test(e.target.value)) {
                    return
                  }

                  setModelInfo({ ...modelInfo, height: e.target.value })
                }}
              />
              <p
                style={{
                  fontSize: '18px',
                  color: '#707070',
                  marginLeft: 10,
                }}
              >
                cm
              </p>
            </Grid>
            <Grid item xs={1.6} sx={{ display: 'flex' }}>
              <p
                style={{
                  fontSize: '14px',
                  color: '#707070',
                  fontWeight: 'bold',
                }}
              >
                몸무게
              </p>
              <p
                style={{
                  fontSize: '14px',
                  color: '#FF0000',
                  fontWeight: 'bold',
                  marginLeft: 2,
                }}
              >
                *
              </p>
            </Grid>
            <Grid item xs={10} sx={{ display: 'flex', alignItems: 'end' }}>
              <InputTextField
                width="300px"
                label="몸무게"
                error={isIncorrectForm(modelInfo.weight).value}
                helperText={`${isIncorrectForm(modelInfo.weight).message}${
                  isIncorrectForm(modelInfo.height).intMessage
                }`}
                value={modelInfo.weight}
                onChange={(e) => {
                  if (!/^[0-9.]*$/.test(e.target.value)) {
                    return
                  }
                  setModelInfo({ ...modelInfo, weight: e.target.value })
                }}
              />
              <p
                style={{
                  fontSize: '18px',
                  color: '#707070',
                  marginLeft: 10,
                }}
              >
                kg
              </p>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="large"
            sx={{ backgroundColor: 'black', marginTop: 10 }}
            onClick={onClickStoreModel}
          >
            저장하기
          </Button>
        </Box>
      </Box>

      {/* </Container> */}
    </DefaultLayout>
  )
}

const CustomDropDownField = styled(Autocomplete)({
  marginTop: '10px',
  width: '300px',

  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    // 해당 input focus 되었을 때 placeholder text color
    // floatng label을 사용할 때 처리 필요하다
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
})

export default StylistAddModelPage
