import { Box, Divider, Stack, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../element/Button'
import DefaultLayout from '../../global/DefaultLayout'
import useNotice from '../../hooks/notice/useNotice'
import { formatDateToKorean } from '../../utils'
import parse from 'html-react-parser'

const NoticeDetailPage = () => {
  const navigate = useNavigate()
  const location = useLocation().pathname.split('/').pop()

  const { notice } = useNotice(location)

  return (
    <DefaultLayout isLoggedIn>
      <Stack
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderBottom: '1px solid #e4e4e4',
            height: 54,
            paddingX: '20px',
          }}
        >
          <Typography>{notice?.title}</Typography>
          <Typography
            sx={{
              color: '#707070',
              fontSize: 14,
            }}
          >
            {formatDateToKorean(new Date(notice?.createdAt))}
          </Typography>
        </Stack>
        <Divider />

        <Box sx={{ padding: '20px' }}>
          {notice.content && parse(notice.content)}
        </Box>

        <Box height={30} />
        <Stack direction="row" justifyContent="center">
          <Button onClick={() => navigate(-1)} width={100}>
            <Typography>이전으로</Typography>
          </Button>
        </Stack>
      </Stack>
    </DefaultLayout>
  )
}

export default NoticeDetailPage
