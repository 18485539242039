import { useEffect, useRef, useState } from 'react'
import { getUntagProductList } from '../../api/product'

const SIZE = 10

const useUntagProductList = () => {
  const [untagProduct, setUntagProduct] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const viewPage = useRef(1)

  useEffect(() => {
    ;(async () => {
      try {
        const { count } = await getUntagProductList(page)
        setCount(count)
      } catch (error) {}
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (viewPage.current > page) return
      try {
        const { data } = await getUntagProductList(page)
        setUntagProduct((prev) => prev.concat(data))
        viewPage.current += 1
      } catch (error) {}
    })()
  }, [page])

  const prevPage = () => setPage((prev) => (prev === 1 ? 1 : prev - 1))
  const nextPage = () =>
    setPage((prev) => (count / SIZE === page ? prev : prev + 1))

  const viewProduct = untagProduct.slice((page - 1) * SIZE, page * SIZE)

  return { count, untagProduct: viewProduct, prevPage, nextPage }
}

export default useUntagProductList
