import * as React from 'react'
import { Autocomplete, TextField, styled } from '@mui/material'
import { inputLabelPropsStyle } from '../../utils'

const wrap = (name, value) => ({
  name,
  value,
})

const SHIPPING_COMPANIES = [
  wrap('', ''),
  wrap('CJ 대한통운', 'CJ'),
  wrap('우체국택배', 'EPOST'),
  wrap('한진택배', 'HANJIN'),
  wrap('롯데택배', 'LOTTE'),
  wrap('로젠택배', 'ILOGEN'),
  wrap('홈픽택배', 'HOMEPICK'),
  wrap('GS 편의점택배', 'GS'),
  wrap('CU 편의점택배', 'CU'),
  wrap('경동택배', 'KDEXP'),
  wrap('대신택배', 'DAESIN'),
  wrap('일양로지스', 'ILYANGLOGIS'),
  wrap('합동택배', 'HDEXP'),
  wrap('부릉', 'VROONG'),
]

export const nameToValue = (name) =>
  SHIPPING_COMPANIES.find((o) => o.name === name).value
export const valueToName = (value) =>
  SHIPPING_COMPANIES.find((o) => o.value === value).name

export default function PostSelectBtn({ body, setBody, isModify, checked }) {
  const handleChange = (e, value) => {
    setBody({ ...body, shippingCompany: nameToValue(value) })
  }

  return (
    <CustomSmallDropDownField
      fullWidth
      disableClearable
      size="small"
      defaultValue={
        isModify ? valueToName(checked[0]?.innerShippingCompany) : ''
      }
      onChange={handleChange}
      ListboxProps={{
        style: {
          fontSize: '14px',
        },
      }}
      options={SHIPPING_COMPANIES.map((o) => o.name)}
      filterOptions={(options, { inputValue }) => {
        return options.filter(
          (option) => option !== '' && option.includes(inputValue)
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={inputLabelPropsStyle}
          label="택배사 선택"
        />
      )}
    />
  )
}

const CustomSmallDropDownField = styled(Autocomplete)({
  marginTop: '6px',
  width: '140px',
  '& legend': { display: 'none' },
  '& label.Mui-focused': {
    display: 'none',
  },
  '& label.MuiInputLabel-shrink': {
    display: 'none',
  },
})
