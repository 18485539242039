import { Divider, Grid } from '@mui/material'
import React from 'react'
import {
  getSellerFromLocalStorage,
  setSellerToLocalStorage,
} from '../../api/storage'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { getImagesUrl } from '../../api/image'
import { updateInfo } from '../../api/seller'
import { CheckSnackbar } from '../../components/Snackbar'
import ProductTags from '../../components/stylist/ProductTags'
import { InputTextField } from '../../element/InputTextField'
import DefaultLayout from '../../global/DefaultLayout'
import profieImageAdd from '../../static/profile_image_add.svg'
import { bizType } from '../registerBizInfo'
import { PasswordChangeModal } from '../../components/stylist/PasswordChangeModal'

const StylistBasicInfoPage = () => {
  const navigation = useNavigate()

  const [seller, setSeller] = React.useState(getSellerFromLocalStorage())

  const profileImageHandler = async (e) => {
    const file = e.target.files?.[0]
    if (!file) return alert('잘못된 이미지 형식입니다.')

    const newFileName = seller.name ? seller.name : 'profile' + Date.now()

    const formData = new FormData()
    formData.append('type', 'profile')
    formData.append('files', file, newFileName)

    const { success, data } = await getImagesUrl(formData)

    if (success) {
      setSeller({ ...seller, profileImg: data.pop() })
    }
  }

  const submit = async () => {
    let len = 0
    if (seller.sellProductInfo?.circumstance?.length > 0) {
      len += seller.sellProductInfo?.circumstance?.length
    }

    if (seller.sellProductInfo?.style?.length > 0) {
      len += seller.sellProductInfo?.style?.length
    }
    if (len > 4) {
      alert('상황과 스타일은 총 4개까지 선택이 가능합니다.')
      return
    }

    const { success } = await updateInfo(seller)

    if (success) {
      setOnCheckBar(true)
      setSellerToLocalStorage(
        seller.name,
        seller.approveStatus,
        seller.profileImg,
        seller.sellerId,
        seller.managerName,
        seller.managerNumber,
        seller.sellProductInfo,
        seller.bankInfo,
        seller.businessInfo,
        seller.sellerEmail
      )

      setTimeout(() => {
        navigation('/dashboard')
      }, 400)
    } else {
      alert('에러')
    }
  }

  const [onCheckBar, setOnCheckBar] = React.useState(false)
  const [onPwd, setPwd] = React.useState(false)

  return (
    <DefaultLayout isLoggedIn onClickSubHeaderBtn={submit}>
      <CheckSnackbar open={onCheckBar} setOpen={setOnCheckBar} />
      {onPwd && (
        <PasswordChangeModal open={onPwd} onClose={() => setPwd(false)} />
      )}

      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'end' }}>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            파트너 정보
          </p>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
              marginLeft: '20px',
            }}
          >
            서비스에 노출되는 파트너 정보
          </p>
        </Box>
        <Divider sx={{ height: 16 }} />
        <Grid container spacing={4} sx={{ marginTop: 0 }}>
          <Grid item xs={1.6}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              브랜드명
            </p>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '16px',
              }}
            >
              {seller.name}
            </p>
          </Grid>
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              프로필 이미지
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <label htmlFor="profile">
              <img
                src={profieImageAdd}
                alt="profile_image_add"
                style={{ width: 100 }}
              />
            </label>
            <input
              type="file"
              id="profile"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={profileImageHandler}
            />
            {seller.profileImg && (
              <img
                src={seller.profileImg}
                alt="profile"
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 100,
                  marginLeft: 20,
                  objectFit: 'cover',
                }}
              />
            )}
            <p
              style={{
                fontSize: '14px',
              }}
            >
              최대 1MB 크기의 .jpg, .jpeg, .png 파일(권장 사이즈 128*128)
            </p>
          </Grid>
        </Grid>
        <Divider sx={{ height: 16 }} />
        <Box sx={{ display: 'flex', alignItems: 'start', marginY: '20px' }}>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            판매 상품 정보
          </p>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
              marginLeft: '20px',
            }}
          >
            상품 등록 시 아래 태그가 기본값으로 지정됩니다.
            <br />
            주로 취급하는 카테고리 또는 스타일을 선택 해 주세요 (복수 선택 가능)
          </p>
        </Box>
        <ProductTags seller={seller} setSeller={setSeller} />
      </Box>
      <Box
        sx={{
          marginX: '10px',
          paddingX: '20px',
          marginTop: '10px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Box>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            계정 정보
          </p>
        </Box>

        <Grid
          container
          spacing={4}
          sx={{ display: 'flex', alignItems: 'center', marginTop: 0 }}
        >
          <Grid item xs={1.6}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              로그인 이메일
            </p>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '16px',
              }}
            >
              {seller.sellerEmail}
            </p>
          </Grid>
          <Grid item xs={1.6}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              비밀번호
            </p>
          </Grid>
          <Grid item xs={10}>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'black' }}
              onClick={() => setPwd(true)}
            >
              비밀번호 변경
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ height: 40 }} />
        <Box>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
              marginTop: '20px',
            }}
          >
            판매 담당자 정보
          </p>
        </Box>
        <Grid
          container
          spacing={4}
          sx={{ display: 'flex', alignItems: 'center', marginTop: 0 }}
        >
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              판매 담당자명
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <InputTextField
              width="450px"
              label="판매 담당자명"
              value={seller.managerName}
              onChange={(e) => {
                setSeller({
                  ...seller,
                  managerName: e.target.value,
                })
              }}
            />
          </Grid>
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              담당자 연락처
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <InputTextField
              width="450px"
              label="담당자 연락처"
              value={seller.managerNumber}
              onChange={(e) => {
                setSeller({
                  ...seller,
                  managerNumber: e.target.value,
                })
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ height: 40 }} />
        <Box>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
              marginTop: '20px',
            }}
          >
            정산 정보
          </p>
        </Box>

        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#FFF0F0',
            height: '60px',
            position: 'relative',
            marginTop: '12px',
          }}
        >
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: '#F57979',
              height: '60px',
              width: '10px',
              position: 'absolute',
              left: '0',
              top: '0',
            }}
          ></Box>
          <p
            style={{
              fontSize: '14px',
              marginLeft: '30px',
              position: 'absolute',
              top: '50%',
              transform: 'translate(0%, -50%)',
            }}
          >
            정산 정보 변경을 원하시는 경우 고객센터 '문의하기'를 통해 변경
            가능합니다.
            <br />
            정산일로부터 최소 7 영업일 이전에는 변경이 불가하니 참고 해 주세요.
            <span
              style={{
                color: '#FF0000',
                fontWeight: 'bold',
                paddingLeft: '10px',
                textDecoration: 'underline',
              }}
            >
              정산 정보 변경하기
            </span>
          </p>
        </Box>
        <Grid
          container
          spacing={4}
          sx={{ display: 'flex', alignItems: 'center', marginTop: 0 }}
        >
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              은행
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '16px',
              }}
            >
              {seller.bankInfo.bankName}
            </p>
          </Grid>
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              예금주
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '16px',
              }}
            >
              {seller.bankInfo.accountHolder}
            </p>
          </Grid>
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              계좌번호
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '16px',
              }}
            >
              {seller.bankInfo.accountNumber}
            </p>
          </Grid>
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              이메일
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '16px',
              }}
            >
              {seller.bankInfo.email}
            </p>
          </Grid>
        </Grid>
        <Divider sx={{ height: 40 }} />
        <Box>
          <p
            style={{
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'black',
              marginTop: '20px',
            }}
          >
            사업자 정보
          </p>
        </Box>

        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#FFF0F0',
            height: '60px',
            position: 'relative',
            marginTop: '12px',
          }}
        >
          <Box
            sx={{
              borderRadius: 2,
              backgroundColor: '#F57979',
              height: '60px',
              width: '10px',
              position: 'absolute',
              left: '0',
              top: '0',
            }}
          ></Box>
          <p
            style={{
              fontSize: '14px',
              marginLeft: '30px',
              position: 'absolute',
              top: '50%',
              transform: 'translate(0%, -50%)',
            }}
          >
            사업자 정보 변경을 원하시는 경우 고객센터 '문의하기'를 통해 변경
            가능합니다.
            <br />
            정산일로부터 최소 7 영업일 이전에는 변경이 불가하니 참고 해 주세요.
            <span
              style={{
                color: '#FF0000',
                fontWeight: 'bold',
                paddingLeft: '10px',
                textDecoration: 'underline',
              }}
            >
              사업자 정보 변경하기
            </span>
          </p>
        </Box>
        <Grid
          container
          spacing={4}
          sx={{ display: 'flex', alignItems: 'center', marginTop: 0 }}
        >
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              사업자 구분
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '16px',
              }}
            >
              {bizType.find((o) => o.value === seller.businessInfo.type).name}
            </p>
          </Grid>
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              대표자명
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '16px',
              }}
            >
              {seller.businessInfo.representativeName}
            </p>
          </Grid>

          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              사업자등록증 상호명
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '16px',
              }}
            >
              {seller.businessInfo.businessName}
            </p>
          </Grid>
          <Grid item xs={1.6} sx={{ display: 'flex' }}>
            <p
              style={{
                fontSize: '14px',
                color: '#707070',
                fontWeight: 'bold',
              }}
            >
              사업자등록번호
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#FF0000',
                fontWeight: 'bold',
                marginLeft: 2,
              }}
            >
              *
            </p>
          </Grid>
          <Grid item xs={10}>
            <p
              style={{
                fontSize: '16px',
              }}
            >
              {seller.businessInfo.businessNumber}
            </p>
          </Grid>
        </Grid>
      </Box>
    </DefaultLayout>
  )
}

export default StylistBasicInfoPage
