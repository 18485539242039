import { Divider, Grid, Button as MButton } from '@mui/material'
import {
  ALL_TAG_MAP,
  KO_CLIMATE_TAG,
  KO_OCCUPATION_TAG,
  KO_SPECIAL_TAG,
  KO_STYLE_TAG,
} from '../../fixture/snap_tag'

import React from 'react'
import useOpponentTag from '../../hooks/tag/useOpponentTag'

const SnapTags = ({ tags, tagHandler }) => {
  const { apiOpponent } = useOpponentTag()
  const opponentTag = apiOpponent?.filter((a) => {
    return tags.some((t) => t === a.mainTag.tag)
  })

  const checkTag = (tag) => tags.includes(tag)

  const getVariantStyle = (tag) => (checkTag(tag) ? 'contained' : 'outlined')
  const getBackgroundColor = (tag) => (checkTag(tag) ? 'black' : '')
  const getForegroundColor = (tag) => (checkTag(tag) ? '' : '#707070')
  const getDisable = (tag) => {
    const isInOpponent = opponentTag.some(
      (t) => t.opponentTag.tag === ALL_TAG_MAP[tag]
    )
    const isInCheck = checkTag(ALL_TAG_MAP[tag])
    return isInOpponent && !isInCheck
  }

  const formTagHandler = (tag) => {
    const isIn = checkTag(tag)

    let updated

    if (isIn) {
      updated = tags.filter((e) => e !== tag)
    } else {
      updated = [...tags, tag]
    }

    tagHandler(updated)
  }

  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
            }}
          >
            날씨
          </p>
        </Grid>
        {/* climate */}
        <Grid item xs={10}>
          {KO_CLIMATE_TAG.map((t) => (
            <MButton
              key={`TAG_${t}`}
              variant={getVariantStyle(ALL_TAG_MAP[t])}
              sx={{
                borderRadius: 3,
                backgroundColor: getBackgroundColor(ALL_TAG_MAP[t]),
                color: getForegroundColor(ALL_TAG_MAP[t]),
                borderColor: getForegroundColor(ALL_TAG_MAP[t]),
                marginRight: 1,
                width: '100px',
              }}
              onClick={() => formTagHandler(ALL_TAG_MAP[t])}
              disabled={getDisable(t)}
            >
              {t}
            </MButton>
          ))}
        </Grid>
      </Grid>
      <Divider sx={{ height: 16 }} />
      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
            }}
          >
            상황
          </p>
        </Grid>
        <Grid item xs={10}>
          {/* occupation */}

          {KO_OCCUPATION_TAG.map((t) => (
            <MButton
              key={`TAG_${t}`}
              variant={getVariantStyle(ALL_TAG_MAP[t])}
              sx={{
                borderRadius: 3,
                backgroundColor: getBackgroundColor(ALL_TAG_MAP[t]),
                color: getForegroundColor(ALL_TAG_MAP[t]),
                borderColor: getForegroundColor(ALL_TAG_MAP[t]),
                marginRight: 1,
                width: '100px',
              }}
              onClick={() => formTagHandler(ALL_TAG_MAP[t])}
              disabled={getDisable(t)}
            >
              {t}
            </MButton>
          ))}
        </Grid>
        <Grid item xs={1.5} />
        <Grid item xs={10}>
          {KO_SPECIAL_TAG.map((t) => (
            <MButton
              key={`TAG_${t}`}
              variant={getVariantStyle(ALL_TAG_MAP[t])}
              sx={{
                borderRadius: 3,
                backgroundColor: getBackgroundColor(ALL_TAG_MAP[t]),
                color: getForegroundColor(ALL_TAG_MAP[t]),
                borderColor: getForegroundColor(ALL_TAG_MAP[t]),
                marginRight: 1,
                width: '100px',
              }}
              onClick={() => formTagHandler(ALL_TAG_MAP[t])}
              disabled={getDisable(t)}
            >
              {t}
            </MButton>
          ))}
        </Grid>
      </Grid>
      <Divider sx={{ height: 16 }} />

      <Grid container spacing={2} sx={{ marginTop: 0 }}>
        <Grid item xs={1.5} sx={{ display: 'flex' }}>
          <p
            style={{
              fontSize: '14px',
              color: '#707070',
            }}
          >
            스타일
          </p>
          <p
            style={{
              fontSize: '14px',
              color: '#FF0000',
              fontWeight: 'bold',
              marginLeft: 2,
            }}
          >
            *
          </p>
        </Grid>
        <Grid item xs={10}>
          {KO_STYLE_TAG.map((t) => (
            <MButton
              key={`TAG_${t}`}
              variant={getVariantStyle(ALL_TAG_MAP[t])}
              sx={{
                borderRadius: 3,
                backgroundColor: getBackgroundColor(ALL_TAG_MAP[t]),
                color: getForegroundColor(ALL_TAG_MAP[t]),
                borderColor: getForegroundColor(ALL_TAG_MAP[t]),
                margin: 1,
                width: '105px',
                fontSize: t === '비치웨어/수영복' ? '12px' : '14px',
              }}
              onClick={() => formTagHandler(ALL_TAG_MAP[t])}
              disabled={getDisable(t)}
            >
              <p>{t === '비치웨어/수영복' ? `비치웨어/\n수영복` : t}</p>
            </MButton>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default SnapTags
