const SET_USER = 'USER/SET_USER'

export const getUserAction = (user) => ({
  type: SET_USER,
  user,
})

const initialState = {
  user: {
    login: false,
    nickname: '',
    profileImage: '',
    _id: '',
  },
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return action.user
    default:
      return state
  }
}

export default user
