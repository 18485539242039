import { clearToken, getToken, setToken } from './cookie'

import axios from 'axios'
import { clearLocalStorage } from './storage'

const LOCAL = 'http://127.0.0.1:3000'
const DEV = 'https://api.by-snap.com'
const PROD = 'https://server.by-snap.com'

export const URL =
  process.env.REACT_APP_ENV === 'dev'
    ? DEV
    : process.env.REACT_APP_ENV === 'local'
    ? LOCAL
    : PROD

export const instance = axios.create({
  baseURL: URL,
  timeout: 30 * 1000, // 30 seconds
})

instance.interceptors.request.use(
  async (config) => {
    if (config.url.includes('excel')) {
      config.timeout = 60 * 1000
    }

    if (config.token) {
      const token = getToken()
      if (!token.access) {
        logout()
        return
      }
      config.headers['Authorization'] = `Bearer ${token.access}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const errorStatus = error?.response?.status

    if (errorStatus === 400 || errorStatus === 500) {
      return Promise.reject(error)
    }

    const errorCode = error?.response.data.errorCode
    if (!errorCode || errorCode !== 'ERR_08') {
      return Promise.reject(error)
    }

    const originalRequest = error.config
    if (originalRequest._retry) {
      return
    }
    originalRequest._retry = true

    try {
      const { refresh } = getToken() // Refresh Token을 얻는 함수
      const tokenResponse = await axios.post(
        `${URL}/api/auth/refresh`,
        {},
        { headers: { Authorization: `Bearer ${refresh}` } }
      )
      const { accessToken, refreshToken } = tokenResponse.data
      setToken(accessToken, refreshToken)

      // Axios 인스턴스에 새로운 토큰 설정
      instance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${accessToken}`

      // 원래 요청을 새로운 Access Token으로 업데이트하고 재시도
      originalRequest.headers['Authorization'] = `Bearer ${accessToken}`
      return instance(originalRequest)
    } catch (refreshError) {
      logout()
      return Promise.reject(refreshError)
    }
  }
)

const logout = () => {
  clearToken()
  clearLocalStorage()
  window.location.pathname = '/'
}
