import * as xlsx from 'xlsx'

export class EXCEL {
  /**
   * https://blog.naver.com/PostView.naver?blogId=senshig&logNo=222367357985
   */

  // check file extension
  static getExtensionOfFilename = (file) => {
    const filename = file.name
    const lastDot = filename.lastIndexOf('.')
    const fileExt = filename.substring(lastDot, filename.length).toLowerCase()

    let result = fileExt === '.xlsx' ? true : false
    return result
  }

  // return string at UTF-16 code unit
  static #_fixData = (data) => {
    let o = '',
      l = 0,
      w = 10240 // I think buffer size...
    for (; l < data.byteLength / w; l += 1) {
      o += String.fromCharCode.apply(
        null,
        new Uint8Array(data.slice(l * w, l * w + w))
      )
    }
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
    return o
  }

  // convert workbook to json
  static convertWorkbookToJson = (workbook) => {
    let jArray = []
    // workbook.SheetNames.forEach((sheetName) => {
    const roa = xlsx.utils.sheet_to_json(
      workbook.Sheets['BYSNAP 상품 대량등록'],
      {
        range: 2,
      }
    )
    if (roa.length > 0) jArray.push(roa)

    return jArray[0]
  }

  // generate JSON by excel
  static readExcel = (file, callback) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = (e) => {
      const data = e.target?.result
      const arr = this.#_fixData(data)
      const workbook = xlsx.read(btoa(arr), { type: 'base64' })

      callback(workbook)
    }
  }

  static dowLoad = async () => {
    // request
    fetch('/template.xlsx', { method: 'GET' })
      .then((response) => response.blob()) // response -> blob
      .then((blob) => {
        // create blob and url
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        // file and url setting
        link.setAttribute('href', url)
        link.setAttribute('download', 'BYSNAP_EXCEL_TEMPLATE.xlsx')

        // add body
        document.body.appendChild(link)
        link.click()

        // remove link and url
        link.parentNode.removeChild(link)
        window.URL.revokeObjectURL(url)
      })
  }
}
