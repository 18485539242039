import { Box, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import {
  clearLocalStorage,
  getSellerFromLocalStorage,
  isAvailableSeller,
  isEmptyObj,
} from '../api/storage'

import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import styled from 'styled-components'
import { clearToken } from '../api/cookie'
import logo from '../static/bysnap_header.png'

const Header = ({ name }) => {
  const navigation = useNavigate()
  const clickLogo = () => {
    const user = getSellerFromLocalStorage()
    const isAvailable = isAvailableSeller()

    if (isEmptyObj(user)) {
      navigation('/')
    } else if (isAvailable) {
      navigation('/dashboard')
    } else {
      navigation('/stylist/info')
    }
    window.scrollTo(0, 0)
  }

  const clickLogout = () => {
    clearLocalStorage()
    clearToken()
    clickLogo()
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: '#393939', height: 50 }}>
        <Toolbar
          variant="dense"
          disableGutters
          sx={{ height: 50, justifyContent: 'space-between' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LogoBox onClick={clickLogo}>
              <img src={logo} alt="logo" style={{ height: '18px' }} />
            </LogoBox>
          </Box>
          <>
            <Box sx={{ display: 'flex' }}>
              <Link
                to="http://pf.kakao.com/_xfxoTrG/chat"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  marginRight: '60px',
                  fontWeight: 700,
                }}
              >
                카카오톡 문의하기
              </Link>
              <Link
                to="/faq"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  marginRight: '60px',
                }}
              >
                <Typography sx={{ fontWeight: 700 }}>자주 묻는 질문</Typography>
              </Link>
              {name && (
                <Box marginRight={'30px'} sx={{ fontWeight: 'bold' }}>
                  <p>{name} 님</p>
                </Box>
              )}
              {name && (
                <Box marginRight={'30px'} sx={{ cursor: 'pointer' }}>
                  <P onClick={clickLogout}>로그아웃</P>
                </Box>
              )}
            </Box>
          </>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header

const P = styled('p')`
  text-decoration: underline;
`

const LogoBox = styled(Box)`
  padding: 16px 0px 10px 20px;
  &:hover {
    cursor: pointer;
  }
`
