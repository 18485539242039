import styled from 'styled-components'
import Image from '../../element/Image'
import { getDate } from '../../utils'
import React, { useState } from 'react'
import { InputTextField } from '../../element/InputTextField'

const heads = [
  '상품번호',
  '대표이미지',
  '상품명',
  '옵션여부',
  '재고수량',
  '컬러',
  '사이즈',
  '상품등록일',
]

const StockProductTable = ({ products, setProducts }) => {
  const [viewIds, setViewIds] = useState([])
  const onClickTableRow = (pid) => {
    const findIdIdx = viewIds.findIndex((id) => id === pid)
    if (findIdIdx !== -1) {
      viewIds.splice(findIdIdx, 1)
    } else {
      viewIds.push(pid)
    }
    setViewIds([...viewIds])
  }

  return (
    <Table>
      <thead>
        <tr>
          {heads.map((head, idx) => (
            <Th key={idx}>{head}</Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {products.map((product) => {
          const totalCnt = product.option.inventoryCounts.reduce(
            (pre, cur) => pre + cur.count,
            0
          )
          return (
            <React.Fragment key={product.id}>
              {TableRow(
                product,
                product.id,
                true,
                '',
                '',
                totalCnt,
                onClickTableRow,
                () => {}
              )}
              {viewIds.includes(product.id) &&
                product.option.inventoryCounts.map(({ count, index }, idx) => {
                  let color = ''
                  let size = ''
                  const options = product.option.options
                  if (options.length === 1) {
                    size = options[0].list[index[0]]
                  } else {
                    color = options[0].list[index[0]]
                    size = options[1].list[index[1]]
                  }

                  const onChangeHandler = (e) => {
                    const input = e.target.value
                    if (!/^[0-9,]*$/.test(input)) {
                      return
                    }
                    product.option.inventoryCounts[idx].count = Number(input)
                    setProducts([...products])
                  }

                  return TableRow(
                    product,
                    idx,
                    false,
                    color,
                    size,
                    count,
                    () => {},
                    onChangeHandler
                  )
                })}
            </React.Fragment>
          )
        })}
      </tbody>
    </Table>
  )
}

export default StockProductTable

const Table = styled('table')({
  width: '100%',
  minWidth: '1480px',
})

const Th = styled('th')({
  textAlign: 'center',
  borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
  fontSize: '13px',
  color: '#707070',
})

const Td = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
  fontSize: '13px',
})

const TableRow = (
  product,
  key,
  isMain,
  color,
  size,
  count,
  onClickTableRow,
  onChangeHandler
) => {
  const backgroundColor = isMain ? '' : '#ddd'
  const option = isMain ? (product.existOption ? 'Y' : 'N') : ''
  const onClick = () => {
    if (isMain) {
      onClickTableRow(product.id)
    }
  }

  return (
    <tr
      key={key}
      style={{
        borderBottom: '1px solid #ddd',
        height: '120px',
        backgroundColor,
        cursor: isMain ? 'pointer' : '',
      }}
      onClick={onClick}
    >
      <Td width={80}>{product.productNumber}</Td>
      <Td style={{ width: '80px' }}>
        {isMain && (
          <Image alt="product" src={product.imgs[0]} width={60} height={60} />
        )}
      </Td>
      <Td width={250}>{product.name}</Td>

      <Td width={100}>{option}</Td>
      <Td width={100}>{CountTableData(isMain, count, onChangeHandler)}</Td>
      <Td width={250}>{color}</Td>
      <Td width={250}>{size}</Td>

      <Td>{getDate(product.createdAt)}</Td>
    </tr>
  )
}

const CountTableData = (isMain, count, onChangeHandler) =>
  isMain ? (
    count
  ) : (
    <InputTextField
      width="80px"
      label="판매가 입력"
      value={count}
      onChange={onChangeHandler}
    />
  )
