import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Divider } from '@mui/material'
import { Button } from '../../element/Button'
import SendingTable from './sending-table'
import { DiscountType } from '../../utils'
import { InputTextField } from '../../element/InputTextField'
import SelectDiscountTypeForModal from './SelectDiscountTypeForModal'
import { getDiscountedSellingPrice } from '../../pages/product/addProduct'
import { updateDiscount } from '../../api/product'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 955,
  maxHeight: '100vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  outline: 'none',
  boxShadow: 24,
  padding: '16px 16px 30px 16px',
}

export default function SetDiscountModal({
  open,
  handleClose,
  checked,
  setChecked,
}) {
  const [discount, setDiscount] = React.useState({
    type: DiscountType.Percentage,
    amount: 0,
  })

  const onClickBtn = async () => {
    if (
      checked.some((products) => {
        return (
          getDiscountedSellingPrice(
            products.sellingPrice,
            discount.type,
            discount.amount
          ) < 5000
        )
      })
    ) {
      alert('할인판매가는 최소 5,000원 이상이어야합니다.')
      return
    }
    // 요청
    const { success } = await updateDiscount(
      checked.map((p) => p.id),
      discount.type,
      discount.amount
    )

    if (success) {
      checked.forEach((c) => {
        c.discountAmount = discount.amount
        c.discountType = discount.type

        let discountRate
        if (discount.type === DiscountType.Percentage) {
          discountRate = discount.amount
        } else if (discount.type === DiscountType.Absolute) {
          discountRate = Math.round((discount.amount / c.sellingPrice) * 100)
        }

        c.discountRate = discountRate
      })

      setChecked([])
      handleClose()
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <p style={{ fontSize: '18px', fontWeight: 'bold' }}>
          선택 할인정보 입력
        </p>
        <Divider sx={{ height: 16 }} />
        <Box sx={{ padding: '10px 0 10px 0' }}>
          <p style={{ color: '#707070' }}>
            선택된 할인 가능한 상품 목록입니다.
          </p>
        </Box>
        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: '#FFF0F0',
            height: '220px',
            marginTop: '10px',
            marginBottom: '22px',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              borderRadius: '10px 0px 0px 10px',
              backgroundColor: '#F57979',
              width: '10px',
            }}
          />

          <p
            style={{
              fontSize: '14px',
              margin: '10px 0 0 30px',
            }}
          >
            <span style={{ color: 'red', fontWeight: 'bold' }}>* 주의사항</span>
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>
              · 해당 정보는 등록 즉시 반영됩니다.
            </span>
            <br />
            <span>
              &ensp; 할인 상품 선택이 정확히 되었는지 다시 한 번 확인해주세요.
            </span>
            <br />
            <br />

            <span style={{ fontWeight: 'bold' }}>
              · 할인정보 일괄 입력은 할인율(%)로만 기입 가능합니다.
            </span>
            <br />

            <span>
              &ensp; 할인율이 아닌 할인액 차감을 원하실 경우 상품 수정 기능을
              통해 등록해주세요.
            </span>

            <br />
            <br />

            <span style={{ fontWeight: 'bold' }}>
              정산금액은 원화(KRW) 할인가 기준으로 실제 정산 금액도 변경되니
              등록 시 유의해주세요.
            </span>
          </p>
        </Box>

        <Box
          sx={{
            borderRadius: 2,
            padding: '10px',
            marginBottom: '30px',
            display: 'flex',
            gap: 3,
          }}
        >
          <Box display="flex" alignItems="center">
            원화 기준 판매가에서
            <InputTextField
              width="70px"
              sx={{ height: '45px', marginX: 1 }}
              value={discount.amount === 0 ? '' : discount.amount}
              onChange={(e) => {
                const input = e.target.value
                if (!/^[0-9]*$/.test(input)) {
                  return
                }

                const numInput = Number(input)
                if (numInput < 0 || numInput >= 100) {
                  return
                }

                setDiscount({ ...discount, amount: numInput })
              }}
            />
            <SelectDiscountTypeForModal
              discount={discount}
              setDiscount={setDiscount}
            />
            할인
          </Box>
        </Box>

        <Box height="20px" />

        <Box maxHeight="300px" overflow="scroll">
          <SendingTable checked={checked} discount={discount} />
        </Box>

        <Box
          paddingX={1}
          paddingY={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            color="#f57979"
            bg="#fff0f0"
            width="80px"
            height="50px"
            borderRadius="10px"
            bold
            onClick={handleClose}
          >
            취소
          </Button>
          <Box sx={{ width: 20 }} />
          <Button
            color="white"
            bg="black"
            width="126px"
            height="50px"
            borderRadius="10px"
            bold
            onClick={onClickBtn}
          >
            할인정보 저장
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
