import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'

export const TAG_IMAGE_SIZE = 500
export const TAG_DOT_SIZE = 20
export const REAL_TAG_DOT_SIZE = TAG_DOT_SIZE / 2
export const PREVIEW_IMAGE_SIZE = 375
export const LOC_Z_INDEX = 50

const ProductLocs = ({ loc, size = TAG_IMAGE_SIZE, real }) => {
  const { x, y } = loc

  const SIZE = real ? REAL_TAG_DOT_SIZE : TAG_DOT_SIZE

  return (
    <RadioButtonCheckedIcon
      sx={{
        width: SIZE,
        height: SIZE,
        position: 'absolute',
        left: x * size - SIZE / 2,
        top: y * size - SIZE / 2,
        color: 'white',
        backgroundColor: '#707070',
        borderRadius: SIZE,
        zIndex: LOC_Z_INDEX,
      }}
    />
  )
}

export default ProductLocs
