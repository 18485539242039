import { Divider, Stack, Typography } from '@mui/material'
import NoticeTable from '../../components/notice/notice-table'
import DefaultLayout from '../../global/DefaultLayout'

const NoticePage = () => {
  return (
    <DefaultLayout isLoggedIn>
      <Stack
        sx={{
          marginX: '10px',
          paddingX: '20px',
          paddingTop: '16px',
          paddingBottom: '40px',
          backgroundColor: 'white',
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: 18, paddingY: '10px' }}>
          공지목록
        </Typography>
        <Divider sx={{ paddingY: '10px' }} />
        <NoticeTable />
      </Stack>
    </DefaultLayout>
  )
}

export default NoticePage
