import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AdddProductSuccessModal from '../../components/product/AddProductSuccessModal'

const useSuccessProductModal = () => {
  const navigation = useNavigate()
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => {
    setOpen(false)
    navigation('/product')
  }
  const goAddSnap = () => {
    setOpen(false)
    navigation(`/snap/add`)
  }

  return {
    openModal,
    modal: () => (
      <AdddProductSuccessModal
        open={open}
        onClose={closeModal}
        goAddSnap={goAddSnap}
      />
    ),
  }
}

export default useSuccessProductModal
